import { RcaOutcomeState } from '@pages/app/rca/outcome/rca-outcome-hook';
import useField from '@hooks/use-field-hook';
import { DraggableReportItemProps } from '@pages/app/rca/outcome/components/custom-lense-selection-item';
import { useEffect, useMemo } from 'react';
import { useSearchReportRankByOptionQuery } from '@api/endpoints/report-type.api';
import { ReportSubType } from '@api/types/report-type/report-subtype.option';
import { ReportType } from '@api/types/report-type/report-type.option';
import { useGetCaseReportsQuery } from '@api/endpoints/case-report.api';
import { useSetCaseOutcomeDetailsMutation } from '@api/endpoints/case-outcome.api';
import { ApiError } from '@api/types/api-error';
import { SetCaseOutcomeDetailsRequest } from '@api/types/case/case-outcome/set-case-outcome-details.request';
import { usePageAlertVariants } from '@components/alerts';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { required } from '@util/validators';

export default function useCustomiseOutcome(state: RcaOutcomeState) {
  const { showErrorMessage } = usePageAlertVariants();
  const { outcomeDetail, outcomeId, caseId, caseDetail } = state;

  const [save, { isLoading: isBusy }] = useSetCaseOutcomeDetailsMutation();

  const {
    data: reportOptions,
    isLoading: loadingReportOptions,
    isSuccess: reportOptionsLoaded,
  } = useGetCaseReportsQuery(caseId);

  const { data: rankByOptions, isLoading: loadingRankByOptions } =
    useSearchReportRankByOptionQuery({
      reportType: ReportType.coverage,
      reportSubType: ReportSubType.solution,
    });

  const summary = useField<string>(
    [required()],
    outcomeDetail?.executiveSummary
  );
  const lenses = useField<Array<DraggableReportItemProps>>(
    [],
    // Default value is set in use effect below
    useMemo(() => [], [])
  );

  const rankBy = useField<number>([], outcomeDetail?.reportRankById);
  const recommendations = useField<string>(
    [required()],
    outcomeDetail?.recommendations
  );
  const showDefaultThemesOutcome = useField<boolean>(
    [],
    outcomeDetail?.showDefaultThemesOutcome
  );
  const showDefaultSolutionsOutcome = useField<boolean>(
    [],
    outcomeDetail?.showDefaultSolutionsOutcome
  );

  const showSummary = outcomeDetail?.showOverview ?? true;
  const showLenses = outcomeDetail?.showInsights ?? true;
  const showSolutions = outcomeDetail?.showSolutions ?? true;
  const showRecommendations = outcomeDetail?.showRecommendations ?? true;

  const isLoading =
    state.isLoading || loadingRankByOptions || loadingReportOptions;
  const canSubmit = !isLoading && !isBusy;

  const { scrollToTopMostError, validateAll } = useFieldsWatcher([
    summary,
    lenses,
    rankBy,
    recommendations,
    showDefaultThemesOutcome,
    showDefaultSolutionsOutcome,
  ]);

  const toggleSelectedReport = (reportId: number) => {
    const newLenses = lenses.value.map((lens) => {
      if (lens.id === reportId) {
        return {
          ...lens,
          isSelected: !lens.isSelected,
        };
      }
      return lens;
    });
    lenses.set([...newLenses]);
  };

  const setLenses = lenses.set;
  useEffect(() => {
    if (reportOptionsLoaded && caseDetail != null) {
      const selectedReports = outcomeDetail?.reports ?? [];
      const newLenses =
        reportOptions!.model.map((report): DraggableReportItemProps => {
          return {
            id: report.reportId,
            reportName: report.name,
            reportTypeName: report.reportTypeName,
            // subReportTypeName: report.reportSubTypeName,
            created: report.created,
            createdBy: report.createdByCompanyUser,
            isSelected:
              selectedReports.findIndex((x) => x.reportId === report.reportId) >
              -1,
          };
        }) ?? [];
      setLenses([...newLenses]);
    }
  }, [
    caseDetail,
    setLenses,
    outcomeDetail?.reports,
    reportOptions,
    reportOptionsLoaded,
  ]);

  const setRankBy = rankBy.set;
  useEffect(() => {
    if (isLoading || rankByOptions == null) {
      return;
    }

    const caseDetailRankBy = outcomeDetail?.reportRankById;
    if (caseDetailRankBy == null) {
      const defaultRankBy = rankByOptions.find(
        (x) => x.reportRankBy === 'Effectiveness'
      );
      if (defaultRankBy != null) {
        setRankBy(defaultRankBy.reportRankById);
      }
    }
  }, [isLoading, outcomeDetail?.reportRankById, setRankBy, rankByOptions]);

  const submit = async () => {
    if (!validateAll()) {
      scrollToTopMostError();
      return false;
    }

    try {
      await save({
        caseId,
        outcomeId: outcomeId!,
        executiveSummary: summary.value,
        reportIds: lenses.value.filter((x) => x.isSelected).map((x) => x.id!),
        recommendations: recommendations.value,
        reportRankById: rankBy.value,
        showDefaultThemesOutcome: showDefaultThemesOutcome.value,
        showDefaultSolutionsOutcome: showDefaultSolutionsOutcome.value,
      }).unwrap();

      return true;
    } catch (e) {
      const { message, errors } = e as ApiError<SetCaseOutcomeDetailsRequest>;

      showErrorMessage(errors?.caseId ?? errors?.outcomeId ?? message);

      summary.setError(errors?.executiveSummary);
      rankBy.setError(errors?.reportRankById);
      recommendations.setError(errors?.recommendations);
      lenses.setError(errors?.reportIds);
      showDefaultSolutionsOutcome.setError(errors?.showDefaultSolutionsOutcome);
      showDefaultThemesOutcome.setError(errors?.showDefaultThemesOutcome);

      scrollToTopMostError();

      return false;
    }
  };

  return {
    outcomeDetail,
    summary,
    lenses,
    rankBy,
    recommendations,
    isLoading,
    isBusy,
    canSubmit,
    rankByOptions,
    toggleSelectedReport,
    submit,
    showSummary,
    showLenses,
    showSolutions,
    showRecommendations,
    showDefaultThemesOutcome,
    showDefaultSolutionsOutcome,
  };
}
