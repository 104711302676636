import { Chip, Typography } from '@mui/material';
import { NodeEndStateType, RcaNode } from '@store/rca-editor/types';
import { Handle, Position } from 'reactflow';
import NodeActions from '@pages/app/rca/tabs/components/node-action';
import { store } from '@store/store';
import {
  makeSelectNode,
  makeSelectNodeFromChainItemId,
  NodeDisplayBadge,
  selectCurrentRcaCaseId,
} from '@store/rca-editor/selectors';
import { isNullOrEmpty } from '@util/string-util';
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MetaConnectionButton from '@pages/app/rca/tabs/components/meta-connections/meta-connection-button';
import { MetaConnectionPosition } from '@pages/app/rca/tabs/components/meta-connections/types';
import { RcaUtil } from '@util/rca-util';
import CollapsedNodeIndicator from '@pages/app/rca/tabs/components/collapsed-node-indicator';
import usePopoverMenu from '@components/popover-menu/use-popover-menu';
import PopoverMenu from '@components/popover-menu/popover-menu';
import { ReactComponent as ConnectionIcon } from '@assets/svgs/connection.svg';
import NodeLinkIcon from '@pages/app/rca/tabs/components/node-link-icon';
import { LoadingIndicator } from '@components/loading-indicator';
import useDefaultNode from '@pages/app/rca/tabs/components/node-types/default-node-hook';
import Row from '@components/layout-util-components/row';
import ColorBadge from '@components/badges/color-badge';
import { v4 as uuid } from 'uuid';
import { StyledNode } from '@pages/app/rca/tabs/components/node-types/styled-default-node';
import AnalysisCompleteInfo from '@pages/app/rca/tabs/components/end-state-content/analysis-complete-info';
import FurtherAnalysisInfo from '@pages/app/rca/tabs/components/end-state-content/further-analysis-info';
import { Circle } from '@mui/icons-material';
import HealthBadge from '@components/badges/health-badge';
import { InlineBoxSkeleton } from '@components/skeletons';
import { useNavigate } from 'react-router-dom';
import { ChartDisplayMode } from '@store/rca-editor/rca-editor-slice';
import useSystemText from '@hooks/use-system-text';

export const RCA_BOX_TEXT_MAX_LENGTH = 65;

export function RcaDefaultNode(node: RcaNode) {
  const navigate = useNavigate();
  const menu = usePopoverMenu();
  const elipsisMenu = usePopoverMenu();
  const { systemText } = useSystemText();

  const state = useDefaultNode(node);
  const {
    id,
    textAreaRef,
    data,
    isBusy,
    canShowActions,
    label,
    collapsedState,
    childCountIncludingMetaNodes,
    isConnection,
    shouldDisplayConnectionIndicator,
    shouldDisplayEndStateInfo,
    shouldDisplayCollapsedIndicator,
    shouldDisplayEndStateElipsis,
    canShowLeftMetaAction,
    canShowRightMetaAction,
    canConnectEdgeToThisNode,
    canConnectEdgeFromThisNode,
    isEditing,
    isHighlightMode,
    isDevMode,
    canHandleClick,
    displayProperties,
    maybeCommitCreate,
    cancelCreate,
    setNodeHighlight,
    selectNode,
    canShowBorder,
    removeEndState,
    canDrag,
    shouldFadeOut,
    type,
    isDragging,
    chartDisplayMode,
    canEditFocalPoint,
  } = state;
  const {
    isRoot,
    endState,
    chainItemId,
    linkedToChainItems,
    linkedFromChainItems,
    sortOrder,
  } = data;

  const [content, setContent] = useState<string>(label || '');

  useEffect(() => {
    if (isEditing) {
      const textArea = textAreaRef.current;
      if (textArea != null) {
        textArea.focus();

        const valLen = textArea.value?.length ?? 0;
        if (valLen > 0) {
          textArea.setSelectionRange(0, valLen);
        }

        // We need to get the node, as the node passed by react-flow isn't the same as the one in the store
        RcaUtil.snapFocusToNode(
          makeSelectNode(id)(store.getState())!,
          false,
          true
        );
      }
    }
  }, [id, isEditing, node, textAreaRef]);

  const onToggleHighlight = () => {
    if (childCountIncludingMetaNodes === 0) {
      setNodeHighlight();
    } else {
      menu.open([
        {
          label: 'Highlight Cause Box',
          onClick: () => setNodeHighlight(),
        },
        {
          label: 'Highlight Chain',
          onClick: () => setNodeHighlight(true),
        },
      ]);
    }
  };

  const onKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    const value = textAreaRef.current?.value?.trim();
    if (e.key === 'Enter') {
      if (value != null && value.length > 0) {
        e.preventDefault();
        e.stopPropagation();
        maybeCommitCreate(value);
      } else {
        cancelCreate();
      }
    } else if (e.key === 'Escape') {
      cancelCreate();
    }
  };

  const onNodeContentChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const inputString = e.target.value;
    setContent(inputString);
  };

  const onMouseDownCapture: MouseEventHandler = (e) => {
    if (!canDrag) {
      e.stopPropagation();
      return;
    }
  };

  const onClick: MouseEventHandler = (e) => {
    if (!canHandleClick) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    const clickCount = e.detail;
    if (e.target !== textAreaRef.current) {
      const className = (e.target as HTMLDivElement)?.className;
      const requiredClickCount = 1;
      if (
        clickCount === requiredClickCount &&
        className != null &&
        className.includes != null &&
        (className.includes('node-actions-container') ||
          className.includes('node-content'))
      ) {
        if (isConnection) {
          // The 'data' for a connection node is actually the data for the node it's connected to
          // therefore, we can use the cause box in the data to get the actual node.
          const connectedNode = makeSelectNodeFromChainItemId(
            data.chainItemId!
          )(store.getState());
          if (connectedNode) {
            RcaUtil.smoothFocusToNode(connectedNode);
            e.stopPropagation();
            e.preventDefault();
          }
        } else if (isHighlightMode) {
          onToggleHighlight();
        } else if (isRoot) {
          if (canEditFocalPoint) {
            const caseId = selectCurrentRcaCaseId(store.getState());
            menu.open([
              {
                label: 'Edit Focal Point',
                onClick: () => navigate(`/rca/edit/${caseId}`),
              },
            ]);
          } else {
            e.stopPropagation();
            e.preventDefault();
          }
        } else {
          selectNode();
        }
      }
    }
  };

  const onEndStateElipsisClick = () => {
    elipsisMenu.open([
      {
        label: 'Remove end state',
        onClick: removeEndState,
      },
    ]);
  };

  const renderBadges = (badges: NodeDisplayBadge[]) => {
    return (
      <Row>
        {badges.map((badge) => {
          let badgeText = badge.text ?? '';
          switch (chartDisplayMode) {
            case ChartDisplayMode.static:
            case ChartDisplayMode.dynamic:
              if (badgeText === 'Static') {
                badgeText = systemText['RCA.CauseBox.NonTransitory'];
              } else if (badgeText === 'Dynamic') {
                badgeText = systemText['RCA.CauseBox.Transitory'];
              } else if (badgeText === 'NA') {
                badgeText = 'Not Set';
              }
              break;
          }
          return (
            <ColorBadge
              color={badge.baseColor}
              textColor={badge.textColor}
              key={uuid()}
              small
            >
              <Row gap={6}>
                {!!badge.indicatorColor && (
                  <Circle
                    sx={{
                      fontSize: 8,
                      color: badge.indicatorColor,
                    }}
                  />
                )}
                <span>{badgeText}</span>
              </Row>
            </ColorBadge>
          );
        })}
      </Row>
    );
  };

  const renderDisplayProperties = () => {
    const isLoading = displayProperties?.isLoading === true && !isRoot;
    const loadingElement = <InlineBoxSkeleton width={100} />;

    switch (displayProperties.type) {
      case 'none':
        if (isLoading && !isEditing) return loadingElement;
        return <></>;
      case 'disproved':
        return (
          <Chip size="small" variant="filled" label="Disproved" color="error" />
        );
      case 'health-score':
        if (isLoading) return loadingElement;
        return <HealthBadge health={displayProperties.healthScore} small />;
      case 'default':
        if (isLoading) return loadingElement;
        if (
          displayProperties.chartDisplayMode !== ChartDisplayMode.healthScore
        ) {
          return renderBadges(displayProperties.badges!);
        } else {
          return <HealthBadge health={displayProperties.healthScore} small />;
        }
      case 'coverage':
        if (isLoading) return loadingElement;
        return renderBadges(displayProperties.badges!);
    }
  };

  const containerClassName = useMemo(() => {
    const classNames = ['node-container', collapsedState];
    if (isEditing) {
      classNames.push('editing');
    }

    if (isNullOrEmpty(label)) {
      classNames.push('no-content');
    }

    if (isRoot) {
      classNames.push('root');
    }

    if (shouldFadeOut) {
      classNames.push('faded');
    }

    if (displayProperties.type === 'disproved') {
      classNames.push('disproved');
    }

    return classNames.join(' ');
  }, [
    collapsedState,
    displayProperties.type,
    isEditing,
    isRoot,
    label,
    shouldFadeOut,
  ]);

  return (
    <>
      <StyledNode
        ref={menu.ref}
        className={containerClassName}
        onMouseDownCapture={onMouseDownCapture}
        onClickCapture={onClick}
        highlightColor={displayProperties.highlightColor}
        outlineColor={displayProperties.outlineColor}
        nodeType={type}
        isSelected={canShowBorder}
        isDragging={isDragging}
        draggable={canDrag}
        isConnection={isConnection}
        isCompleteEndState={endState === NodeEndStateType.complete}
        isFurtherAnalysisRequired={
          endState === NodeEndStateType.furtherAnalysis
        }
      >
        <div className="node-background" />
        <div className="node-border" />

        {canConnectEdgeToThisNode ? (
          <Handle position={Position.Left} type="target" />
        ) : null}

        {canShowLeftMetaAction ? (
          <MetaConnectionButton
            nodeId={id}
            position={MetaConnectionPosition.left}
            isParentNode={false}
          />
        ) : null}

        {canShowActions ? <NodeActions {...state} /> : null}

        <div className="node-content">
          {isEditing ? (
            <textarea
              ref={textAreaRef}
              className="content-input"
              autoFocus
              value={content}
              onChange={onNodeContentChange}
              onClick={onClick}
              onBlur={() => maybeCommitCreate(textAreaRef.current?.value)}
              onKeyDown={onKeyDown}
              placeholder="Caused By"
              maxLength={RCA_BOX_TEXT_MAX_LENGTH}
            />
          ) : (
            <Typography className="content-text">
              {isDevMode
                ? `[${label}] (${sortOrder}) chainItemId: ${chainItemId}\nlinkedTO:${linkedToChainItems
                    ?.map((x) => x.id)
                    .join(', ')}\nlinkedFROM:${linkedFromChainItems
                    ?.map((x) => x.id)
                    .join(', ')}`
                : label || 'Caused By'}
            </Typography>
          )}

          {renderDisplayProperties()}

          <LoadingIndicator show={isBusy} />
        </div>

        {shouldDisplayConnectionIndicator ? (
          <div className="connection-highlight">
            <ConnectionIcon />
          </div>
        ) : null}

        {canConnectEdgeFromThisNode ? (
          <Handle position={Position.Right} type="source" />
        ) : null}

        {shouldDisplayCollapsedIndicator ? (
          <CollapsedNodeIndicator nodeId={id} isDragging={isDragging} />
        ) : isConnection ? (
          <NodeLinkIcon />
        ) : shouldDisplayEndStateInfo ? (
          endState === NodeEndStateType.complete ? (
            <AnalysisCompleteInfo
              ref={elipsisMenu.ref}
              onElipsisClick={
                shouldDisplayEndStateElipsis
                  ? onEndStateElipsisClick
                  : undefined
              }
            />
          ) : endState === NodeEndStateType.furtherAnalysis ? (
            <FurtherAnalysisInfo
              ref={elipsisMenu.ref}
              onElipsisClick={
                shouldDisplayEndStateElipsis
                  ? onEndStateElipsisClick
                  : undefined
              }
            />
          ) : null
        ) : canShowRightMetaAction ? (
          <MetaConnectionButton
            nodeId={id}
            position={MetaConnectionPosition.right}
            isParentNode
          />
        ) : null}
      </StyledNode>

      <PopoverMenu {...menu} />
      <PopoverMenu {...elipsisMenu} />
    </>
  );
}
