import {
  selectDateOnlyFormatLong,
  selectDatetimeLongFormat,
  selectDatetimeShortFormat,
  selectLocale,
  selectTimeOnlyFormatShort,
} from '@store/locale/locale-selector';
import { store } from '@store/store';
import { getDate } from '@util/date-util';
import i18n from 'i18next';
import dayjs from 'dayjs';

export function formatDateCustom(value: any, format: string) {
  const date = getDate(value);
  if (date == null) return value;

  return date.format(format);
}

export function datetimeLongFormatter(value: any) {
  const format = selectDatetimeLongFormat(store.getState());

  return formatDateCustom(value, format);
}

export function datetimeShortFormatter(value: any) {
  const format = selectDatetimeShortFormat(store.getState());
  return formatDateCustom(value, format);
}

export function dateOnlyLongFormatter(value: any) {
  const format = selectDateOnlyFormatLong(store.getState());
  return formatDateCustom(value, format);
}

export function dateOnlyShortFormatter(value: any) {
  return dateOnlyLongFormatter(value);

  // There should now only be one date format as per client request.
  // leaving this code here just encase we need to revert back to two formats.

  // const format = selectDateOnlyFormatShort(store.getState());
  // return formatDateCustom(value, format);
}

export function timeOnlyShortFormatter(value: any) {
  const format = selectTimeOnlyFormatShort(store.getState());

  return formatDateCustom(value, format);
}

export function timespanFormatter(value: any) {
  const date = getDate(value);
  if (date == null) return value;

  const locale = selectLocale(store.getState());
  const dayJsDate = date.locale(locale);
  return dayJsDate.fromNow();
}

function getOrdinal(n: number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

export function todayYesterdayDateFormatter(value: any) {
  const date = getDate(value);
  if (date == null) return value;

  const locale = selectLocale(store.getState());
  const dayJsDate = date.locale(locale);
  const today = dayjs().locale(locale);
  const yesterday = today.subtract(1, 'day');

  if (dayJsDate.isSame(today, 'day')) {
    return 'Today';
  }

  if (dayJsDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  }

  if (dayJsDate.isSame(today, 'year')) {
    const formattedDate = dayJsDate.format('ddd D MMM');
    const ordinalFormattedDate = formattedDate.replace(
      /\b(\d{1,2})\b/,
      (match, p1) => getOrdinal(parseInt(p1))
    );
    return ordinalFormattedDate;
  }

  const formattedYearDate = dayJsDate.format('ddd D MMM YYYY');
  const ordinalYearDate = formattedYearDate.replace(
    /\b(\d{1,2})\b/,
    (match, p1) => getOrdinal(parseInt(p1))
  );
  return ordinalYearDate;
}

export function currencyFormatter(
  value: any,
  opt = { decimalPlaces: 0, currency: 'GBP' }
) {
  const sanitisedValue = value ?? 0;

  const locale = selectLocale(store.getState());

  let formatter: Intl.NumberFormat;
  try {
    formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: opt.currency,
      maximumFractionDigits: opt.decimalPlaces,
    });
  } catch {
    formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      maximumFractionDigits: opt.decimalPlaces,
    });
  }

  return formatter.format(sanitisedValue);
}

export function numberFormatter(value: any, opt = { decimalPlaces: 0 }) {
  const sanitisedValue = value ?? 0;

  const locale = selectLocale(store.getState());
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: opt.decimalPlaces,
  });
  return formatter.format(sanitisedValue);
}

export function resetFormatters() {
  const formatter = i18n.services.formatter!;
  formatter.add('datetime', datetimeLongFormatter);
  formatter.add('datetimeShort', datetimeShortFormatter);
  formatter.add('date', dateOnlyLongFormatter);
  formatter.add('dateShort', dateOnlyShortFormatter);
  formatter.add('timespan', timespanFormatter);
}
