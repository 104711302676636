import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { useAppSelector } from '@store/store';
import { useLazyGetNotificationsQuery } from '@api/endpoints/notification.api';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { selectIsLoggedInAndFullyAuthenticated } from '@store/user/user-selectors';

interface Props {
  applyTopMargin?: boolean;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  position: 'relative',
  display: 'inline-flex',
  flex: '0 0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',

  svg: {
    height: '1.4rem',
  },

  '.counter': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.primary.main,
    position: 'absolute',
    top: 5,
    right: 5,
    transform: 'translate(50%, -50%)',
    width: '1rem',
    height: '1rem',
    fontSize: '0.7rem',
    borderRadius: '50%',
    color: 'white',
  },
}));

export default function NotificationsIcon({ applyTopMargin }: Props) {
  const canFetchNotifications = useAppSelector(
    selectIsLoggedInAndFullyAuthenticated
  );

  const [count, setCount] = useState(0);

  const [getNotifications] = useLazyGetNotificationsQuery();

  useEffect(() => {
    async function fetch() {
      try {
        const result = await getNotifications({ skip: 0, take: 25 }).unwrap();
        const unreadCount = result.model.filter((n) => !n.read).length;

        setCount(unreadCount);

        // let result2: PaginatedResult<NotificationResource>;
        //
        // const mockResources: Array<NotificationResource> = [];
        // for (let i = 0; i < 10; i++) {
        //   mockResources.push({
        //     notificationId: i + 1,
        //     read: i % 2 === 0,
        //     notificationTypeId: 1,
        //     companyUserId: 1,
        //     companyUserName: 'John Doe',
        //     fromCompanyUserId: 2,
        //     fromCompanyUserName: 'Jane Doe',
        //     description: 'This is a notification',
        //     created: new Date().toISOString(),
        //   });
        // }
        //
        // result2 = {
        //   count: 10,
        //   model: mockResources,
        //   totalCount: 10,
        // };

        // const unreadCount = result2.model.filter((n) => !n.read).length;
        // setCount(unreadCount);
      } catch (e) {
        console.log('Notification error: ', e);
      }
    }

    if (canFetchNotifications) {
      fetch();
      const interval = setInterval(fetch, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [canFetchNotifications, getNotifications]);

  return (
    <Container>
      <FontAwesomeIcon icon={faBell} />
      {count > 0 && <div className="counter">{count}</div>}
    </Container>
  );
}
