import useOutcomeReport from '@components/outcome-report/outcome-report-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import { styled } from '@mui/material';
import CoverSection from '@components/outcome-report/sections/cover-section';
import OverviewSection from '@components/outcome-report/sections/overview-section';
import ExecSummarySection from '@components/outcome-report/sections/exec-summary-section';
import EvidenceSection from '@components/outcome-report/sections/evidence-section';
import TeamMembersSection from '@components/outcome-report/sections/team-members-section';
import ChartSection from '@components/outcome-report/sections/chart-section';
import ImpactsSection from '@components/outcome-report/sections/impacts-section';
import SolutionsSection from '@components/outcome-report/sections/solutions-section';
import InsightsSection, {
  InsightSectionProps,
} from '@components/outcome-report/sections/insights-section';
import ClosingStatementSection from '@components/outcome-report/sections/closing-statement-section';
import { useMemo, useRef } from 'react';

interface Props {
  caseId: number;
  outcomeId: number;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowY: 'auto',
  '> div.dark, > div > div.dark': {
    background:
      'url(/svgs/darkBottomLeft.svg) no-repeat bottom left, url(/svgs/darkTopRight.svg) no-repeat top right #00223A',
    'h2, .section-title, p': {
      color: '#fff',
    },
    '.chart-title-container': {
      '.chart-title': {
        color: '#fff',
      },
      '.chart-percentage': {
        color: '#fff',
      },
    },
  },
  '> div.light, > div > div.light': {
    'h2, .section-title, p': {
      color: '#00223A',
    },
    background: '#ECEDF0',
  },
  '> div.white, > div > div.white': {
    'h2, .section-title, p': {
      color: '#00223A',
    },
    background: '#fff',
  },
  '> div.white > div, > div.light > div, > div.dark > div, > div > div.white > div,  > div  > div.light > div,  > div > div.dark > div':
    {
      padding: '100px 70px',
      gap: '50px',
      maxWidth: 1600,
      margin: '0 auto',

      '&#summary': {
        padding: '100px 70px',
        gap: '60px',
        maxWidth: 1100,
      },
    },
  '.chart-title-container': {
    '.chart-title': {
      color: '#00223A',
    },
    '.chart-percentage': {
      color: '#00223A',
    },
  },
  '.insights-table': {
    tr: {
      td: {
        backgroundColor: 'white',
        padding: '12px 15px',
        borderTop: '1px solid rgba(0,0,0,0.25)',
        borderBottom: '1px solid rgba(0,0,0,0.25)',
        '&:first-child': {
          borderRadius: '4px 0 0 4px',
          borderLeft: '1px solid rgba(0,0,0,0.25)',
          padding: '12px 15px',
        },
        '&:last-child': {
          borderRadius: '0 4px 4px 0',
          borderRight: '1px solid rgba(0,0,0,0.25)',
          padding: '12px 15px',
        },
      },
      '&.no-data': {
        td: {
          backgroundColor: '#ECEDF0',
          borderTop: '1px solid #E0E0E0',
          borderBottom: '1px solid #E0E0E0',
          '&:first-child': {
            borderLeft: '1px solid #E0E0E0',
          },
          '&:last-child': {
            borderRight: '1px solid #E0E0E0',
          },
        },
      },
      '&.selected': {
        td: {
          borderTop: '2px solid #00223A',
          borderBottom: '2px solid #00223A',
          padding: '11px 15px',
          '&:first-child': {
            borderLeft: '2px solid #00223A',
            padding: '11px 15px 11px 14px',
          },
          '&:last-child': {
            borderRight: '2px solid #00223A',
            padding: '11px 14px 11px 15px',
          },
        },
      },
    },
  },
  '> .dark': {
    '.card': {
      background: '#fff',
      borderRadius: 8,
    },
    '.insights-table': {
      'tr.selected': {
        td: {
          borderTop: '2px solid #FF6C00',
          borderBottom: '2px solid #FF6C00',
          '&:first-child': {
            borderLeft: '2px solid #FF6C00',
          },
          '&:last-child': {
            borderRight: '2px solid #FF6C00',
          },
        },
      },
    },
  },
  '> .light': {
    '.card': {
      background: '#fff',
      border: '1px solid rgba(0,0,0,0.25)',
      borderRadius: 8,
    },
    '.insights-table tr.no-data': {
      td: {
        backgroundColor: '#ECEDF0',
        borderTop: '1px solid rgba(0,0,0,0.2)',
        borderBottom: '1px solid rgba(0,0,0,0.2)',
        '&:first-child': {
          borderLeft: '1px solid rgba(0,0,0,0.2)',
        },
        '&:last-child': {
          borderRight: '1px solid rgba(0,0,0,0.2)',
        },
      },
    },
    '.insights-table tr.selected': {
      td: {
        borderTop: '2px solid #00223A',
        borderBottom: '2px solid #00223A',
        padding: '11px 15px',
        '&:first-child': {
          borderLeft: '2px solid #00223A',
          padding: '11px 15px 11px 14px',
        },
        '&:last-child': {
          borderRight: '2px solid #00223A',
          padding: '11px 14px 11px 15px',
        },
      },
    },
  },
  '> .white': {
    '.card': {
      background: '#FFF9EF',
      border: '1px solid rgba(0,0,0,0.25)',
      borderRadius: 8,
    },
  },
  'button, a': {
    svg: {
      marginLeft: 8,
    },
  },
  '.card': {
    '.title': {
      color: '#000000',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '26px',
    },
    '.subTitle': {
      color: '#636D73',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '23px',
    },
    '.label': {
      color: '#949FA2',
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '150%',
      letterSpacing: '0.15px',
      textTransform: 'uppercase',
    },
    '.flat-badge': {
      borderRadius: '4px',
    },
    '.cardFooter': {
      marginTop: 'auto',
    },
  },

  '> #insights-row-2, > #insights-row-4, > #insights-row-6, > #insights-row-8, > #insights-row-10':
    {
      '.layout': {
        flexDirection: 'row-reverse',
      },
    },
});

export type ColorClass = 'dark' | 'light' | 'white';

export default function OutcomeReportScaffold({ caseId, outcomeId }: Props) {
  const reportScrollContainer = useRef<HTMLDivElement>(null);
  const colorClasses: ColorClass[] = ['dark', 'light', 'white'];
  let colourClassIndex = -1;

  const getColorClass = (colorClass?: ColorClass): ColorClass => {
    if (colorClass && colorClasses.includes(colorClass)) {
      colourClassIndex = colorClass.indexOf(colorClass) - 1;
    }

    const nextColorIndex = (colourClassIndex + 1) % colorClasses.length;
    colourClassIndex++;
    return colorClasses[nextColorIndex];
  };

  const projectColorClass = (): ColorClass => {
    const nextColorIndex = (colourClassIndex + 1) % colorClasses.length;
    return colorClasses[nextColorIndex];
  };

  const state = useOutcomeReport(caseId, outcomeId);
  const { isLoading, result, outcomeDetail, scaleOptionsLookup } = state;

  const insightReports = useMemo(() => {
    const outcome = outcomeDetail;
    const report = result;

    if (outcome == null || report == null || !outcome.showInsights) {
      return [];
    }

    let id = 1;
    const reports: Array<InsightSectionProps> = [];
    if (
      outcome.showDefaultThemesOutcome &&
      report.themeCoverageReport != null
    ) {
      reports.push({
        id: id++,
        title: 'Theme Coverage',
        report: report.themeCoverageReport,
      });
    }

    if (
      outcome.showDefaultSolutionsOutcome &&
      report.solutionCoverageReport != null
    ) {
      reports.push({
        id: id++,
        title: 'Solution Coverage',
        report: report.solutionCoverageReport,
      });
    }

    if (report.selectedReports != null) {
      for (const selectedReport of report.selectedReports) {
        if (selectedReport.data.length === 0) {
          continue;
        }

        reports.push({
          id: id++,
          title: selectedReport.request.name ?? '',
          report: selectedReport,
        });
      }
    }
    return reports;
  }, [outcomeDetail, result]);

  if (isLoading) {
    return <LoadingIndicator show />;
  }

  const outcome = outcomeDetail!;
  const report = result!;

  const caseDetails = report.case!;

  return (
    <Container ref={reportScrollContainer} id="report-scroll-container">
      <CoverSection caseDetails={caseDetails} />
      <OverviewSection caseDetails={caseDetails} />
      {outcome.showOverview && outcome.executiveSummary ? (
        <ExecSummarySection
          outcome={outcome}
          className={getColorClass('dark')}
        />
      ) : null}
      {outcome.showEvidence && (
        <EvidenceSection
          evidence={report.evidence!}
          evidenceOverview={report.evidenceOverview!}
          className={getColorClass()}
          coverage={report.evidenceCoverageReport!}
        />
      )}
      {outcome.showTeam &&
      report.teamMembers != null &&
      report.teamMembers.length > 0 ? (
        <TeamMembersSection
          teamMembers={report.teamMembers}
          className={
            projectColorClass() === 'light'
              ? getColorClass('white')
              : getColorClass()
          }
        />
      ) : null}
      {outcome.showAnalysis && (
        <ChartSection
          chainDetail={report.chain!}
          healthScore={report.healthScore}
          themeCount={report.themeCount}
        />
      )}
      {outcome.showImpacts &&
      report.caseImpacts != null &&
      report.caseImpacts.length > 0 ? (
        <ImpactsSection
          impacts={report.caseImpacts}
          optionsLookup={scaleOptionsLookup}
          className={getColorClass('dark')}
        />
      ) : null}
      {outcome.showSolutions &&
      report.solutionCount != null &&
      report.solutionCount > 0 ? (
        <SolutionsSection
          solutionCount={report.solutionCount}
          solutions={report.solutions!}
          coverage={report.solutionCoverageReport!}
          termReport={report.solutionTermReport!}
          effectivnessReport={report.solutionEffectivenessReport!}
          className={getColorClass()}
          limit={outcome.noOfSolutions ?? 6}
        />
      ) : null}
      {insightReports.length > 0
        ? insightReports.map((insightReport) => (
            <InsightsSection {...insightReport} className={getColorClass()} />
          ))
        : null}
      {outcome.showRecommendations && outcome.recommendations ? (
        <ClosingStatementSection
          outcome={outcome}
          className={getColorClass()}
        />
      ) : null}
    </Container>
  );
}
