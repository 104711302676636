import { Option } from '@api/types/option';
import { styled } from '@mui/material';
import FlatBadge from '@components/badges/flat-badge';
import { truncateString } from '@util/string-util';

interface Props {
  items: Array<Option<number | string>>;
  maxCount?: number;
  providedPlusCount?: number;
}

const Container = styled('div', { target: 'grouped-avatars-container' })(
  ({ theme: { palette } }) => ({
    display: 'flex',
    gap: 4,
  })
);

export default function GroupedBadges({
  items,
  providedPlusCount,
  maxCount = 1,
}: Props) {
  const plusCount = !!providedPlusCount
    ? providedPlusCount
    : items.length > maxCount
    ? items.length - maxCount
    : null;

  return (
    <Container>
      {items
        .slice(0, Math.min(items.length, maxCount))
        .map(({ id, label }, index) => (
          <FlatBadge key={id} small>
            {truncateString(label, 20)}
          </FlatBadge>
        ))}
      {plusCount != null ? <span className="plus">+{plusCount}</span> : null}
    </Container>
  );
}
