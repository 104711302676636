import { FormHelperText, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { ElipsisButton, OutlinedButton } from '@components/buttons';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import CaseFileManagerModal, {
  CaseFileManagerProps,
} from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal';
import { isNullOrEmpty } from '@util/string-util';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import CaseFileItem from '@components/cards/case-file-item';
import { Fragment } from 'react';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  value: Array<CaseFileListItemResponse>;
  onChange: (value: Array<CaseFileListItemResponse>) => void;
  error?: string;
  readonly?: boolean;
  allowAdd?: boolean;
  addButtonlabel?: string;
  allowRemove?: boolean;
  showLabel?: boolean;
  label?: string;
  elipsisActions?: (file: CaseFileListItemResponse) => Array<PopoverMenuAction>;
}

const Container = styled(Column, { target: 'case-file-manager-input' })({});

export default function CaseFileManagerInput({
  value,
  onChange,
  error,
  addButtonlabel,
  readonly = false,
  allowAdd = !readonly,
  allowRemove = !readonly,
  showLabel = !readonly,
  label,
  elipsisActions,
}: Props) {
  const { showPopup } = useUiPopup();

  const openPicker = async () => {
    const files = await showPopup<
      CaseFileManagerProps,
      Array<CaseFileListItemResponse>
    >(CaseFileManagerModal, {
      preselectedFileIds: value.map((v) => v.caseFileId),
    });

    if (files != null) {
      onChange(files);
    }
  };

  return (
    <Container>
      {!readonly || showLabel ? (
        <Row>
          <Typography variant="body1">
            {`${label ?? 'Files'}`} ({value.length})
          </Typography>
          <Spacer />
          {allowAdd && (
            <OutlinedButton onClick={openPicker}>
              {addButtonlabel ?? 'Add File or Location'}
            </OutlinedButton>
          )}
        </Row>
      ) : null}
      {!isNullOrEmpty(error) ? (
        <>
          <FormHelperText error>{error}</FormHelperText>
        </>
      ) : null}
      {value.length > 0 ? (
        <>
          <Gap size={15} />
          {value.map((file) => (
            <Fragment key={file.caseFileId}>
              <CaseFileItem
                caseFile={file}
                displayCreatedBy={false}
                trailing={
                  elipsisActions != null || !readonly || allowRemove ? (
                    <ElipsisButton
                      actions={
                        elipsisActions != null
                          ? elipsisActions!(file)
                          : [
                              {
                                label: 'Remove',
                                onClick: () => {
                                  onChange(
                                    value.filter(
                                      (v) => v.caseFileId !== file.caseFileId
                                    )
                                  );
                                },
                              },
                            ]
                      }
                    />
                  ) : undefined
                }
              />
              <Gap size={10} />
            </Fragment>
          ))}
        </>
      ) : null}
    </Container>
  );
}
