import { PinInfo } from '@components/google-map/pin-display-map';
import RcaCard from '@components/cards/rca-card';
import ContentFooterCard from '@components/cards/content-footer-card';
import { styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import { getAddressString } from '@util/string-util';
import { useMemo } from 'react';
import Row from '@components/layout-util-components/row';
import { CreateAnalysisType } from '@store/create-analysis-process/types';
import { OutlinedButton } from '@components/buttons';
import ActionsRow from '@components/layout-util-components/actions-row';
import FilledBadge from '@components/badges/filled-badge';
import HealthBadge from '@components/badges/health-badge';
import { timespanFormatter } from '@i18n/formatters';
import { useNavigate } from 'react-router-dom';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  info: PinInfo;
  onFilterByLocationId: (locationId: number) => void;
}

const StyledReportingLocationContent = styled('div')({
  '.stat-title': {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '12px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#00000080',
  },

  '.big-stat': {
    fontFamily: 'Roboto',
    fontSize: '48px',
    fontWeight: '400',
    lineHeight: '56px',
    letterSpacing: '0px',
    textAlign: 'left',
  },

  '.stat': {
    fontFamily: 'Roboto',
    fontSize: '30px',
    fontWeight: '300',
    lineHeight: '35px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
});

const StyledIncidentLocationContent = styled('div')(
  ({ theme: { palette } }) => ({
    '.row-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      maxHeight: 400,
      overflowY: 'auto',

      '.detail-row': {
        padding: 10,
        background: palette.common.white,

        '.name': {
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '21px',
          letterSpacing: '0.15000000596046448px',
          textAlign: 'left',
        },

        '.created': {
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '0.4000000059604645px',
          textAlign: 'left',
        },
      },
    },
  })
);

export default function CasePinCard({ info, onFilterByLocationId }: Props) {
  const navigate = useNavigate();

  const { cases } = info;
  const numberOfCases = cases.length;

  const incidentLocation = useMemo(() => {
    if (cases.length === 0) {
      return;
    }

    const location = cases[0].incidentLocation;
    if (location == null) {
      return;
    }

    return getAddressString({ ...location }) || location.name;
  }, [cases]);

  const reportingLocation = useMemo(() => {
    if (cases.length === 0) {
      return;
    }

    const location = cases[0].reportingLocation;
    if (location == null) {
      return;
    }

    return getAddressString({ ...location }) || location.name;
  }, [cases]);

  const problemCount = useMemo(() => {
    return cases.filter(
      (x) => x.caseAnalysisTypeId === CreateAnalysisType.problem
    ).length;
  }, [cases]);

  const riskCount = useMemo(() => {
    return cases.filter((x) => x.caseAnalysisTypeId === CreateAnalysisType.risk)
      .length;
  }, [cases]);

  const opportunityCount = useMemo(() => {
    return cases.filter(
      (x) => x.caseAnalysisTypeId === CreateAnalysisType.improvement
    ).length;
  }, [cases]);

  if (numberOfCases === 1) {
    return <RcaCard data={cases[0]} afterClickRedirect={() => {}} />;
  }

  if (info.isReportingLocation) {
    return (
      <ContentFooterCard
        content={
          <StyledReportingLocationContent>
            <Column>
              <Typography variant="caption">Reporting Location</Typography>
              <Gap size={6} />
              <Typography>{reportingLocation || '-'}</Typography>
              <Gap size={20} />
              <Typography className="stat-title">Linked Incidents</Typography>
              <Typography className="big-stat">{cases.length}</Typography>
              <Gap size={20} />
              <Row spaceBetween>
                <Column>
                  <Typography className="stat-title">Problems</Typography>
                  <Gap size={8} />
                  <Typography className="stat">{problemCount}</Typography>
                </Column>
                <Column>
                  <Typography className="stat-title">Risks</Typography>
                  <Gap size={8} />
                  <Typography className="stat">{riskCount}</Typography>
                </Column>
                <Column>
                  <Typography className="stat-title">Opportunities</Typography>
                  <Gap size={8} />
                  <Typography className="stat">{opportunityCount}</Typography>
                </Column>
              </Row>
            </Column>
          </StyledReportingLocationContent>
        }
        footer={
          <ActionsRow>
            <OutlinedButton
              onClick={() =>
                onFilterByLocationId(
                  cases[0].reportingLocation!.companyLocationId
                )
              }
            >
              View incidents
            </OutlinedButton>
          </ActionsRow>
        }
      />
    );
  }

  return (
    <ContentFooterCard
      widthOverride={300}
      content={
        <StyledIncidentLocationContent>
          <Column>
            <Typography variant="caption">Incident Location</Typography>
            <Gap size={6} />
            <Typography>{incidentLocation || '-'}</Typography>
            <Gap size={20} />
            <div className="row-container">
              {cases.map(
                ({
                  caseId,
                  caseAnalysisTypeName,
                  healthScore,
                  name,
                  created,
                }) => (
                  <Column
                    key={caseId}
                    className="detail-row"
                    onClick={() => navigate(`/rca/tabs/${caseId}`)}
                  >
                    <Row spaceBetween>
                      <FilledBadge>{caseAnalysisTypeName}</FilledBadge>
                      <Gap size={8} />
                      <HealthBadge health={healthScore} />
                    </Row>
                    <Gap size={6} />
                    <Typography className="name">{name}</Typography>
                    <Gap size={6} />
                    <Typography className="created">{`Created ${timespanFormatter(
                      created
                    )}`}</Typography>
                  </Column>
                )
              )}
            </div>
          </Column>
        </StyledIncidentLocationContent>
      }
    />
  );
}
