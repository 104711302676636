import { AppDispatch, RootState } from '@store/store';
import caseReportApi from '@api/endpoints/case-report.api';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import {
  closeReport,
  RunReportModel,
  setLoadedReportData,
  setShowReportId,
  setShowRunModel,
} from '@store/reports/reports-slice';

export const showReportByRunRequest =
  (model: RunReportModel, reset = true) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setShowRunModel({ model, reset }));

      const caseId = selectCurrentRcaCaseId(getState());
      const data = await dispatch(
        caseReportApi.endpoints.getRunReport.initiate(
          {
            caseId,
            reportTypeId: model.reportTypeId,
            reportSubTypeId: model.reportSubTypeId,
            reportRankById: model.reportRankById,
            ids: model.ids,
            impactType: model.impactType,
            caseImpactTypeId: model.caseImpactTypeId,
            impactGroup: model.impactGroup,
            filterCaseImpactIds: model.filterCaseImpacts,
          },
          { forceRefetch: true }
        )
      ).unwrap();

      dispatch(
        setLoadedReportData({
          model,
          data,
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(closeReport());
    }
  };

export const showReportById =
  (reportId: number) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setShowReportId({ id: reportId, reset: true }));

      const caseId = selectCurrentRcaCaseId(getState());
      const report = await dispatch(
        caseReportApi.endpoints.getCaseReport.initiate(
          { reportId, caseId },
          { forceRefetch: true }
        )
      ).unwrap();

      const model: RunReportModel = {
        reportType: report.reportType,
        reportTypeId: report.reportTypeId,
        reportTypeName: report.caseName,
        reportSubType: report.reportSubType,
        reportSubTypeId: report.reportSubTypeId,
        reportSubTypeName: report.reportSubTypeName,
        reportRankBy: report.reportRankBy,
        reportRankById: report.reportRankById,
        reportRankByName: report.reportRankByName,
        ids: report.items.map((x) => x.id),
        impactType: report.impactType,
        caseImpactTypeId: report.caseImpactTypeId,
        impactGroup: report.impactGroup,
        filterCaseImpacts: report.filterCaseImpacts?.map((x) => x.id),
        caseImpactTypeName: report.caseImpactTypeName,
        filterCaseImpactNames: report.filterCaseImpacts?.map((x) => x.label),
        name: report.name,
      };

      dispatch(showReportByRunRequest(model, false));
    } catch (e) {
      console.log(e);
      dispatch(closeReport());
    }
  };
