import { Box } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import Spacer from '@components/layout-util-components/spacer';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { BaseFile } from '@api/types/file-types';
import { useEffect, useRef, useState } from 'react';
import { isNullOrEmpty } from '@util/string-util';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  file: BaseFile;
  onRemove: () => void;
  onUpdateName: (name: string) => void;
}

export default function FileItem({ file, onRemove, onUpdateName }: Props) {
  const { filename, length } = file;
  const [initialFileName] = useState(filename);
  const [newName, setNewName] = useState('');

  const updateRef = useRef(onUpdateName);
  useEffect(() => {
    if (isNullOrEmpty(newName)) {
      updateRef.current(initialFileName);
    } else {
      updateRef.current(newName);
    }
  }, [initialFileName, newName]);

  return (
    <div className="item complete">
      <Box width={244}>
        <WCTTextField
          placeholder="Add file name (optional)"
          value={newName}
          onChange={setNewName}
        />
      </Box>
      <Gap size={20} />
      <Spacer />
      <span>{initialFileName}</span>
      <Gap size={20} />
      {/* convert length to MB */}
      <span>{(length / (1024 * 1024)).toFixed(1)}mb</span>
      <Spacer />
      <Gap size={20} />
      <FlatButton onClick={onRemove}>
        <FontAwesomeIcon icon={faTrash} />
      </FlatButton>
    </div>
  );
}
