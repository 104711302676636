import { Drawer, DrawerProps, styled } from '@mui/material';
import useLensesPanel, {
  LensesPanelViewState,
} from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import OptionSelectView from '@pages/app/rca/tabs/lenses/views/option-select-view';
import ReportFormView from '@pages/app/rca/tabs/lenses/views/report-form-view';
import AllRecentLensesView from '@pages/app/rca/tabs/lenses/views/all-recent-lenses-view';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/store';
import { selectCurrentRcaCaseIdNullable } from '@store/rca-editor/selectors';
import { setIsHealthScorePanelOpen } from '@store/rca-editor/rca-editor-slice';
import useMaybeSnapToFocusedNode from '@pages/app/rca/tabs/hooks/use-maybe-snap-to-focused-node-hook';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const StyledDrawer = styled(Drawer, {
  target: 'health-score-side-panel',
})<DrawerProps>(({ theme: { palette } }) => ({
  '.MuiPaper-root': {
    top: '79px',
    height: 'calc(100vh - 79px)',
    width: 450,
    boxShadow: '0px 0px 15px 0px #00000040',
    background: '#fff',
    padding: 20,
    overflowY: 'auto',
    zIndex: '1000',
  },
}));

export default function LensesPanel({ isOpen, onClose }: Props) {
  const dispatch = useAppDispatch();
  const state = useLensesPanel();
  const caseId = useAppSelector(selectCurrentRcaCaseIdNullable);
  const navigate = useNavigate();
  const { viewState } = state;

  useMaybeSnapToFocusedNode(isOpen);

  useEffect(() => {
    if (isOpen && caseId != null) {
      dispatch(setIsHealthScorePanelOpen(false));
      navigate(`/rca/tabs/${caseId!}/chart`);
    }
  }, [caseId, dispatch, isOpen, navigate]);

  return (
    <StyledDrawer
      variant="persistent"
      open={isOpen}
      onClose={onClose}
      anchor="right"
      hideBackdrop
    >
      {viewState === LensesPanelViewState.optionSelect ? (
        <OptionSelectView onClose={onClose} state={state} />
      ) : viewState === LensesPanelViewState.reportForm ? (
        <ReportFormView onClose={onClose} state={state} />
      ) : viewState === LensesPanelViewState.allRecentLenses ? (
        <AllRecentLensesView onClose={onClose} state={state} />
      ) : null}
    </StyledDrawer>
  );
}
