import {
  ItemLink,
  NodeEndStateType,
  RcaEdge,
  RcaEdgeType,
  RcaNode,
  RcaNodeData,
  RcaNodeType,
  StorageNode,
} from '@store/rca-editor/types';
import { v4 as uuid } from 'uuid';
import { AppDispatch, RootState } from '@store/store';

import {
  addNodesToStorage,
  clearProximityEdge,
  decrementNodeBusyTracker,
  incrementNodeBusyTracker,
  NodePanelEditorTab,
  onConnectNode,
  onEdgesChange,
  onNodesChange,
  refreshNodes,
  resetFocus,
  resetOnDragIds,
  selectNodePanelEditorTab,
  setEditNodeContentId,
  setHoverVisibilityNodeId,
  setIsDraggingIntoStorageContainer,
  setOnDragIds,
  setProximityEdge,
  setSelectedNode,
  setSortedSiblings,
  setStorageGraphNode,
  setStorageNodes,
  toggleDevMode,
  unHighlightAllNodes,
  updateEdge,
  updateNode,
} from '@store/rca-editor/rca-editor-slice';
import {
  EdgeResetChange,
  NodeAddChange,
  NodeRemoveChange,
} from '@reactflow/core/dist/esm/types/changes';
import { EdgeAddChange, EdgeRemoveChange, getIncomers } from 'reactflow';
import {
  makeSelectAlLDescendants,
  makeSelectChildCount,
  makeSelectChildNodes,
  makeSelectChildNodesWithSubMetaChildNodes,
  makeSelectConnectionChildNodes,
  makeSelectConnectionNode,
  makeSelectConnectionNodesFor,
  makeSelectNode,
  makeSelectNodeFromChainItemId,
  makeSelectNonMetaChildNodes,
  makeSelectOutgoingEdges,
  makeSelectParentConnectingEdge,
  makeSelectParentNode,
  selectChainId,
  selectDraggingNodeDescendants,
  selectDragHolderNode,
  selectEdges,
  selectIsDraggingIntoStorageContainer,
  selectMetaNodes,
  selectNodes,
  selectOnDragOriginalParentId,
  selectProximityEdge,
  selectStorageGraphNode,
  selectStorageNodes,
} from '@store/rca-editor/selectors';
import { stratify, tree } from 'd3-hierarchy';
import {
  getRFPosition,
  RFDirection,
  rfPositionMap,
} from '@util/react-flow-utils';
import { RcaUtil } from '@util/rca-util';
import { isProd } from '@util/env';
import chainItemApi from '@api/endpoints/chain/chain-item.api';
import { saveGraphState } from '@store/rca-graph-saver/rca-graph-saver-actions';
import { ChainItemResource } from '@api/types/chain/chain-item.resource';
import chainItemStorageApi from '@api/endpoints/chain/chain-item-storage.api';
import { setAlert } from '@store/ui/ui-slice';
import { XYPosition } from '@reactflow/core/dist/esm/types/utils';
import { isApiError } from '@api/types/api-error';
import { CreateChainItemRequest } from '@api/types/chain/create-chain-item.request';

const layout = tree<RcaNode>()
  // the node size configures the spacing between the nodes ([width, height])
  .nodeSize([RcaUtil.NODE_HEIGHT, RcaUtil.NODE_WIDTH])
  // this is needed for creating equal space between all nodes
  .separation(() => 1.5);

export const layoutNodes = (n: Array<RcaNode>, edges: Array<RcaEdge>) => {
  const nodes = [...n].sort((a, b) => a.data.sortOrder - b.data.sortOrder);
  const metaNodes = nodes.filter(RcaUtil.isMetaNode);

  const filteredNodes = nodes.filter((node) => metaNodes.indexOf(node) === -1);

  const hierarchy = stratify<RcaNode>()
    .id((d) => d.id)
    // get the id of each node by searching through the edges
    // this only works if every node has one connection
    .parentId((d: RcaNode) => {
      const edge = edges.find((e: RcaEdge) => e.target === d.id);
      if (edge == null) {
        return;
      }

      const metaNode = metaNodes.find((node) => node.id === edge.source);
      // Not connected to a meta node, so return the source (parent)
      if (metaNode == null) {
        return edge.source;
      }

      // Is a meta node... so we need to return this meta nodes' parent instead
      return edges.find((e: RcaEdge) => e.target === metaNode.id)?.source;
    })(filteredNodes);

  const root = layout(hierarchy);

  const updatedFilteredNodes = filteredNodes.map((node) => {
    // find the node in the hierarchy with the same id and get its coordinates
    const { x, y } = root.find((d) => d.id === node.id) ?? {
      x: node.position.x,
      y: node.position.y,
    };

    const direction: RFDirection = 'LR';
    return {
      ...node,
      sourcePosition: rfPositionMap[direction[1]],
      targetPosition: rfPositionMap[direction[0]],
      position: getRFPosition(x, y, direction),
    };
  });

  const updatedMetaNodes = metaNodes.map((node) => {
    const parentNode = getIncomers(node, updatedFilteredNodes, edges)?.[0];

    // Get all meta node children
    const childEdges = edges.filter((edge) => edge.source === node.id);
    const childNodes = childEdges.map(
      (edge) => updatedFilteredNodes.find((node) => edge.target === node.id)!
    );
    // Average the Y co-ordinate, so it's always in the middle
    let y = 0,
      x = childNodes[0].position.x - 75;
    for (const childNode of childNodes) {
      y += childNode.position.y + RcaUtil.HALF_NODE_HEIGHT - 12.5;
    }

    y /= childNodes.length;

    // If y is very close to the parent node, we'll move it inline with it
    if (parentNode != null && Math.abs(parentNode.position.y - y) < 50) {
      y = parentNode.position.y + RcaUtil.HALF_NODE_HEIGHT - 12.5;
    }

    return {
      ...node,
      position: { x, y },
    };
  });

  return [...updatedFilteredNodes, ...updatedMetaNodes];
};

export const layoutChart =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const edges = selectEdges(getState());
    const nodes = selectNodes(getState());

    const laidOutNodes = layoutNodes(nodes, edges);

    dispatch(refreshNodes(laidOutNodes));
  };

const removeInvalidNodes =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const nodeChangeEvents: Array<NodeRemoveChange> = [];
    const edgeChangeEvents: Array<EdgeRemoveChange | EdgeAddChange> = [];

    const metaNodes = selectMetaNodes(getState());
    for (const metaNode of metaNodes) {
      const childCount = makeSelectChildCount(metaNode.id)(getState());

      // Meta node with no children... simply remove it
      if (childCount === 0) {
        const parentEdge = makeSelectParentConnectingEdge(metaNode.id)(
          getState()
        );
        nodeChangeEvents.push({ type: 'remove', id: metaNode.id });
        edgeChangeEvents.push({ type: 'remove', id: parentEdge.id });

        // Meta node with 1 child... remove the meta node and reparent the child
        // to the meta node's parent
      } else if (childCount === 1) {
        const parentEdge = makeSelectParentConnectingEdge(metaNode.id)(
          getState()
        );
        const child = makeSelectChildNodes(metaNode.id)(getState())[0];
        const childParentEdge = makeSelectParentConnectingEdge(child.id)(
          getState()
        );

        nodeChangeEvents.push({ type: 'remove', id: metaNode.id });
        edgeChangeEvents.push({ type: 'remove', id: parentEdge.id });
        edgeChangeEvents.push({
          type: 'remove',
          id: childParentEdge.id,
        });

        edgeChangeEvents.push({
          type: 'add',
          item: {
            ...childParentEdge,
            source: parentEdge.source,
          },
        });
      }
    }

    dispatch(onNodesChange(nodeChangeEvents));
    dispatch(onEdgesChange(edgeChangeEvents));
  };

const sanitizeLinksForNodeRemoval =
  (node: RcaNode, willReparentChildren: boolean) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const chainItemId = node.data.chainItemId;
    if (chainItemId == null) {
      return;
    }

    // If child nodes will be reparented, we'll still need to remove immediate
    // child connections otherwise these would need to be relinked.
    const descendants = [
      node,
      ...(willReparentChildren
        ? makeSelectConnectionChildNodes(node.id)(getState())
        : makeSelectAlLDescendants(node.id)(getState())),
    ];

    // Get all descendants that are connection nodes, we will use this
    // to remove references FROM their real nodes
    const connectionNodesToRemove = descendants.filter(
      (x) => x.type === RcaNodeType.connection
    );

    // Get all descendants that are NOT connection nodes
    const nonConnectionNodesToRemove = descendants.filter(
      (x) => x.type !== RcaNodeType.connection
    );

    // convert non connection node array to an array of chain item IDs
    const removedNodeIds = nonConnectionNodesToRemove
      .map((x) => x.data.chainItemId)
      .filter((x) => x != null) as Array<number>;

    const nodesToRemove: Array<NodeRemoveChange> = [];
    const edgeChanges: Array<EdgeRemoveChange> = [];

    // Remove descendant connections and their references
    for (const connectionNode of connectionNodesToRemove) {
      const connectionChainItemId = connectionNode.data.chainItemId;
      if (connectionChainItemId == null) {
        continue;
      }

      const realNode = makeSelectNodeFromChainItemId(connectionChainItemId)(
        getState()
      );
      if (realNode == null) {
        continue;
      }

      const linkedFromChainItems = (
        realNode.data.linkedFromChainItems ?? []
      ).filter((x) => !removedNodeIds.includes(x.id));

      dispatch(
        updateNodeData(realNode.id, {
          linkedFromChainItems,
        })
      );

      const connectionNodeParentEdge = makeSelectParentConnectingEdge(
        connectionNode.id
      )(getState());
      edgeChanges.push({ type: 'remove', id: connectionNodeParentEdge.id });
      nodesToRemove.push({ type: 'remove', id: connectionNode.id });
    }

    // Remove any references TO nodes that are being removed
    for (const removedDescendant of nonConnectionNodesToRemove) {
      const removedDescendantChainItemId = removedDescendant.data.chainItemId;
      if (removedDescendantChainItemId == null) {
        continue;
      }
      for (const linkedNode of removedDescendant.data.linkedFromChainItems ??
        []) {
        const realNode = makeSelectNodeFromChainItemId(linkedNode.id)(
          getState()
        );
        if (realNode == null) {
          continue;
        }

        const newLinkedToArray = (
          realNode.data.linkedToChainItems ?? []
        ).filter((x) => x.id !== removedDescendantChainItemId);

        const connectionNode = makeSelectConnectionNode(
          removedDescendantChainItemId
        )(getState());

        if (connectionNode != null) {
          const connectionNodeParentEdge = makeSelectParentConnectingEdge(
            connectionNode.id
          )(getState());
          edgeChanges.push({ type: 'remove', id: connectionNodeParentEdge.id });
          nodesToRemove.push({ type: 'remove', id: connectionNode.id });
        }

        dispatch(
          updateNodeData(realNode.id, {
            linkedToChainItems: newLinkedToArray,
          })
        );
      }
    }

    dispatch(onNodesChange(nodesToRemove));
    dispatch(onEdgesChange(edgeChanges));
  };

export const removeNode =
  (
    nodeId: string,
    opt: { moveToStorage?: boolean; reparentChildren?: boolean } = {
      moveToStorage: false,
      reparentChildren: false,
    }
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null || node.data.isRoot) {
      return;
    }

    dispatch(sanitizeLinksForNodeRemoval(node, opt.reparentChildren ?? false));

    const parent = makeSelectParentNode(nodeId)(getState())!;
    const parentEdge = makeSelectParentConnectingEdge(nodeId)(getState());

    const nodesToRemove: Array<NodeRemoveChange> = [];
    const edgeChanges: Array<EdgeRemoveChange | EdgeAddChange> = [];

    nodesToRemove.push({ type: 'remove', id: nodeId });
    edgeChanges.push({ type: 'remove', id: parentEdge.id });

    if (opt.reparentChildren) {
      const children = makeSelectChildNodes(nodeId, true)(getState());
      for (const child of children) {
        const childParentEdge = makeSelectParentConnectingEdge(child.id)(
          getState()
        );
        edgeChanges.push({ type: 'remove', id: childParentEdge.id });

        edgeChanges.push({
          type: 'add',
          item: {
            id: `${parent.id}->${child.id}`,
            source: parent.id,
            target: child.id,
            targetHandle: null,
            sourceHandle: null,
          },
        });

        dispatch(
          updateNodeData(child.id, {
            ...child.data,
            sortOrder: children.length === 1 ? node.data.sortOrder : 99999,
          })
        );
      }
    } else {
      const descendants = makeSelectAlLDescendants(nodeId, true)(getState());
      for (const descendant of descendants) {
        const descendantParentEdge = makeSelectParentConnectingEdge(
          descendant.id
        )(getState());
        edgeChanges.push({ type: 'remove', id: descendantParentEdge.id });
        nodesToRemove.push({ type: 'remove', id: descendant.id });
      }

      if (opt.moveToStorage) {
        dispatch(addNodesToStorage([node]));

        // We need to ensure that meta and connection nodes don't get moved to storage.
        const nonMetaDescendants = descendants.filter(
          (x) => !RcaUtil.isMetaNode(x) && x.type !== RcaNodeType.connection
        );
        if (nonMetaDescendants.length > 0) {
          dispatch(addNodesToStorage(nonMetaDescendants));
        }
      }
    }

    dispatch(onEdgesChange(edgeChanges));
    dispatch(onNodesChange(nodesToRemove));

    dispatch(sanitizeChildSortOrders(parent.id));
    dispatch(removeInvalidNodes());
    dispatch(resetFocus());
    dispatch(layoutChart());

    const { chainItemId } = node.data;
    if (chainItemId != null) {
      await dispatch(saveGraphState());
    }
  };

// This method is only a convenience to remove multiple nodes and edges at once
// it does NO additional processing and validations.
const removeNodeArray =
  (nodes: Array<RcaNode>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (nodes.length === 0) {
      return;
    }

    const nodeChangeEvents: Array<NodeRemoveChange> = nodes.map((node) => ({
      type: 'remove',
      id: node.id,
    }));

    // remove connecting edges
    const edgeChangeEvents: Array<EdgeRemoveChange> = nodes
      .map((node) => makeSelectParentConnectingEdge(node.id)(getState()))
      .filter((edge) => edge != null)
      .map((edge) => ({ type: 'remove', id: edge!.id }));

    dispatch(onNodesChange(nodeChangeEvents));
    dispatch(onEdgesChange(edgeChangeEvents));
  };

const sanitizeChildSortOrders =
  (parentId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const parent = makeSelectNode(parentId)(getState());
    if (parent == null) {
      return;
    }

    let fromNodeId = parentId;
    if (RcaUtil.isMetaNode(parent)) {
      fromNodeId = makeSelectParentNode(parentId)(getState())!.id;
    }

    const children = makeSelectChildNodesWithSubMetaChildNodes(fromNodeId)(
      getState()
    );
    dispatch(setSortedSiblings(RcaUtil.setSortOrders(children)));
  };

const updateNodeData =
  (nodeId: string, data: Partial<RcaNodeData>, followConnection = true) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    let isEqual = true;
    for (const dataKey in data) {
      const newVal = data[dataKey];
      const oldVal = node.data[dataKey];

      if (newVal !== oldVal) {
        isEqual = false;
        break;
      }
    }

    if (isEqual) {
      return;
    }

    dispatch(
      updateNode({
        ...node,
        data: {
          ...node.data,
          ...data,
        },
      })
    );

    // If there are connection nodes linking to this node, we need to ensure
    // it's data is updated accordingly.
    if (followConnection) {
      const connections = makeSelectConnectionNodesFor(nodeId)(getState());
      for (const connection of connections) {
        dispatch(
          updateNode({
            ...connection,
            data: {
              ...node.data,
              ...data,
              sortOrder: connection.data.sortOrder, // Sort order must be kept separate from real node
            },
          })
        );
      }
    }
  };

const createNodeAndLinkFrom =
  (
    linkFromId: string,
    sortOrder?: number,
    type?: RcaNodeType,
    initialData?: RcaNodeData
  ) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const fromNode = makeSelectNode(linkFromId)(getState());
    if (fromNode == null) {
      return;
    }

    if (sortOrder != null && sortOrder <= -1) {
      sortOrder = 0;
    }

    // We need to handle the case where the fromNode is a meta node
    // We need to get its parent so that we can reliably sort
    let sortParentId = linkFromId;
    if (RcaUtil.isMetaNode(fromNode)) {
      sortParentId = makeSelectParentNode(linkFromId)(getState())!.id;
    }

    const immediateChildren = makeSelectChildNodes(linkFromId)(getState());
    const preSortedNodes = RcaUtil.setSortOrders(
      makeSelectChildNodesWithSubMetaChildNodes(sortParentId)(getState())
    );

    sortOrder ??= RcaUtil.getBestSortOrder(
      preSortedNodes.filter((x) =>
        immediateChildren.some((y) => x.id === y.id)
      ),
      type ?? RcaNodeType.default
    );

    const nodeId = uuid();
    const newNode: RcaNode = {
      id: nodeId,
      // Position doesn't matter here, it's the sort order that determines when
      // the node is above or below its siblings.
      position: {
        x: 0,
        y: 0,
      },
      type: type ?? RcaNodeType.default,
      draggable: true,
      selected: false,
      data: {
        ...(initialData ?? {
          label: '',
        }),
        isRoot: false,
        disproved: fromNode.data.disproved,
        sortOrder,
      },
    };

    let edgeType: RcaEdgeType = RcaEdgeType.default;
    if (type === RcaNodeType.connection) {
      edgeType = RcaEdgeType.connection;
    }

    const edgeId = `${linkFromId}->${nodeId}`;
    const newEdge: RcaEdge = {
      id: edgeId,
      type: edgeType,
      source: linkFromId,
      target: newNode.id,
      targetHandle: null,
      sourceHandle: null,
    };

    const sortedSiblings = RcaUtil.incrementInsertedSortOrder(
      preSortedNodes,
      sortOrder
    );

    const nodeChanges: Array<NodeAddChange | NodeRemoveChange> = [
      {
        type: 'add',
        item: newNode,
      },
    ];

    const edgeChanges: Array<EdgeAddChange | EdgeRemoveChange> = [
      {
        type: 'add',
        item: newEdge,
      },
    ];

    dispatch(onNodesChange(nodeChanges));
    dispatch(onEdgesChange(edgeChanges));
    dispatch(setSortedSiblings(sortedSiblings));
    dispatch(layoutChart());

    dispatch(setEditNodeContentId(newNode.id));

    return makeSelectNode(nodeId)(getState())!;
  };

const setDescendantsDisprovedBasedOn =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node != null) {
      const { disproved } = node.data;

      // We don't want to effect connection nodes
      const descendants = makeSelectAlLDescendants(node.id)(getState()).filter(
        (x) => x.type !== RcaNodeType.connection
      );
      if (descendants.length > 0) {
        for (const child of descendants) {
          dispatch(updateNodeData(child.id, { ...child.data, disproved }));
        }
      }
    }
  };

export const insertSiblingAbove =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const parent = makeSelectParentNode(nodeId)(getState());
    const node = makeSelectNode(nodeId)(getState());
    if (parent == null || node == null) {
      return;
    }

    return dispatch(createNodeAndLinkFrom(parent.id, node.data.sortOrder));
  };

export const insertSiblingBelow =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const parent = makeSelectParentNode(nodeId)(getState());
    const node = makeSelectNode(nodeId)(getState());
    if (parent == null || node == null) {
      return;
    }

    return dispatch(createNodeAndLinkFrom(parent.id, node.data.sortOrder + 1));
  };

export const insertInPlaceAndReparent =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    const parent = makeSelectParentNode(nodeId)(getState());

    if (node == null || parent == null) {
      return;
    }

    const newNode = dispatch(
      createNodeAndLinkFrom(parent.id, node.data.sortOrder)
    );
    if (newNode == null) {
      return;
    }

    // make node a child of newNode
    const edge = makeSelectParentConnectingEdge(nodeId)(getState());
    if (edge == null) {
      return;
    }

    dispatch(
      onConnectNode({
        source: newNode.id,
        target: node.id,
        sourceHandle: null,
        targetHandle: null,
      })
    );

    dispatch(sanitizeChildSortOrders(parent.id));
    dispatch(sanitizeChildSortOrders(newNode.id));
    dispatch(layoutChart());
  };

export const addChildToNode = (nodeId: string) => (dispatch: AppDispatch) => {
  return dispatch(createNodeAndLinkFrom(nodeId, -999));
};

export const commitCreateNode =
  (nodeId: string, content: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }
    dispatch(
      updateNodeData(nodeId, {
        label: content,
      })
    );

    dispatch(resetFocus());

    try {
      const chainId = selectChainId(getState())!;
      const response = await dispatch(
        chainItemApi.endpoints.createChainItem.initiate({
          chainId,
          description: content,
          guid: nodeId,
        })
      ).unwrap();

      dispatch(
        updateNodeData(nodeId, {
          chainItemId: response.chainItemId,
        })
      );
      await dispatch(saveGraphState());
    } catch (e) {
      if (isApiError<CreateChainItemRequest>(e)) {
        dispatch(
          setAlert({
            message: e.message,
            type: 'error',
          })
        );
      }

      console.log('ERROR on create draft', e);

      dispatch(
        removeNode(nodeId, { moveToStorage: false, reparentChildren: true })
      );
    }
  };

export const updateNodeLabel =
  (nodeId: string, label: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    dispatch(updateNodeData(nodeId, { label }));
    dispatch(saveGraphState());
  };

export const toggleNodeCollapseState =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    const willCollapse = !node.data.collapse;
    const descendants = makeSelectAlLDescendants(nodeId)(getState());

    dispatch(updateNodeData(nodeId, { collapse: willCollapse }));
    dispatch(setHoverVisibilityNodeId(undefined));

    for (const descendant of descendants) {
      const descendantChildCount = makeSelectNonMetaChildNodes(descendant.id)(
        getState()
      ).length;

      dispatch(
        updateNodeData(descendant.id, {
          collapse: willCollapse ? false : descendantChildCount > 0,
        })
      );
    }

    return dispatch(saveGraphState());
  };

export const unCollapseNodeAndCollapseImmediateChildren =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const children = makeSelectNonMetaChildNodes(nodeId)(getState());

    dispatch(updateNodeData(nodeId, { collapse: false }));
    dispatch(setHoverVisibilityNodeId(undefined));

    for (const child of children) {
      const subChildCount = makeSelectNonMetaChildNodes(child.id)(
        getState()
      ).length;
      dispatch(updateNodeData(child.id, { collapse: subChildCount > 0 }));
    }

    return dispatch(saveGraphState());
  };

export const createStorageDragNode =
  (storageNode: StorageNode, x: number, y: number) =>
  (dispatch: AppDispatch) => {
    const nodeId = storageNode.clientUuid;
    const node: RcaNode = {
      id: nodeId,
      position: { x, y },
      type: RcaNodeType.default,
      dragging: true,
      draggable: true,
      style: {
        visibility: 'visible',
      },
      data: {
        label: storageNode.description ?? '',
        sortOrder: -1,
        chainItemId: storageNode.chainItemId,
        isRoot: false,
        caseId: storageNode.caseId,
        collapse: false,
        healthScore: undefined,
      },
    };

    dispatch(setStorageGraphNode(node));

    return node;
  };

export const updateStorageGraphNodePosition =
  (x: number, y: number) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const node = selectStorageGraphNode(getState());
    if (node && node.position.x !== x && node.position.y !== y) {
      dispatch(
        setStorageGraphNode({
          ...node,
          position: { x, y },
        })
      );

      dispatch(updateProximityEdge(node, { x, y }));
    }
  };

export const beginDraggingNode =
  (node: RcaNode) => (dispatch: AppDispatch, getState: () => RootState) => {
    const parent = makeSelectParentNode(node.id)(getState());
    const descendants = makeSelectAlLDescendants(node.id, true)(getState());

    dispatch(
      setOnDragIds({
        currentNodeDraggingId: node.id,
        onDragOriginalParentId: parent!.id,
        onDragDescendantIds: descendants.map((n) => n.id),
      })
    );

    const nodeChangeEvents: Array<NodeAddChange> = [];
    const edgeChangeEvents: Array<EdgeResetChange | EdgeRemoveChange> = [];

    // Insert temp holding node to connect the immediate descendants to this, so edges stay in place
    const dragHolderNodeId = uuid();
    const dragHolderNode: RcaNode = {
      id: dragHolderNodeId,
      type: RcaNodeType.dragHolder,
      position: node.position,
      data: node.data,
    };

    nodeChangeEvents.push({
      type: 'add',
      item: dragHolderNode,
    });

    // Break parent connection to node being dragged
    const parentEdge = makeSelectParentConnectingEdge(node.id)(getState());
    if (parentEdge != null) {
      edgeChangeEvents.push({
        type: 'remove',
        id: parentEdge.id,
      });
    }

    dispatch(onNodesChange(nodeChangeEvents));
    dispatch(onEdgesChange(edgeChangeEvents));

    // Add the immediate descendants to the holder node
    const edges = makeSelectOutgoingEdges(node.id)(getState());
    for (const edge of edges) {
      dispatch(
        updateEdge({
          ...edge,
          source: dragHolderNodeId,
        })
      );
    }
  };

export const stopDraggingNode =
  (node: RcaNode) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const isDraggingIntoStorage = selectIsDraggingIntoStorageContainer(
      getState()
    );
    const holderNode = selectDragHolderNode(getState());
    const proximityConnectSourceId = selectProximityEdge(getState())?.source;

    let didSwapParentNodes = false;
    let originalParentId: string | undefined;

    // User dragging a pre-existing node around in the chart
    if (holderNode != null) {
      const draggingNodeDescendants =
        selectDraggingNodeDescendants(getState()) ?? [];

      originalParentId = selectOnDragOriginalParentId(getState());
      const newParentId = proximityConnectSourceId ?? originalParentId!;

      const nodeChangeEvents: Array<NodeAddChange | NodeRemoveChange> = [];
      const edgeChangeEvents: Array<EdgeResetChange> = [];

      // Remove holder node
      nodeChangeEvents.push({
        type: 'remove',
        id: holderNode.id,
      });

      const edges = makeSelectOutgoingEdges(holderNode.id)(getState());

      // User has dragged the node to one of it's descendants
      if (
        draggingNodeDescendants.includes(newParentId) &&
        originalParentId != null
      ) {
        // Connect the descendants to the original parent
        for (const edge of edges) {
          dispatch(
            updateEdge({
              ...edge,
              source: originalParentId,
            })
          );
        }
      } else {
        // Reconnect edges from holder node to node that was originally dragged
        for (const edge of edges) {
          dispatch(
            updateEdge({
              ...edge,
              source: node.id,
            })
          );
        }
      }

      dispatch(onNodesChange(nodeChangeEvents));
      dispatch(onEdgesChange(edgeChangeEvents));

      // Connect the node to it's new parent
      dispatch(
        onConnectNode({
          source: newParentId,
          target: node.id,
          sourceHandle: null,
          targetHandle: null,
        })
      );

      if (originalParentId != null) {
        dispatch(sanitizeChildSortOrders(originalParentId));
      }

      if (isDraggingIntoStorage) {
        dispatch(removeNode(node.id, { moveToStorage: true }));

        if (originalParentId != null) {
          dispatch(sanitizeChildSortOrders(originalParentId));
        }
      } else if (
        proximityConnectSourceId != null &&
        proximityConnectSourceId !== originalParentId
      ) {
        didSwapParentNodes = true;
        dispatch(
          updateNode({
            ...node,
            draggable: true,
            data: { ...node.data, sortOrder: 999999 },
          })
        );
        dispatch(sanitizeChildSortOrders(proximityConnectSourceId));
      }
    } else {
      const storageNode = selectStorageGraphNode(getState());
      if (storageNode != null) {
        // User has dragged a node from storage onto the chart
        if (proximityConnectSourceId != null) {
          const nodeChangeEvents: Array<NodeAddChange> = [
            { type: 'add', item: storageNode },
          ];

          dispatch(onNodesChange(nodeChangeEvents));
          dispatch(
            onConnectNode({
              source: proximityConnectSourceId!,
              target: storageNode.id,
              sourceHandle: null,
              targetHandle: null,
            })
          );
          dispatch(
            updateNode({
              ...storageNode,
              draggable: true,
              data: { ...storageNode.data, sortOrder: -999 },
            })
          );
          const storageNodes = selectStorageNodes(getState());
          dispatch(
            setStorageNodes(
              storageNodes.filter((x) => x.clientUuid !== storageNode.id)
            )
          );
          didSwapParentNodes = true;

          dispatch(sanitizeChildSortOrders(proximityConnectSourceId!));
        }
        dispatch(setStorageGraphNode(undefined));
      }
    }

    dispatch(setIsDraggingIntoStorageContainer(false));
    dispatch(clearProximityEdge());
    dispatch(resetOnDragIds());
    dispatch(removeInvalidNodes());

    const parentId = makeSelectParentNode(node.id)(getState());
    if (parentId != null && originalParentId !== parentId.id) {
      dispatch(setDescendantsDisprovedBasedOn(parentId.id));
    }

    dispatch(layoutChart());
    if (didSwapParentNodes) {
      return dispatch(saveGraphState());
    }
  };

export const updateProximityEdge =
  (movingNode: RcaNode, mousePosition: XYPosition) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    let nodes = selectNodes(getState());

    const descendantIds = selectDraggingNodeDescendants(getState()) ?? [];
    const storageNode = selectStorageGraphNode(getState());
    nodes = storageNode ? [...nodes, storageNode] : nodes;

    const proximityEdge = selectProximityEdge(getState());
    const isHoveringOverStorageContainer = RcaUtil.isPointInStorageContainer(
      mousePosition.x,
      mousePosition.y
    );

    if (storageNode == null) {
      dispatch(
        setIsDraggingIntoStorageContainer(isHoveringOverStorageContainer)
      );
    }

    const NODE_AREA_WIDTH = 250;
    let dx: number, dy: number, d: number;
    const closestNode = nodes.reduce(
      (res, n) => {
        if (descendantIds.includes(n.id)) {
          return res;
        }

        if (n.id !== movingNode.id && RcaUtil.isAttachable(n)) {
          dx = n.position.x - movingNode.position.x + NODE_AREA_WIDTH;
          dy = n.position.y - movingNode.position.y;
          d = dx * dx + dy * dy;

          if (d < res.distance) {
            res.distance = d;
            res.node = n;
          }
        }

        return res;
      },
      {
        distance: Number.MAX_VALUE,
        node: null as RcaNode | null,
      }
    );

    const { node } = closestNode;
    if (node === null) {
      dispatch(clearProximityEdge());
    } else if (proximityEdge?.source !== node.id) {
      dispatch(
        setProximityEdge({
          id: 'proximity',
          className: 'proximity',
          source: node.id,
          target: movingNode.id,
          type: 'simplebezier',
        })
      );
    }
  };

export const setNodeConnections =
  (
    nodeId: string,
    chainItemIds: Array<number>,
    skipLayoutAndChartSave = false
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    // If a node is an end state, we need to make sure this is removed
    const isEndState = node.type === RcaNodeType.endState;
    if (isEndState) {
      await dispatch(makeNodeEndState(nodeId, NodeEndStateType.none, true));
    }

    const originalLinkedNodes = makeSelectConnectionChildNodes(nodeId)(
      getState()
    );

    // Connection nodes that have been disconnected
    const removedConnectionNodes = originalLinkedNodes.filter(
      (x) => !chainItemIds.includes(x.data.chainItemId ?? -999)
    );

    // New nodes that have been connected
    const addedNodes = chainItemIds
      .filter((x) => !originalLinkedNodes.some((y) => y.data.chainItemId === x))
      .map((id) => makeSelectNodeFromChainItemId(id)(getState()))
      .filter((x) => x != null) as Array<RcaNode>;

    // Generate the new linkTo array ensuring we filter out removed nodes
    const linkedTo: Array<ItemLink> = [...originalLinkedNodes, ...addedNodes]
      .filter((x) => !removedConnectionNodes.some((y) => x.id === y.id))
      .map((x) => ({
        id: x.data.chainItemId!,
        label: x.data.label,
      }));

    dispatch(
      updateNode({
        ...node,
        type: RcaNodeType.default,
        data: {
          ...node.data,
          linkedToChainItems: linkedTo,
          endState: NodeEndStateType.none,
        },
      })
    );

    const chainItemId = node.data.chainItemId!;

    // Updates the linkFROM value of nodes we just connected to
    for (const connectionNode of addedNodes) {
      const realNode = makeSelectNodeFromChainItemId(
        connectionNode.data.chainItemId!
      )(getState())!;

      const linkedItems = [...(realNode.data.linkedFromChainItems ?? [])];
      if (linkedItems.findIndex((x) => x.id === chainItemId) === -1) {
        linkedItems.push({
          id: chainItemId,
          label: node.data.label,
        });
      }

      // Update the linkedFROM value of the real node
      dispatch(
        updateNodeData(realNode.id, {
          linkedFromChainItems: linkedItems,
        })
      );

      // Create connection node
      dispatch(
        createNodeAndLinkFrom(nodeId, undefined, RcaNodeType.connection, {
          ...realNode.data,
          linkedFromChainItems: linkedItems,
        })
      );
    }

    // remove references to this node, FROM the nodes that have been disconnected
    for (const connectionNode of removedConnectionNodes) {
      const realNode = makeSelectNodeFromChainItemId(
        connectionNode.data.chainItemId!
      )(getState())!;

      const linkedFromChainItems = (
        realNode.data.linkedFromChainItems ?? []
      ).filter((x) => x.id !== chainItemId);

      dispatch(
        updateNodeData(realNode.id, {
          linkedFromChainItems,
        })
      );
    }

    dispatch(removeNodeArray(removedConnectionNodes));

    const chainId = selectChainId(getState())!;
    if (skipLayoutAndChartSave) {
      return dispatch(
        chainItemApi.endpoints.linkChainItems.initiate({
          chainId,
          chainItemId,
          chainItemIds,
        })
      ).unwrap();
    } else {
      dispatch(layoutChart());
      return dispatch(
        saveGraphState(async () => {
          await dispatch(
            chainItemApi.endpoints.linkChainItems.initiate({
              chainId,
              chainItemId,
              chainItemIds,
            })
          ).unwrap();
        })
      );
    }
  };

export const makeNodeEndState =
  (
    nodeId: string,
    endState: NodeEndStateType,
    skipLayoutAndChartSave = false
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    const isRemoving = endState === NodeEndStateType.none;
    const hasLinkedNodes = !!node.data.linkedToChainItems?.length;
    if (!isRemoving && hasLinkedNodes) {
      await dispatch(setNodeConnections(nodeId, [], true));
    }

    dispatch(
      updateNode({
        ...node,
        type: !isRemoving ? RcaNodeType.endState : RcaNodeType.default,
        data: {
          ...node.data,
          endState,
          linkedToChainItems: [],
        },
      })
    );

    const chainItemId = node.data.chainItemId!;
    const chainId = selectChainId(getState())!;

    if (skipLayoutAndChartSave) {
      return dispatch(
        chainItemApi.endpoints.setChainItemEndState.initiate({
          chainId,
          endState,
          chainItemId,
        })
      ).unwrap();
    } else {
      dispatch(layoutChart());
      return dispatch(
        saveGraphState(async () => {
          await dispatch(
            chainItemApi.endpoints.setChainItemEndState.initiate({
              chainId,
              endState,
              chainItemId,
            })
          ).unwrap();
        })
      );
    }
  };

export const insertMetaNode =
  (type: RcaNodeType, children: Array<RcaNode>) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (children.length === 0) {
      return;
    }

    const node1 = children[0];

    const parentNode = makeSelectParentNode(node1.id)(getState());
    if (parentNode == null) {
      return;
    }

    let saveChart = true;
    let node2 = children.length > 1 ? children[1] : undefined;
    if (node2 == null) {
      // We don't want to save straight away because the user will have to enter the cause box label
      // for the automatically created second node
      saveChart = false;
      node2 = dispatch(
        createNodeAndLinkFrom(parentNode.id, node1.data.sortOrder + 1)
      )!;

      if (node2 == null) {
        return;
      }

      children.push(node2);
    }

    const metaNodeId = `meta-node-${type}-${uuid()}`;
    const metaNode: RcaNode = {
      id: metaNodeId,
      type: type,
      position: { x: 0, y: 0 },
      data: { isRoot: false, label: type, sortOrder: node1.data.sortOrder },
    };

    const nodeChangeEveents: Array<NodeAddChange> = [
      {
        type: 'add',
        item: metaNode,
      },
    ];

    const metaParentEdgeId = `meta-edge-${metaNodeId}-${uuid()}`;
    const metaParentEdge: RcaEdge = {
      id: metaParentEdgeId,
      source: parentNode.id,
      target: metaNodeId,
      targetHandle: null,
      sourceHandle: null,
    };

    const edgeChangeEvents: Array<EdgeRemoveChange | EdgeAddChange> = [
      {
        type: 'add',
        item: metaParentEdge,
      },
    ];

    for (const child of children) {
      let nodeParentEdge = makeSelectParentConnectingEdge(child.id)(getState());

      // Re-parent child to the new meta node
      nodeParentEdge = {
        ...nodeParentEdge,
        source: metaNodeId,
      };

      edgeChangeEvents.push({
        type: 'remove',
        id: nodeParentEdge.id,
      });

      edgeChangeEvents.push({
        type: 'add',
        item: nodeParentEdge,
      });
    }

    dispatch(onNodesChange(nodeChangeEveents));
    dispatch(onEdgesChange(edgeChangeEvents));
    dispatch(layoutChart());

    const response = await dispatch(
      chainItemApi.endpoints.createChainItem.initiate({
        chainId: selectChainId(getState())!,
        description: 'meta',
        guid: metaNodeId,
      })
    ).unwrap();

    dispatch(updateNodeData(metaNodeId, { chainItemId: response.chainItemId }));

    if (saveChart) {
      try {
        dispatch(incrementNodeBusyTracker(metaNodeId));
        await dispatch(saveGraphState());
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        dispatch(decrementNodeBusyTracker(metaNodeId));
      }
    }
  };

export const devOnlyToggleDevMode = () => (dispatch: AppDispatch) => {
  if (!isProd) {
    dispatch(toggleDevMode());
  }
};

export const disproveNode =
  (nodeId: string, moveChildrenToStorage: boolean) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    dispatch(updateNodeData(nodeId, { disproved: true }));

    const children = makeSelectAlLDescendants(nodeId)(getState());
    if (children.length > 0) {
      if (moveChildrenToStorage) {
        const nodeChangeEvt: Array<NodeRemoveChange> = [];
        const edgeChangeEvt: Array<EdgeRemoveChange> = [];
        for (const child of children) {
          nodeChangeEvt.push({
            type: 'remove',
            id: child.id,
          });

          const parentEdge = makeSelectParentConnectingEdge(child.id)(
            getState()
          );
          if (parentEdge != null) {
            edgeChangeEvt.push({
              type: 'remove',
              id: parentEdge.id,
            });
          }
        }

        dispatch(addNodesToStorage(children));
        dispatch(onNodesChange(nodeChangeEvt));
        dispatch(onEdgesChange(edgeChangeEvt));
        dispatch(layoutChart());
      } else {
        dispatch(setDescendantsDisprovedBasedOn(nodeId));
      }
    }

    return dispatch(saveGraphState());
  };

export const removeDisproval =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    dispatch(updateNodeData(nodeId, { disproved: false }));

    const children = makeSelectAlLDescendants(nodeId)(getState());
    if (children.length > 0) {
      dispatch(setDescendantsDisprovedBasedOn(nodeId));
    }

    dispatch(
      setAlert({
        type: 'success',
        message: `You have successfully removed the disproved status of the cause box '${node.data.label}'`,
      })
    );

    return dispatch(saveGraphState());
  };

export const highlightNode =
  (nodeId: string, highlightDescendants: boolean) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(unHighlightAllNodes());

    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    dispatch(updateNodeData(nodeId, { highlight: true }, false));

    if (highlightDescendants) {
      const descendants = makeSelectAlLDescendants(nodeId)(getState());
      for (const descendant of descendants) {
        dispatch(updateNodeData(descendant.id, { highlight: true }, false));
      }
    }
  };

export const addNodeToStorage =
  (chainItem: ChainItemResource) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const storageNodes = selectStorageNodes(getState());

    const newNodes: Array<StorageNode> = [
      ...storageNodes,
      {
        clientUuid: uuid(),
        ...chainItem,
      },
    ];

    dispatch(setStorageNodes(newNodes));
  };

export const removeNodeFromStorage =
  (node: StorageNode) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const chainId = selectChainId(getState())!;
    await dispatch(
      chainItemStorageApi.endpoints.deleteStorageItem.initiate({
        chainId,
        chainItemId: node.chainItemId,
      })
    ).unwrap();

    const storageNodes = selectStorageNodes(getState());
    dispatch(
      setStorageNodes(
        storageNodes.filter((x) => x.clientUuid !== node.clientUuid)
      )
    );
  };

export const focusNodeAndBringIntoView =
  (
    node: RcaNode,
    activePanel: NodePanelEditorTab = NodePanelEditorTab.overview,
    isInitial = false
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setSelectedNode(node));
    dispatch(selectNodePanelEditorTab(activePanel));
    RcaUtil.snapFocusToNode(node, isInitial);
  };
