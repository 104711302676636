import { PropsWithChildren, Suspense } from 'react';
import { SnackbarProvider } from 'notistack';

import './i18n';
import './app.css';
import { PageAlertProvider } from '@components/alerts/hooks/page-alert-hook';
import UiPopupProvider from '@components/ui-popup/ui-popup-provider';
import { FullScreenLoadingIndicator } from '@components/loading-indicator';
import { Provider } from 'react-redux';
import { store } from '@store/store';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Outlet } from 'react-router-dom';
import { i18nDayJs } from '@i18n/index';
import AppThemeModeProvider from '@styles/app-theme-mode-provider';
import CommonApiCacheProvider from '@components/hoc/common-api-cache';

function App({ children }: PropsWithChildren<{}>) {
  return (
    <Provider store={store}>
      <AppThemeModeProvider>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          dateLibInstance={i18nDayJs.utc}
        >
          <CssBaseline />
          <CommonApiCacheProvider>
            <SnackbarProvider maxSnack={3}>
              <PageAlertProvider>
                <UiPopupProvider>{children || <Outlet />}</UiPopupProvider>
              </PageAlertProvider>
            </SnackbarProvider>
          </CommonApiCacheProvider>
        </LocalizationProvider>
      </AppThemeModeProvider>
    </Provider>
  );
}

export default function WrappedApp(props: any) {
  return (
    <Suspense fallback={<FullScreenLoadingIndicator />}>
      <App {...props} />
    </Suspense>
  );
}
