import { Button, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';

import { ButtonProps } from './button-props';
import { LoadingIndicator } from '@components/loading-indicator';

const StyledButton = styled(Button)<MuiButtonProps & AnchorHTMLAttributes<any>>(
  ({ theme }) => ({
    display: 'flex',
    height: 56,
    justifyContent: 'center',
    textTransform: 'none',
    alignItems: 'center',
    borderRadius: 3,
    border: `1px solid ${theme.palette.primary.main}`,
    p: {
      color: theme.palette.primary.main,
      fontFamily: 'Usual',
      lineHeight: '26px' /* 173.333% */,
      letterSpacing: '0.46px',
    },
    '&.MuiButton-sizeLarge': {
      height: 56,
      fontSize: '15px',
      fontWeight: 500,
      borderRadius: 4,
    },
    '&.MuiButton-sizeMedium': {
      height: 40,
      fontSize: '14px',
      fontWeight: 400,
      borderRadius: 4,
    },
  })
);

type Props = ButtonProps & {
  isBusy?: boolean;
  size?: 'large' | 'medium' | 'small';
  form?: string;
};

const OutlinedButton = ({
  id,
  children,
  isBusy,
  onClick,
  disabled,
  type,
  href,
  size,
  form,
}: PropsWithChildren<Props>) => {
  return (
    <StyledButton
      id={id}
      type={type ?? 'button'}
      size={size}
      variant="outlined"
      onClick={onClick}
      disabled={disabled || isBusy}
      href={href}
      target={!!href ? '_blank' : undefined}
      rel={!!href ? 'noreferrer' : undefined}
      form={form}
    >
      <span
        className="button-content"
        style={{ visibility: isBusy ? 'hidden' : 'visible' }}
      >
        {children}
      </span>
      <LoadingIndicator show={isBusy ?? false} />
    </StyledButton>
  );
};

export default OutlinedButton;
