import { Box, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  iconUrl: string;
  title: string;
  message: string;
  onClick: () => void;
  isSelected: boolean;
}

const Container = styled('div')<Props>(
  ({ isSelected, theme: { palette } }) => ({
    display: 'flex',
    background: palette.common.white,
    border: '1px solid #00000040',
    borderRadius: 8,
    padding: '15px 12px',
    cursor: 'pointer',

    '.icon': {
      width: 45,
      height: 'auto',
    },

    ...(isSelected && {
      padding: '14px 11px',
      border: '2px solid rgba(255, 108, 0, 1)',
    }),
  })
);

export default function ReportOption(props: Props) {
  const { iconUrl, message, title } = props;
  return (
    <Container {...props}>
      <img className="icon" src={iconUrl} alt="icon" />
      <Gap size={15} />
      <Column>
        <Typography>{title}</Typography>
        <Gap size={4} />
        <Typography variant="body2">{message}</Typography>
      </Column>
      <Spacer />
      <Gap size={15} />
      <Box alignSelf="center">
        <FontAwesomeIcon icon={faChevronRight} />
      </Box>
    </Container>
  );
}
