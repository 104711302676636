import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import { ColumnForm } from '@components/layout-util-components/column';
import { Grid, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import { useSeedWAInstallMutation } from '@api/endpoints/wct-admin/wct-admin-install.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { required, validEmail } from '@util/validators';
import useField from '@hooks/use-field-hook';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { invalidation } from '@api/cache-util';
import { isApiError } from '@api/types/api-error';
import { WAInstallSeed } from '@api/types/wct-admin/wct-admin-install-types';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import WCTTextField from '@components/input/text-field';

export interface WASeedInstallFormProps extends ModalProps<never> {
  installId: number;
}

const WASeedInstallForm = ({ onClose, installId }: WASeedInstallFormProps) => {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const [seedInstallMutation] = useSeedWAInstallMutation();

  const email = useField<string>([required(), validEmail()]);
  const firstName = useField<string>([required()]);
  const lastName = useField<string>([required()]);

  const { isValid, validateAll } = useFieldsWatcher([
    email,
    firstName,
    lastName,
  ]);

  const [seedInstall, isBusy] = useBusyAction(async () => {
    if (!validateAll()) {
      return false;
    }

    try {
      await seedInstallMutation({
        id: installId,
        emailAddress: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
      }).unwrap();
      await invalidation('WAInstall');
      showSuccessMessage('Install seeded successfully');
      onClose();
      return true;
    } catch (error) {
      if (isApiError<WAInstallSeed>(error)) {
        const { message, errors } = error;
        showErrorMessage(errors?.id ?? message);

        email.setError(errors?.emailAddress);
        firstName.setError(errors?.firstName);
        lastName.setError(errors?.lastName);
      }
      return false;
    }
  });

  const canSubmit = isValid && !isBusy;

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        seedInstall();
      }}
    >
      <Typography variant="h2">Seed Install</Typography>
      <Gap size={24} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WCTTextField
            name="email"
            label="Email Address"
            onChange={email.set}
            value={email.value}
            error={email.error}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <WCTTextField
            name="firstName"
            label="First Name"
            onChange={firstName.set}
            value={firstName.value}
            error={firstName.error}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <WCTTextField
            name="surname"
            label="Last Name"
            onChange={lastName.set}
            value={lastName.value}
            error={lastName.error}
            required
          />
        </Grid>
      </Grid>
      <Gap size={24} />
      <ActionsRow>
        <OutlinedButton onClick={() => onClose()}>Cancel</OutlinedButton>
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Seed Install
        </PrimaryButton>
      </ActionsRow>
    </ColumnForm>
  );
};

export default withModal(WASeedInstallForm);
