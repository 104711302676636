import requireAuth from '@components/hoc/with-required-auth-hoc';
import ContentContainer from '@components/containers/content-container';
import Row from '@components/layout-util-components/row';
import { LoadingIndicator } from '@components/loading-indicator';
import StatsContainer from '@components/containers/stats-container';
import {
  DropDownFilter,
  SortByFilter,
  WCTTable,
  WCTTableFilters,
} from '@components/table';
import { Gap } from '@components/layout-util-components/gap';
import useWACompaniesIndexPage from '@pages/wct-admin/companies/wa-companies-index-hook';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuildings,
  faCloudXmark,
  faHourglassEnd,
  faTimer,
  faUser as faLiteUser,
  faWavePulse,
} from '@fortawesome/pro-light-svg-icons';
import { faUser as faProUser } from '@fortawesome/pro-solid-svg-icons';
import WACompanyTableRow from '@pages/wct-admin/companies/components/wa-company-row';
import { WACompanyRow } from '@api/types/wct-admin/wct-admin-company-types';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import WaViewCompany from '@pages/wct-admin/companies/components/wa-view-company';
import PageCount from '@components/typography/page-count';

function WACompaniesIndexPage() {
  const { showPopup } = useUiPopup();
  const {
    isLoading,
    isFetching,
    companyStats,
    companies,
    search,
    installOptions,
    sort,
    packageId,
    packageOptions,
    pagination,
    statusId,
    subscriptionOptions,
    tagIds,
    managerUserId,
    sortByOptions,
    installId,
  } = useWACompaniesIndexPage();

  const onViewCompany = (company: WACompanyRow) => {
    showPopup(WaViewCompany, {
      companyId: company.companyId,
      companyName: company.companyName,
    });
  };

  return (
    <ContentContainer maxWidth={1500}>
      <Row>
        <PageCount
          title="Companies"
          isLoading={isLoading}
          count={companies?.totalCount}
        />
      </Row>
      <Gap size={32} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : companies!.totalCount === 0 ? (
        <OnboardingEntryContainer
          icon={<FontAwesomeIcon icon={faBuildings} size="3x" />}
          title="No Companies Found"
          message="There are no companies in the system"
        />
      ) : (
        <>
          <StatsContainer
            show
            stats={[
              {
                title: 'ACTIVE',
                value: companyStats!.activeCount,
                icon: faWavePulse,
                color: '#4f9a43',
              },
              {
                title: 'EXPIRING',
                value: companyStats!.expiringCount,
                icon: faHourglassEnd,
                color: '#f4862c',
              },
              {
                title: 'CANCELLED',
                value: companyStats!.cancelledCount,
                icon: faCloudXmark,
                color: '#FF3D3D',
              },
              {
                title: 'PRO',
                value: companyStats!.proCount,
                icon: faProUser,
                color: '#43ff3d',
              },
              {
                title: 'LITE',
                value: companyStats!.liteCount,
                icon: faLiteUser,
                color: '#3dffef',
              },
              {
                title: 'TRIAL',
                value: companyStats!.freeTrialCount,
                icon: faTimer,
                color: '#db3dff',
              },
            ]}
          />
          <Gap size={32} />
          <WCTTable
            count={companyStats!.totalCount}
            pagination={pagination}
            isLoading={isLoading}
            headers={[
              { title: 'Name', sortProperty: 'companyName', ...sort },
              {
                title: 'Subscription',
                sortProperty: 'subscriptionName',
                ...sort,
              },
              { title: 'RCA Count', sortProperty: 'rcaCount', ...sort },
              { title: 'Admin Name' },
              { title: 'Tags' },
              { title: 'Health Score' },
              { title: 'Status', sortProperty: 'companyStatusName', ...sort },
              { title: 'Created', sortProperty: 'created', ...sort },
              { title: <></> },
            ]}
            filters={
              <WCTTableFilters
                isLoading={isLoading}
                isFetching={isFetching}
                search={search.value}
                onSearch={search.set}
                rightOfSearchContent={
                  <SortByFilter
                    value={sort.value}
                    onChange={sort.set}
                    options={sortByOptions}
                  />
                }
              >
                <DropDownFilter
                  options={packageOptions ?? []}
                  value={packageId.value}
                  onChange={packageId.set}
                >
                  Package
                </DropDownFilter>
                <DropDownFilter
                  options={subscriptionOptions ?? []}
                  value={statusId.value}
                  onChange={statusId.set}
                >
                  Status
                </DropDownFilter>
                <DropDownFilter
                  options={installOptions ?? []}
                  value={installId.value}
                  onChange={installId.set}
                >
                  Install
                </DropDownFilter>
              </WCTTableFilters>
            }
          >
            {companies!.model.map((company) => (
              <WACompanyTableRow
                key={company.companyId}
                company={company}
                onView={() => onViewCompany(company)}
              />
            ))}
          </WCTTable>
        </>
      )}
    </ContentContainer>
  );
}

export default requireAuth(WACompaniesIndexPage);
