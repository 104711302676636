import { Grid, Typography } from '@mui/material';
import { ImpactInfoState } from '@pages/app/rca/create/forms/impact-info-hook';
import WCTSelectField from '@components/input/select-field';
import BinarySelectField from '@components/input/binary-select-field';
import WCTTextField, { TextFieldMaxSize } from '@components/input/text-field';
import { FormEvent } from 'react';
import { CaseImpactTypeMetric } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import RadioSelectGroup from '@components/input/radio-select-group';
import { numberFromString } from '@util/string-util';
import WCTCurrencyField from '@components/input/currency-field';
import { Gap } from '@components/layout-util-components/gap';
import { ColumnForm } from '@components/layout-util-components/column';
import GridSkeleton from '@components/skeletons/grid-skeleton';
import ListContainerSkeleton from '@components/skeletons/list-container-skeleton';
import InlineRadioSelectGroup from '@components/input/inline-radio-select-group';
import SwitchFieldSkeleton from '@components/skeletons/switch-field-skeleton';
import WCTSwitchField from '@components/input/switch-field';
import { InputSkeleton } from '@components/skeletons';

interface ImpactInfoFormInputsProps extends ImpactInfoState {
  onSuccess: () => void;
}

function ImpactInfoFormInputs(props: ImpactInfoFormInputsProps) {
  const {
    isLoading,
    track,
    typeOfImpactOptions,
    typeOfImpact,
    metric,
    metricType,
    name,
    description,
    impactTypeOptions,
    loadingMetricOptions,
    metricValueOptions,
    metricValue,
    submit,
    severityOptions,
    severity,
    systemText,
    currency,
    onSuccess,
  } = props;

  return (
    <>
      <ColumnForm
        id="impactInfo"
        noValidate
        onSubmit={async (e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();

          if ('submitter' in e.nativeEvent) {
            const submitter = (e.nativeEvent as SubmitEvent).submitter;
            const addAnother =
              submitter != null && submitter.id === 'createAndAdd';
            const didSucceed = await submit(addAnother);
            if (didSucceed && !addAnother) {
              onSuccess();
            }
          }
        }}
      >
        <Typography variant="body1">Impact Name</Typography>
        <Gap size={15} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            name="name"
            placeholder="Impact Name"
            value={name.value}
            error={name.error}
            onChange={name.set}
            required
            maxLength={100}
          />
        )}
        <Gap size={24} />
        <Typography variant="body1">Description</Typography>
        <Typography variant="body2">
          Additional information to describe this impact clearly
        </Typography>
        <Gap size={15} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            name="description"
            placeholder="Description"
            value={description.value}
            error={description.error}
            onChange={description.set}
            required
            multiline
            maxLength={100}
          />
        )}
        <Gap size={24} />
        <Typography variant="body1">
          {systemText['RCA.Details.ImpactCategory']}
        </Typography>
        <Typography variant="body2">
          Which category is your impact associated with
        </Typography>
        <Gap size={15} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTSelectField
            name="category"
            id="category"
            options={impactTypeOptions}
            placeholder={`${systemText['RCA.Details.ImpactCategory']}`}
            value={metric.value}
            error={metric.error}
            onChange={metric.set}
            required
          />
        )}
        <Gap size={24} />
        <Typography variant="body1">
          {systemText['RCA.Details.TypeOfImpact']}
        </Typography>
        <Gap size={15} />
        {isLoading ? (
          <GridSkeleton count={2} itemHeight={200} />
        ) : (
          <BinarySelectField
            name="typeOfImpact"
            options={typeOfImpactOptions}
            value={typeOfImpact.value}
            error={typeOfImpact.error}
            onChange={typeOfImpact.set}
          />
        )}
        <Gap size={24} />

        {metricType === CaseImpactTypeMetric.scale ? (
          <>
            <Typography variant="body1">
              {typeOfImpact.value == null || typeOfImpact.value
                ? `What was the actual ${impactTypeOptions
                    .find((x) => x.id === metric.value)
                    ?.label?.toLowerCase()} impact?`
                : `What is the forecasted potential ${impactTypeOptions
                    .find((x) => x.id === metric.value)
                    ?.label?.toLowerCase()} impact?`}
            </Typography>
            <Gap size={15} />
            {isLoading || loadingMetricOptions ? (
              <InputSkeleton maxWidth={TextFieldMaxSize.small} />
            ) : (
              <RadioSelectGroup
                value={numberFromString(metricValue.value)}
                error={metricValue.error}
                onChange={metricValue.set}
                options={metricValueOptions!}
              />
            )}
            <Gap size={24} />
          </>
        ) : metricType === CaseImpactTypeMetric.currency ? (
          <>
            <Typography variant="body1">
              {typeOfImpact.value == null || typeOfImpact.value
                ? 'What was the actual financial impact?'
                : 'What is the forecasted potential financial impact?'}
            </Typography>
            <Gap size={15} />
            {loadingMetricOptions ? (
              <InputSkeleton maxWidth={TextFieldMaxSize.small} />
            ) : (
              <WCTCurrencyField
                value={metricValue.value}
                error={metricValue.error}
                onChange={metricValue.set}
                placeholder="0.00"
                name="currencyValue"
                required
                currency={currency}
              />
            )}
            <Gap size={24} />
          </>
        ) : metricType === CaseImpactTypeMetric.numeric ? (
          <>
            <Typography variant="body1">
              {typeOfImpact.value == null || typeOfImpact.value
                ? 'What was the actual value of the impact?'
                : 'What is the forecasted potential value of the impact?'}
            </Typography>
            <Gap size={15} />
            {loadingMetricOptions ? (
              <SwitchFieldSkeleton />
            ) : (
              <InlineRadioSelectGroup
                value={
                  metricValue.value ? Number(metricValue.value) : undefined
                }
                error={metricValue.error}
                onChange={metricValue.set}
                name="metricValue"
                options={[
                  { id: 1, label: '1' },
                  { id: 2, label: '2' },
                  { id: 3, label: '3' },
                  { id: 4, label: '4' },
                  { id: 5, label: '5' },
                ]}
                helperIndicators={{
                  left: 'Safe',
                  right: 'Unsafe',
                }}
              />
            )}
            <Gap size={24} />
          </>
        ) : null}

        <Typography variant="body1">Severity of Impact</Typography>
        <Gap size={15} />
        {isLoading ? (
          <ListContainerSkeleton count={4} />
        ) : (
          <RadioSelectGroup
            options={severityOptions ?? []}
            value={severity.value}
            error={severity.error}
            onChange={severity.set}
          />
        )}
        <Gap size={24} />
        <Grid container>
          <Grid container item xs={9}>
            <Typography variant="body1">Track this impact?</Typography>
            <Typography variant="body2">
              Track impacts to improve analysis and support solution quality
            </Typography>
          </Grid>
          <Grid container item xs={3} justifyContent="flex-end">
            {isLoading ? (
              <SwitchFieldSkeleton noText />
            ) : (
              <WCTSwitchField
                name="track"
                value={track.value}
                error={track.error}
                onChange={track.set}
              />
            )}
          </Grid>
        </Grid>
        <Gap size={24} />
      </ColumnForm>
    </>
  );
}

export default ImpactInfoFormInputs;
