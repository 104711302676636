import { Typography } from '@mui/material';
import { LoadingIndicator } from '@components/loading-indicator';
import { ImpactStepState } from '@pages/app/rca/create/steps/impact-step-hook';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { Gap } from '@components/layout-util-components/gap';
import ImpactInfoRow from '@pages/app/rca/create/components/impact-info-row';
import Column from '@components/layout-util-components/column';
import { faBullseyePointer } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WCTTable } from '@components/table';

interface IImpactsPageProps {
  state: ImpactStepState;
}

export default function ImpactsPage({ state }: IImpactsPageProps) {
  const {
    remove,
    isLoading,
    actualImpacts,
    potentialImpacts,
    systemText,
    isEmpty,
    beginCreate,
    beginEdit,
    caseId,
    canContribute,
  } = state;

  return (
    <Column>
      <Gap size={10} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : isEmpty ? (
        <OnboardingEntryContainer
          icon={<FontAwesomeIcon icon={faBullseyePointer} size="2x" />}
          title="No impacts have been added to this RCA"
          message={
            canContribute
              ? 'Please click below to add impacts to this RCA'
              : undefined
          }
          createMessage="Add Impact"
          onCreateClicked={canContribute ? beginCreate : undefined}
        />
      ) : (
        <>
          {actualImpacts.length > 0 && (
            <>
              <Gap size={32} />
              <Typography variant="h3">
                Actual Impacts ({actualImpacts.length})
              </Typography>
              <Gap size={15} />
              <WCTTable
                count={actualImpacts.length}
                headers={[
                  { title: 'Tracked' },
                  { title: systemText['RCA.Details.ImpactCategory'] },
                  { title: 'Impact Name' },
                  { title: 'Description' },
                  { title: 'Severity' },
                  { title: 'Actual Impact' },
                ]}
              >
                {actualImpacts.map((impact) => (
                  <ImpactInfoRow
                    key={impact.caseImpactId}
                    info={impact}
                    caseId={caseId!}
                    onEditClick={() => beginEdit(impact)}
                    onDeleteClick={remove}
                    canContribute={canContribute}
                  />
                ))}
              </WCTTable>
            </>
          )}
          {potentialImpacts.length > 0 && (
            <>
              <Gap size={32} />
              <Typography variant="h3">
                Potential Impacts ({potentialImpacts.length})
              </Typography>
              <Gap size={15} />
              <WCTTable
                count={actualImpacts.length}
                headers={[
                  { title: 'Tracked' },
                  { title: systemText['RCA.Details.ImpactCategory'] },
                  { title: 'Impact Name' },
                  { title: 'Description' },
                  { title: 'Severity' },
                  { title: 'Potential Impact' },
                ]}
              >
                {potentialImpacts.map((impact) => (
                  <ImpactInfoRow
                    key={impact.caseImpactId}
                    info={impact}
                    caseId={caseId!}
                    onEditClick={() => beginEdit(impact)}
                    onDeleteClick={remove}
                    canContribute={canContribute}
                  />
                ))}
              </WCTTable>
            </>
          )}
        </>
      )}
    </Column>
  );
}
