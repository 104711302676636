import { Chip, styled, Typography } from '@mui/material';
import { ColorId } from '@util/colour-identifiers';
import IdColorIndicator from '@pages/app/rca/tabs/reports/components/id-color-indicator';
import Row from '@components/layout-util-components/row';
import { Gap } from '@components/layout-util-components/gap';

export type OutcomeColoredBadgeProps = {
  bgColor: string;
  label: string;
  textColor?: string;
  borderColor?: string;
  showIndicator?: boolean;
} & (
  | {
      indicatorColorId?: ColorId;
    }
  | {
      indicatorColor?: string;
    }
);

const StyledChip = styled(Chip)<{
  bg?: string;
  textCol?: string;
  borderColor?: string;
}>(({ bg, textCol, borderColor, theme: { palette } }) => ({
  borderColor: borderColor,
  borderWidth: '1px',
  borderRadius: '4px',
  cursor: 'pointer',
  background: bg || '#F8F8FA',
  display: 'flex',
  height: '30px',
  padding: '3px 4px 3px 1px',
  maxWidth: '115px',
  justifyContent: 'center',
  alignItems: 'center',
  borderStyle: 'solid',
  '> span > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    p: {
      fontSize: 12,
      color: textCol || '#525B5E',
    },
  },
}));

export default function OutcomeColoredBadge(props: OutcomeColoredBadgeProps) {
  const {
    bgColor,
    textColor,
    label,
    borderColor,
    showIndicator = true,
  } = props;

  let indicatorColorId: ColorId | undefined;
  if ('indicatorColorId' in props) {
    indicatorColorId = props.indicatorColorId;
  }

  let indicatorColor: string | undefined;
  if ('indicatorColor' in props) {
    indicatorColor = props.indicatorColor;
  }

  const displayIndicator =
    showIndicator && (indicatorColorId != null || indicatorColor != null);

  return (
    <StyledChip
      bg={bgColor}
      textCol={textColor}
      borderColor={borderColor}
      label={
        <Row>
          {displayIndicator ? (
            <>
              <IdColorIndicator
                id={indicatorColorId ?? 'theme'}
                colour={indicatorColor}
              />
              <Gap size={6} />
            </>
          ) : null}
          <Typography sx={{ color: `${textColor} !important` }}>
            {label}
          </Typography>
        </Row>
      }
    />
  );
}
