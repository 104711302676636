import { CoverageReportMetaData } from '@pages/app/rca/tabs/reports/views/coverage-report-view';
import {
  ReportCell,
  ReportCellType,
  ReportRow,
  RunReportResponse,
} from '@api/types/case/case-report/run-report.response';
import { Option } from '@api/types/option';
import { RadialOverviewStat } from '@components/outcome-report/components/radial-chart';
import { ReportDataSplit } from '@hooks/report/use-report-data-split-hook';
import { ChainItemRadialOverviewStat } from '@components/outcome-report/components/chain-item-radial-chart';

export namespace ReportUtil {
  export function getRank(
    rowClientGeneratedId: number,
    rows: ReportRow[],
    meteData: CoverageReportMetaData[]
  ) {
    const rowIndex = rows.findIndex(
      (x) => x.clientGeneratedId === rowClientGeneratedId
    );
    if (rowIndex < 0) {
      return;
    }

    return meteData[rowIndex].rank;
  }

  export function isCoverageMetaData(
    data: any
  ): data is CoverageReportMetaData[] {
    if (!Array.isArray(data) || data.length === 0) {
      return false;
    }

    return data[0].rank != null && data[0].chainItems != null;
  }

  export function isInChainItemsMetaData(
    chainItemId: number,
    data: Record<number, string> | Array<Option>
  ) {
    if (Array.isArray(data)) {
      return data.some((x) => x.id === chainItemId);
    }

    return data[chainItemId] != null;
  }

  export function getGroupedSolutionTermAndEffectivenessReports(
    effectivnessReport: RunReportResponse<
      {
        solutionId: number;
        efficacyName?: string;
        chainItems: Record<number, string>;
        rank: number;
      },
      {
        chainItemCount: number;
        coveragePercent: number;
        coverageShare: Array<number>;
      }
    >,
    termReport: RunReportResponse<
      {
        solutionId?: number;
        solutionTermId?: number;
        solutionTermName: string;
        chainItems: Record<string, string>;
        rank: number;
      },
      {
        chainItemCount: number;
        coveragePercent: number;
        coverageShare: Array<number>;
      }
    >
  ) {
    // define upfront to force order
    const groupedByTerm: Record<string, ChainItemRadialOverviewStat> = {
      Long: {
        id: 2,
        name: 'Long',
        percentage: 0,
        colour: '#26AEB6',
        chainItems: 0,
      },
      Medium: {
        id: 1,
        name: 'Medium',
        percentage: 0,
        colour: '#FF6C00',
        chainItems: 0,
      },
      Short: {
        id: 0,
        name: 'Short',
        percentage: 0,
        colour: '#C640A9',
        chainItems: 0,
      },
    };

    termReport.metaData.forEach(({ solutionTermName: name }, index) => {
      if (!name) {
        return;
      }
      const data = groupedByTerm[name] ?? {
        id: Object.keys(groupedByTerm).length,
        name,
        percentage: 0,
        chartPercentage: 0,
        rank: 0,
        chainItems: 0,
      };

      const coverage = termReport.totals.coverageShare[index];
      groupedByTerm[name] = {
        ...data,
        percentage: data.chartPercentage! + coverage,
        chainItems:
          data.chainItems +
          Object.keys(termReport.metaData[index].chainItems).length,
      };
    });

    const groupedByEffectiveness: Record<string, RadialOverviewStat> = {
      Low: {
        id: 0,
        name: 'Low',
        percentage: 0,
        chartPercentage: 0,
      },
      Medium: {
        id: 1,
        name: 'Medium',
        percentage: 0,
        chartPercentage: 0,
      },
      High: {
        id: 2,
        name: 'High',
        percentage: 0,
        chartPercentage: 0,
      },
    };

    effectivnessReport.metaData.forEach(({ efficacyName: name }, index) => {
      if (!name) {
        return;
      }

      const data = groupedByEffectiveness[name] ?? {
        id: Object.keys(groupedByEffectiveness).length,
        name,
        percentage: 0,
      };

      const coverage = effectivnessReport.totals.coverageShare[index];
      groupedByEffectiveness[name] = {
        ...data,
        percentage: data.chartPercentage! + coverage,
      };
    });

    return {
      byTerm: groupedByTerm,
      byTermCount: Object.values(groupedByTerm).length,
      byEffectiveness: groupedByEffectiveness,
      byEffectivenessCount: Object.values(groupedByEffectiveness).length,
    };
  }

  export function getRowName(row: ReportRow) {
    return row.cells.find((x) => x.type === ReportCellType.identifier)?.value;
  }

  const NOT_SET_NAMES = [
    'no solution',
    'no impact',
    'no theme',
    'no evidence',
    'no task',
  ];

  export function isNotSetData(row: ReportCell[]) {
    const identifierCell = row.find(
      (x) => x.type === ReportCellType.identifier
    );

    if (identifierCell == null) {
      return false;
    }

    return NOT_SET_NAMES.includes(identifierCell.value.toLowerCase());
  }

  const NOT_REQUIRED_NAMES = [
    'no solutions required',
    'no impacts required',
    'no themes required',
    'no evidence required',
    'no tasks required',
  ];

  export function isNotRequiredData(row: ReportCell[]) {
    const identifierCell = row.find(
      (x) => x.type === ReportCellType.identifier
    );

    if (identifierCell == null) {
      return false;
    }

    return NOT_REQUIRED_NAMES.includes(identifierCell.value.toLowerCase());
  }

  export function splitRows(
    data: RunReportResponse<any, { coverageShare?: number[] }>,
    rows: Array<ReportRow>
  ): ReportDataSplit {
    const nonNegativeIdRows = rows.filter(
      ({ clientGeneratedId }) => clientGeneratedId >= 0
    );
    const negativeIdRows = rows.filter(
      ({ clientGeneratedId }) => clientGeneratedId < 0
    );

    const percentages: Record<number, number> = {};
    for (const row of [...nonNegativeIdRows, ...negativeIdRows]) {
      const rowIndex = data.data.findIndex(
        (x) => x.clientGeneratedId === row.clientGeneratedId
      );
      percentages[row.clientGeneratedId] =
        data.totals.coverageShare?.[rowIndex] ?? 0.0;
    }

    negativeIdRows.sort((a, b) => {
      const aLower = ReportUtil.getRowName(a)?.toLowerCase();
      const bLower = ReportUtil.getRowName(b)?.toLowerCase();

      if (!!aLower && NOT_REQUIRED_NAMES.includes(aLower)) {
        return -1;
      } else if (!!bLower && NOT_REQUIRED_NAMES.includes(bLower)) {
        return 1;
      }

      return 0;
    });

    return {
      nonNegativeIdRows,
      negativeIdRows,
      allRows: [...nonNegativeIdRows, ...negativeIdRows],
      percentages,
    };
  }
}
