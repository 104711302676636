import { api } from '@api/api';
import { ThemeResource } from '@api/types/theme/theme.resource';

const companyThemeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getThemes: build.query<ThemeResource[], void>({
      query: () => ({
        url: 'company/theme',
        method: 'GET',
      }),
      providesTags: (response) =>
        response
          ? [
              ...response.map((data) => ({
                id: data.themeId,
                type: 'CompanyTheme' as const,
              })),
              'CompanyTheme',
            ]
          : [],
    }),
    companyEnableTheme: build.mutation<void, number>({
      query: (id) => ({
        url: `company/theme/${id}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CompanyTheme' }] : [],
    }),
    companyDisableTheme: build.mutation<void, number>({
      query: (id) => ({
        url: `company/theme/${id}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CompanyTheme' }] : [],
    }),
  }),
});

export const {
  useGetThemesQuery,
  useCompanyDisableThemeMutation,
  useCompanyEnableThemeMutation,
} = companyThemeApi;
