import { styled } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import { useMemo } from 'react';
import {
  ChainItemRadialOverviewStat,
  ChainItemReportRadialOverview,
} from '../components/chain-item-radial-chart';

export type SolutionTermCoverage = RunReportResponse<
  {
    solutionId?: number;
    solutionTermId?: number;
    solutionTermName: string;
    chainItems: Record<string, string>;
    rank: number;
    coverage: number;
  },
  {
    chainItemCount: number;
    coveragePercent: number;
    coverageShare: Array<number>;
    solutionCount: number;
    linkedSolutionAverageEffectiveness: number;
  }
>;

interface Props {
  termReport: SolutionTermCoverage;
}

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')(({ theme: { palette } }) => ({
  '.section-title': {
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '43px',
    letterSpacing: '0.15px',
    textAlign: 'center',
  },

  '.intro-wrapper': {
    alignItems: 'flex-start',
  },

  '.overview-title': {
    maxWidth: '50%',
    fontSize: '40px',
    fontWeight: '400',
    lineHeight: '53px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: palette.common.black,

    '.highlight': {
      color: palette.primary.main,
    },
  },
}));

export default function SolutionsSectionOverview({ termReport }: Props) {
  const solutionTermChartData: ChainItemRadialOverviewStat[] = useMemo(() => {
    return termReport.data
      .filter((x) => x.clientGeneratedId >= 0)
      .map((x, i) => {
        var colors = ['', '#C640A9', '#FF6C00', '#26AEB6'];
        return {
          id: termReport.metaData[i].solutionTermId!,
          name: termReport.metaData[i].solutionTermName,
          percentage: termReport.metaData[i].coverage,
          chartPercentage: termReport.totals.coverageShare[i],
          colour: colors[termReport.metaData[i].solutionTermId!],
          chainItems: Object.keys(termReport.metaData[i].chainItems).length,
        };
      })
      .sort((a, b) => a.id - b.id);
  }, [termReport.data, termReport.metaData, termReport.totals.coverageShare]);

  return (
    <Container>
      <div className="section-title">Solutions</div>
      <Row className="intro-wrapper">
        <div className="overview-title">
          This RCA has{' '}
          <span className="highlight">{termReport.totals.solutionCount}</span>{' '}
          solution{termReport.totals.solutionCount === 1 ? '' : 's'}, achieving{' '}
          <span className="highlight">
            {termReport.totals.coveragePercent}%
          </span>{' '}
          chart coverage. The average solution effectiveness is{' '}
          <span className="highlight">
            {termReport.totals.linkedSolutionAverageEffectiveness.toFixed(1)}%
          </span>
          .
        </div>
        <Spacer />
        <ChainItemReportRadialOverview
          name={
            <>
              Term <br />
               Coverage
            </>
          }
          stats={solutionTermChartData}
          size="medium"
          totalChainItems={termReport.totals.chainItemCount}
        />
      </Row>
    </Container>
  );
}
