import { Link, styled } from '@mui/material';

export const BlackButton = styled(Link)({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px 25px',
  background: 'black',
  color: 'white',
  gap: 12,
  fontSize: '14px',
  borderRadius: '30px',
  textDecoration: 'none',
  flex: '0 0 auto',
});
