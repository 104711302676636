import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import useActivityPanel, { ActivityPanelView } from './activity-panel-hook';
import { Box, Chip, Typography } from '@mui/material';
import { timespanFormatter } from '@i18n/formatters';
import { Divider } from '@components/layout-util-components/divider';
import { ActivityAvatar } from '../editor-panels/components/activities/activity-avatar';
import { getActivityDescription } from '../editor-panels/components/activities/get-activity-description';
import { isActivityItemSameDayAsNext } from '../editor-panels/components/activities/is-activity-item-same-day-as-next';
import { AvatarConnectingLine } from '../avatar-connecting-line';
import { EventItemResult } from '@api/types/events/event-item.result';
import { CircleButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListTimeline, faTimes } from '@fortawesome/pro-light-svg-icons';
import Row from '@components/layout-util-components/row';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import { Gap } from '@components/layout-util-components/gap';
import { LoadingIndicator } from '@components/loading-indicator';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

interface Props {
  view: ActivityPanelView;
  onClose?: () => void;
}

export default function ActivityPanel({ view, onClose }: Props) {
  const { isLoading, isFetching, events } = useActivityPanel(view);
  const isChainItemLevel = view === ActivityPanelView.chainItemLevel;

  const closeButton = onClose ? (
    <CircleButton onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </CircleButton>
  ) : undefined;

  return (
    <Column>
      <EditPanelHeader
        title="Activity"
        end={
          <Row>
            <FetchingIndicator show={isFetching} />
            <Gap size={8} />
            {closeButton}
          </Row>
        }
      />
      {isLoading ? (
        <LoadingIndicator show xOffset={-68} />
      ) : !events?.totalCount ? (
        <>
          <Gap size={20} />
          <OnboardingEntryContainer
            icon={<FontAwesomeIcon icon={faListTimeline} size="2x" />}
            title={
              isChainItemLevel
                ? 'There is no activity on this Cause Box'
                : 'There is no activity on this RCA'
            }
            message={
              isChainItemLevel
                ? 'There is currently no historic activity to view on this Cause box'
                : 'There is currently no historic activity to view on this RCA '
            }
            hideBgImage={view === ActivityPanelView.chainItemLevel}
            backgroundColor
          />
        </>
      ) : (
        events?.model.map((activity: EventItemResult, idx: number) => {
          const isFirstActivityInDay = !isActivityItemSameDayAsNext({
            activity,
            activities: events?.model,
            idx,
          });

          const date = new Date(activity.eventDate);
          const timeOfEvent = timespanFormatter(date);

          return (
            <Box
              mt={isFirstActivityInDay ? 3 : 0}
              key={`activity-row-${activity.eventId}`}
            >
              {isFirstActivityInDay && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mb: 3,
                  }}
                >
                  <Chip sx={{ mr: 1 }} label={timeOfEvent} />
                  <Box width={'100%'}>
                    <Divider sx={{ flexGrow: 1 }} />
                  </Box>
                </Box>
              )}
              <Box sx={{ display: 'flex' }}>
                {!isFirstActivityInDay && <AvatarConnectingLine />}
                <ActivityAvatar type={activity.eventType} />
                <Box ml={2}>
                  <Box mb={1}>
                    <Typography variant="body1">
                      {activity.eventDescription}
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="body2">
                      {getActivityDescription({
                        companyUserName: activity.companyUserName,
                        timeOfEvent,
                        date,
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })
      )}
    </Column>
  );
}
