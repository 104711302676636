import requireAuth from '@components/hoc/with-required-auth-hoc';
import ContentContainer from '@components/containers/content-container';
import { Box } from '@mui/material';
import useSolutions from '@pages/app/solutions/solutions-index-hook';
import Row from '@components/layout-util-components/row';
import {
  DropDownFilter,
  SortByFilter,
  WCTTable,
  WCTTableFilters,
} from '@components/table';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { usePageAlertVariants } from '@components/alerts';
import SolutionsTableRow from './components/solutions-table-row';
import SolutionPopoupView from './components/view-solution-popup';
import deleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { useCallback, useEffect, useRef } from 'react';
import { numberFromString } from '@util/string-util';
import Spacer from '@components/layout-util-components/spacer';
import { OutlinedButton } from '@components/buttons';
import { ButtonSkeleton } from '@components/skeletons';
import { useAppDispatch } from '@store/store';
import { resetFocus } from '@store/rca-editor/rca-editor-slice';
import { Gap } from '@components/layout-util-components/gap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import PageCount from '@components/typography/page-count';

interface Props {
  caseId: number;
  canAddSolution?: boolean;
}

type QueryParams = {
  solutionId?: string;
};

function SolutionsIndexPage({ caseId }: Props) {
  const dispatch = useAppDispatch();
  const { solutionId: solutionIdQuery } = useSearchParamsTyped<QueryParams>();
  const { showPopup, showSingle } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const {
    hasNoSolutions,
    solutions,
    search,
    sort,
    sortByOptions,
    isLoading,
    isFetching,
    pagination,
    typeFilter,
    typeFilterOptions,
    termFilter,
    termFilterOptions,
    deleteSolution,
    canDelete,
    canContribute,
    currency,
  } = useSolutions(caseId);

  useEffect(() => {
    dispatch(resetFocus());
  }, [dispatch]);

  const onAddSolution = () => {
    showSingle(() =>
      showPopup(SolutionPopoupView, {
        caseId: caseId!,
        isViewMode: false,
      })
    );
  };

  const onEditSolution = (solutionId: number) => {
    onOpenSolution(solutionId, false);
  };

  const onDeleteSolution = (solutionId: number) => {
    showPopup(deleteConfirmationModal, {
      itemName: 'Solution',
      title: 'Are you sure you want to delete this solution?',
      message:
        'Are you sure you want to delete this solution?\n\nPlease note this will remove this solution from all associated cause boxes.',
      deleteButtonMessage: 'Delete Solution',
      onDelete: async () => {
        try {
          await deleteSolution({ caseId: caseId!, solutionId });
          showSuccessMessage('Solution deleted successfully');
          return true;
        } catch (error) {
          showErrorMessage('Something went wrong');
          return false;
        }
      },
    });
  };

  const onOpenSolution = useCallback(
    (solutionId: number, openInViewMode?: boolean) => {
      showSingle(() =>
        showPopup(SolutionPopoupView, {
          caseId: caseId!,
          solutionId: solutionId,
          isViewMode: openInViewMode,
        })
      );
    },
    [caseId, showPopup, showSingle]
  );

  const hasOpenedInitial = useRef(false);
  useEffect(() => {
    if (hasOpenedInitial.current) {
      return;
    }

    const id = numberFromString(solutionIdQuery);
    if (id != null) {
      onOpenSolution(id, true);
      hasOpenedInitial.current = true;
    }
  }, [onOpenSolution, solutionIdQuery]);

  const showAddButtonInTopRight = isLoading || !hasNoSolutions;

  return (
    <ContentContainer>
      <Row>
        <PageCount
          title="Solutions"
          isLoading={isLoading}
          count={solutions?.totalCount}
        />
        <Spacer />
        {canContribute && showAddButtonInTopRight && (
          <>
            {isLoading ? (
              <Box>
                <ButtonSkeleton />
              </Box>
            ) : (
              <OutlinedButton onClick={onAddSolution}>
                Add Solution
              </OutlinedButton>
            )}
          </>
        )}
      </Row>
      <Gap size={32} />
      {hasNoSolutions ? (
        <OnboardingEntryContainer
          icon={<FontAwesomeIcon icon={faCircleCheck} size="2x" />}
          title="No solutions have been added to this RCA"
          message={
            canContribute
              ? 'Please click below to add solutions to this RCA'
              : undefined
          }
          createMessage="Add Solution"
          onCreateClicked={canContribute ? onAddSolution : undefined}
        />
      ) : (
        <WCTTable
          count={solutions?.totalCount ?? 0}
          isLoading={isLoading}
          pagination={pagination}
          filters={
            <WCTTableFilters
              search={search.value}
              onSearch={search.set}
              isLoading={isLoading}
              isFetching={isFetching}
              rightOfSearchContent={
                <SortByFilter
                  value={sort.value}
                  onChange={sort.set}
                  options={sortByOptions}
                />
              }
            >
              <DropDownFilter
                value={typeFilter.value}
                onChange={typeFilter.set}
                options={typeFilterOptions ?? []}
                multiple
              >
                Type
              </DropDownFilter>
              <DropDownFilter
                value={termFilter.value}
                onChange={termFilter.set}
                options={termFilterOptions ?? []}
                multiple
              >
                Term
              </DropDownFilter>
            </WCTTableFilters>
          }
          headers={[
            {
              title: 'Solution Name',
              sortProperty: 'name',
              colSpan: 2,
              ...sort,
            },
            { title: 'Cause Boxes' },
            { title: 'Type' },
            { title: 'Effectiveness' },
            { title: 'Term' },
            { title: 'Cost', sortProperty: 'cost', ...sort },
            { title: 'Additional Cost' },
            { title: 'Total Cost' },
            { title: '' },
          ]}
        >
          {!isLoading
            ? solutions?.model.map((solution) => (
                <SolutionsTableRow
                  key={solution.solutionId}
                  solution={solution}
                  caseId={caseId}
                  currency={currency}
                  onEdit={
                    canContribute
                      ? () => onEditSolution(solution.solutionId)
                      : undefined
                  }
                  onDelete={
                    canContribute && canDelete(solution)
                      ? () => onDeleteSolution(solution.solutionId)
                      : undefined
                  }
                  onOpen={() => onOpenSolution(solution.solutionId, true)}
                />
              ))
            : null}
        </WCTTable>
      )}
    </ContentContainer>
  );
}

export default requireAuth(SolutionsIndexPage);
