import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { Box, Grid, styled, Typography } from '@mui/material';
import CaseFileTypeIconContainer from '@components/containers/case-file-type-icon-container';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { getInitials } from '@util/name-util';
import { dateOnlyLongFormatter } from '@i18n/formatters';
import { ElipsisButton } from '@components/buttons';
import Row from '@components/layout-util-components/row';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import FlatBadge from '@components/badges/flat-badge';
import useCaseFileMenuActions from '@pages/app/rca/tabs/hooks/case-file-menu-actions-hook';
import { Gap } from '@components/layout-util-components/gap';
import { truncateString } from '@util/string-util';
import CaseFileViewModal, {
  CaseFileViewModalProps,
} from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-view-modal';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import HeaderBGImage from '@assets/svgs/card-detail.svg';

interface Props {
  caseFile: CaseFileListItemResponse;
  onSelectDeleteFile: (file: CaseFileListItemResponse) => Promise<boolean>;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  cursor: 'pointer',
  border: '1px solid rgba(0, 0, 0, 0.25)',
  borderRadius: 8,
  background: palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  margin: '1px',

  '&:hover': {
    border: `2px solid ${palette.blue.dark}`,
    boxShadow: '0px 0px 0px 5px rgba(0, 34, 58, 0.15)',
    margin: '0px',
  },

  'file-size': {
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: '11px',
    letterSpacing: '0px',
    textAlign: 'left',
  },

  'linked-cause-box-container': {
    outline: 'none',
    padding: 4,
    display: 'inline-flex',
    gap: '5px',
    border: '1px solid #000000',
    borderRadius: 4,
    maxWidth: 212,
    overflowY: 'elipsis',
    fontFamily: 'Kyn, Roboto',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: palette.common.black,

    svg: {
      height: 8,
      width: 'auto',
    },

    span: {
      textDecoration: 'underline',
    },
  },
}));

const CardHeader = styled('div')(({ theme: { palette } }) => ({
  background: `url(${HeaderBGImage}) no-repeat top right  ${palette.blue.dark}`,
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  padding: 20,
  gap: 20,
  display: 'flex',
  flexDirection: 'column',
  color: palette.common.white,
  '& p': {
    display: 'block',
    lineHeight: '150%',
    height: '2.9em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .circle-container': {
    backgroundColor: palette.blue.light,
    color: palette.blue.dark,
    svg: {
      height: 18,
    },
  },
}));

const CardContent = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  gap: 20,
}));

const CardInfo = styled('div')(({ theme: { palette } }) => ({
  fontSize: '14px',
  fontWeight: 400,
  '& .MuiTypography-overline': {
    display: 'block',
    marginBottom: '0.5em',
    color: palette.blue.dark,
    fontSize: '12px',
  },
  '& .circle-container': {
    backgroundColor: palette.success.main,
    opacity: 1,
    fontSize: '10px',
    color: '#fff',
  },
  '& .name-container .label': {
    fontSize: '15px',
    fontWeight: 400,
  },
}));

const CardFooter = styled('div')(({ theme: { palette } }) => ({
  background: 'white',
  padding: 20,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  borderTop: `1px solid ${palette.common.grey50}`,
}));

export default function CaseFileItem({ caseFile, onSelectDeleteFile }: Props) {
  const { showPopup } = useUiPopup();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const { linkedSolutions } = caseFile;
  const { getCaseFileMenuActions } = useCaseFileMenuActions({
    onDelete: onSelectDeleteFile,
  });

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Container
        onClick={() => {
          showPopup<CaseFileViewModalProps, never>(CaseFileViewModal, {
            caseId: caseId,
            caseFileId: caseFile.caseFileId,
          });
        }}
      >
        <CardHeader>
          <CaseFileTypeIconContainer type={caseFile.caseFileType} />
          <Typography variant="body1">
            {truncateString(caseFile.name, 45)}
          </Typography>
          {caseFile.chainItems != null && caseFile.chainItems.length > 0 ? (
            <Box alignSelf="flex-start">
              <Gap size={6} />
              <AssociatedCauseBoxBadge count={caseFile.chainItems.length} />
            </Box>
          ) : null}
          {linkedSolutions > 0 ? (
            <Box display="flex" alignSelf="flex-start">
              <Gap size={6} />
              <FlatBadge>{`Solutions (${linkedSolutions})`}</FlatBadge>
            </Box>
          ) : null}
        </CardHeader>
        <CardContent>
          <CardInfo>
            <Typography variant="overline">Added By</Typography>
            <InitialsContainerWithData
              initials={getInitials(caseFile.createdByName)}
              subLabel={caseFile.createdByName}
            />
          </CardInfo>
          <CardInfo>
            <Typography variant="overline">Created</Typography>
            {dateOnlyLongFormatter(caseFile.created)}
          </CardInfo>
        </CardContent>
        <CardFooter>
          <Row contentAtEnd sx={{ borderBottomRightRadius: '7px' }}>
            <ElipsisButton actions={getCaseFileMenuActions(caseFile)} />
          </Row>
        </CardFooter>
      </Container>
    </Grid>
  );
}
