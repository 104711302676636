import { api } from '@api/api';
import { CompanyUserCaseTotalsResponse } from '@api/types/dashboard/company-user-case-totals-response';
import { UserOutcome } from '@api/types/dashboard/user-outcomes-response';
import { DashboardFiltersRequest } from '@api/types/dashboard/dashboard-filters.request';
import { CompanyUserStatsResponse } from '@api/types/dashboard/company-user-stats-response';
import { PaginatedResult } from '@api/types/paginated-result';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import { SHORT_CACHE_DURATION } from '@api/cache-util';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import { CompanyGroupListItemResponse } from '@api/types/company-group/company-group-list-item.response';
import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';

const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyUserStats: build.query<
      CompanyUserStatsResponse,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'CompanyUser/DashboardStats',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    getCompanyUserCaseTotals: build.query<
      CompanyUserCaseTotalsResponse,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Case/CompanyUserCaseTotals',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    getThemesByCoverage: build.query<
      RunReportResponse<any>,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Report/ThemesByCoverage',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      transformResponse: RunReportResponse.fromJson,
    }),
    getImpactsByCoverage: build.query<
      RunReportResponse<any>,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Report/ImpactsByCoverage',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      transformResponse: RunReportResponse.fromJson,
    }),
    getSolutionsByTerm: build.query<
      RunReportResponse<any>,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Report/SolutionsByTerm',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      transformResponse: RunReportResponse.fromJson,
    }),
    getSolutionsByEffectiveness: build.query<
      RunReportResponse<any>,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Report/SolutionsByEffectiveness',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      transformResponse: RunReportResponse.fromJson,
    }),
    getUserCases: build.query<
      PaginatedResult<CaseItemRowResponse>,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Case/User',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.model.map(({ caseId }) => ({
                id: caseId,
                type: 'Case' as const,
              })),
              'Case',
            ]
          : [],
    }),
    getUserOutcomes: build.query<
      PaginatedResult<UserOutcome>,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Outcome',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.model.map(({ outcomeId }) => ({
                id: outcomeId,
                type: 'Outcome' as const,
              })),
              'Outcome',
            ]
          : [],
    }),
    getUserTaskDetails: build.query<
      PaginatedResult<TaskDetailListItemResponse>,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'TaskDetail/User',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.model.map(({ taskDetailId }) => ({
                id: taskDetailId,
                type: 'TaskDetail' as const,
              })),
              'TaskDetail',
            ]
          : [],
    }),
    getUserCompanyGroups: build.query<
      PaginatedResult<CompanyGroupListItemResponse>,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'CompanyGroup/User',
        params,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      providesTags: (response) =>
        response
          ? [
              ...response.model.map(({ companyGroupId }) => ({
                id: companyGroupId,
                type: 'CompanyGroup' as const,
              })),
              'CompanyGroup',
            ]
          : [],
    }),
  }),
});

export const {
  useGetCompanyUserCaseTotalsQuery,
  useGetThemesByCoverageQuery,
  useGetImpactsByCoverageQuery,
  useGetSolutionsByTermQuery,
  useGetSolutionsByEffectivenessQuery,
  useGetUserCasesQuery,
  useGetUserTaskDetailsQuery,
  useGetUserCompanyGroupsQuery,
  useGetUserOutcomesQuery,
  useGetCompanyUserStatsQuery,
} = dashboardApi;
