import { CaseResource } from '@api/types/case/case.resource';
import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { styled, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import AppLogo from '@assets/svgs/app-logo.svg';
import { isNullOrEmpty } from '@util/string-util';
import FlatBadge from '@components/badges/flat-badge';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { Gap } from '@components/layout-util-components/gap';
import OutcomeSeverityBadge from '@components/outcome-report/components/outcome-severity-badge';

interface Props {
  caseDetails: CaseResource;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  background: 'url(/svgs/error-bg.svg) no-repeat center center #00223A',
  backgroundSize: 'cover',
  zIndex: 200,
  minHeight: '100vh',
  padding: '0 25%',
  '@media (min-height: 900px)': {
    justifyContent: 'center',
    background: 'url(/svgs/error-bg.svg) no-repeat center center #00223A',
    backgroundSize: 'cover',
  },

  '@media (min-height: 1100px)': {
    justifyContent: 'center',
    background: 'url(/svgs/error-bg.svg) no-repeat center center #00223A',
    backgroundSize: 'cover',
    minHeight: '1000px',
  },

  '.header_panel': {
    background: '#FFF9EF',
    borderRadius: '16px 16px 0 0',
    '@media (min-height: 900px)': {
      borderRadius: '16px',
    },
    width: '950px',
    padding: '75px 0 0',
    '>.title': {
      fontSize: '40px',
      fontWeight: '400',
      lineHeight: '53px',
      padding: '0 50px 15px',
      letterSpacing: '0px',
      textAlign: 'center',
    },
    '.description': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '29px',
      letterSpacing: '0.17000000178813934px',
      textAlign: 'center',
      width: 'auto',
      padding: '0 75px 35px',
    },
    '.flat-badge': {
      background: '#00223A;',
      color: '#fff',
      padding: '8px 15px',
      fontSize: 15,
      borderRadius: 4,
    },
    '.footer-container': {
      justifyContent: 'space-between',
    },
  },
  '.types-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '17px',
  },

  '.footer-container': {
    width: '100%',
    display: 'flex',
    background: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 40,
    padding: '40px 75px',
    '@media (min-height: 900px)': {
      borderRadius: '0 0 16px 16px',
    },
    '.initials-container-with-data': {
      '.circle-container': {
        background: '#3CA680',
        color: '#ffff',
        opacity: '1',
        fontSize: 11,
      },
      '.title': {
        fontSize: '13px',
        textTransform: 'uppercase',
        fontWeight: '500',
        color: '#949FA2',
      },
      '.label': {
        fontSize: '15px',
        fontWeight: '400',
        color: '#000',
        marginTop: '3px',
      },
    },
    '.flat-badge': {
      background: '#CDF1F3',
      padding: '8px 15px',
      fontSize: 15,
    },
    '.created_by, .severity, .dateCreated .datelabel, .priority': {
      fontSize: '13px',
      textTransform: 'uppercase',
      fontWeight: '500',
      color: '#949FA2',
    },
    '.severity ~ .MuiChip-filled': {
      height: 'auto',
      padding: '4px 4px',
      marginLeft: '10px',
      maxWidth: 150,
      borderStyle: 'solid',
      p: {
        flexShrink: 0,
      },
    },

    '.dateCreated': {
      '.date': {
        fontSize: '15px',
        fontWeight: '400',
        color: '#000',
        marginTop: '3px',
      },
    },
  },

  span: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
  },
});

export default function CoverSection({ caseDetails }: Props) {
  const {
    name,
    description,
    caseTypes,
    created,
    createdByCompanyUserName,
    caseSeverityName,
    casePriorityName,
  } = caseDetails;

  return (
    <SectionScaffold id="cover" backgroundColor="#00223A">
      <Container>
        <Gap size={32} />
        <img src={AppLogo} alt="logo" width={120} />
        <Gap size={64} />
        <div className="header_panel">
          <div className="title">{name}</div>
          {!isNullOrEmpty(name) ? (
            <>
              <Gap size={20} />
              <div className="description">{description}</div>
            </>
          ) : null}
          {caseTypes != null && caseTypes.length > 0 ? (
            <>
              <Gap size={20} />
              <div className="types-container">
                {caseTypes.map((caseType) => (
                  <FlatBadge key={caseType.id} rounded>
                    {caseType.label}
                  </FlatBadge>
                ))}
              </div>
            </>
          ) : null}
          <div className="footer-container">
            <InitialsContainerWithData
              label="Created By"
              fullName={createdByCompanyUserName}
            />
            <div className="dateCreated">
              <Typography className="datelabel">REPORT DATE</Typography>
              <Typography className="date">
                {dateOnlyShortFormatter(created)}
              </Typography>
            </div>
            <span>
              <span className="severity">SEVERITY</span>
              <OutcomeSeverityBadge severity={caseSeverityName} />
            </span>
            {!isNullOrEmpty(casePriorityName) ? (
              <>
                <Gap size={50} />
                <span>
                  <span className="priority">PRIORITY</span>
                  <FlatBadge rounded>{caseSeverityName}</FlatBadge>
                </span>
              </>
            ) : null}
          </div>
        </div>
      </Container>
    </SectionScaffold>
  );
}
