import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    isOpen: false,
  },
  reducers: {
    setNotificationsOpen(state, { payload }: PayloadAction<boolean>) {
      state.isOpen = payload;
    },
    openNotificationsPanel(state, _: PayloadAction) {
      state.isOpen = true;
    },
    toggleNotificationsPanel(state, _: PayloadAction) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const {
  setNotificationsOpen,
  openNotificationsPanel,
  toggleNotificationsPanel,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
