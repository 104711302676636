import { CaseFileType } from '@api/types/case/case-file/case-file-type';
import CircleContainer from '@components/containers/circle-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faPaperclip } from '@fortawesome/pro-light-svg-icons';

interface Props {
  type: CaseFileType;
}

export default function CaseFileTypeIconContainer({ type }: Props) {
  return (
    <CircleContainer size={32}>
      {type === CaseFileType.file ? (
        <FontAwesomeIcon icon={faFolder} />
      ) : (
        <FontAwesomeIcon icon={faPaperclip} />
      )}
    </CircleContainer>
  );
}
