import { styled } from '@mui/material';
import Row from '@components/layout-util-components/row';
import DonutChart from 'react-donut-chart';
import { getColorForId } from '@util/colour-identifiers';
import { Gap } from '@components/layout-util-components/gap';
import { Circle } from '@mui/icons-material';
import { Item } from 'react-donut-chart/dist/DonutChart';
import { ReactNode, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export interface RadialOverviewStat {
  id?: number;
  colour?: string;
  name: string;
  percentage?: number;
  chartPercentage?: number;
}

export interface ReportRadialOverviewProps {
  name: string | ReactNode;
  stats: Array<RadialOverviewStat>;
  size: 'medium' | 'large';
  totalPercentage?: number;
  onItemHover?: (item: Item) => void;
  onItemLeave?: () => void;
  highlightItemId?: number;
  defaultDisplayInfo?: { label: string; value: string };
  accumulateTotalsForDefaultPercentage?: boolean;
  renderStatValue?: (stat: RadialOverviewStat) => JSX.Element;
}

const MEDIUM_DONUT_SIZE = 225;
const LARGE_DONUT_SIZE = 425;
const ReportRadialOverviewContainer = styled('div')<ReportRadialOverviewProps>(
  ({ size, theme: { palette } }) => ({
    '.donut-container': {
      position: 'relative',

      '.donut-chart-arcs-path,selected': {
        opacity: 1,
      },
    },

    '.donut-chart-innertext': {
      display: 'none',
    },

    '.chart-title-container': {
      top: '50%',
      left: '50%',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'translate(-50%, -50%)',
    },

    ...(size === 'large' && {
      '.donut-chart': {
        maxHeight: LARGE_DONUT_SIZE,
      },

      '.chart-title': {
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '29px',
        letterSpacing: '0px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },

      '.chart-percentage': {
        fontWeight: '300',
        letterSpacing: '0px',
        fontSize: '65px',
        lineHeight: '107px',
        textAlign: 'center',

        '>.small': {
          fontSize: '40px',
          lineHeight: '53px',
        },
      },
    }),

    ...(size === 'medium' && {
      width: 500,
      '.chart_layout': {
        justifyContent: 'space-between',
      },
      '.donut-chart': {
        maxHeight: MEDIUM_DONUT_SIZE,
      },

      '.chart-title': {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '18px',
        letterSpacing: '0px',
        marginTop: '10px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },

      '.chart-percentage': {
        fontWeight: '300',
        letterSpacing: '0px',
        fontSize: '32px',
        lineHeight: '38px',
        textAlign: 'center',

        '>.small': {
          fontSize: '25px',
          lineHeight: '53px',
        },
      },
    }),

    '.stat-container': {
      gap: 8,
      display: 'flex',
      flexDirection: 'column',

      '.item': {
        border: '1px solid rgba(0,0,0, 0.25)',
        background: palette.common.white,
        borderRadius: '25px',
        padding: '8px 12px',
        minWidth: 235,

        '&.selected': {
          border: '2px solid rgba(0,0,0, 0.75)',
          padding: '7px 11px',
        },

        '.stat-name': {
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '0.17000000178813934px',
          textAlign: 'left',
          color: '#1F1F1F',
        },

        '.stat-value': {
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '20px',
          letterSpacing: '0.17000000178813934px',
          textAlign: 'left',
          color: '#1F1F1F',
          marginLeft: 'auto',
        },
      },
    },
  })
);

export const ReportRadialOverview = (props: ReportRadialOverviewProps) => {
  const {
    name,
    stats,
    size,
    onItemHover,
    highlightItemId,
    onItemLeave,
    defaultDisplayInfo,
    renderStatValue,
  } = props;

  const donutSize = size === 'large' ? LARGE_DONUT_SIZE : MEDIUM_DONUT_SIZE;
  const displayStatContainer = size === 'medium';

  const [selectedItem, setSelectedItem] = useState<Item | undefined>();

  const debouncedOnLeaveHover = useDebouncedCallback(() => {
    setSelectedItem(undefined);
    onItemLeave?.call(this);
  }, 200);

  const onHoverItemCallback = (item: Item) => {
    debouncedOnLeaveHover.cancel();
    setSelectedItem(item);
    onItemHover?.call(this, item);
  };

  const displayInfo = useMemo(() => {
    if (highlightItemId != null) {
      const item = stats.find((stat) => stat.id === highlightItemId);
      if (item != null) {
        return {
          label: item?.name,
          value: item?.percentage,
        } as Item;
      }
    }
    if (selectedItem != null) {
      return selectedItem;
    }

    if (defaultDisplayInfo) {
      return undefined;
    }

    return {
      label: name,
      // value: displayTotalPerct,
    } as Item;
  }, [highlightItemId, name, selectedItem, stats, defaultDisplayInfo]);

  return (
    <ReportRadialOverviewContainer {...props}>
      <Row className="chart_layout">
        <div className="donut-container">
          <DonutChart
            onMouseEnter={onHoverItemCallback}
            onMouseLeave={debouncedOnLeaveHover}
            clickToggle={false}
            interactive={true}
            width={donutSize}
            height={donutSize}
            legend={false}
            strokeColor="transparent"
            className="donut-chart"
            colorFunction={(_, index) => {
              const stat = stats[index];
              return stat.colour ?? getColorForId(stat.id ?? -1);
            }}
            selectedOffset={0}
            formatValues={(values) => `${values?.toFixed(2) ?? 0}%`}
            data={stats.map((stat) => ({
              label: stat.name,
              value: stat.chartPercentage!,
            }))}
          />
          {displayInfo && (
            <div className="chart-title-container">
              <div className="chart-title">{displayInfo.label}</div>
              <div className="chart-percentage">
                {displayInfo.value !== undefined ? (
                  <>
                    <span>{displayInfo.value.toFixed(2)}</span>
                    <span className="small">%</span>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
          {!displayInfo && defaultDisplayInfo && (
            <div className="chart-title-container">
              <div className="chart-title">{defaultDisplayInfo.label}</div>
              <div className="chart-percentage">{defaultDisplayInfo.value}</div>
            </div>
          )}
        </div>

        {displayStatContainer && (
          <>
            <div className="stat-container">
              {stats.map((stat) => (
                <Row
                  key={stat.id}
                  className={`item ${
                    displayInfo?.label === stat.name ? 'selected' : ''
                  }`}
                  onMouseEnter={() => {
                    const hoverItem: Item = {
                      label: stat.name,
                      value: stat.percentage!,
                    };
                    onHoverItemCallback(hoverItem);
                  }}
                  onMouseLeave={debouncedOnLeaveHover}
                >
                  <Circle
                    sx={{
                      color: stat.colour ?? getColorForId(stat.id ?? -1),
                      width: 17,
                      height: 17,
                    }}
                  />
                  <Gap size={12} />
                  <div className="stat-name">{stat.name}</div>
                  <Gap size={12} />
                  <div className="stat-value">
                    {renderStatValue
                      ? renderStatValue(stat)
                      : stat.percentage !== undefined
                      ? `${stat.percentage.toFixed(2)}%`
                      : ''}
                  </div>
                </Row>
              ))}
            </div>
          </>
        )}
      </Row>
    </ReportRadialOverviewContainer>
  );
};
