import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import {
  WAInstallRow,
  WAUpsertInstallResponse,
} from '@api/types/wct-admin/wct-admin-install-types';
import { Grid, styled } from '@mui/material';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import useUpsertInstallForm from '@pages/wct-admin/installs/components/forms/wa-upsert-install-hook';
import { ColumnForm } from '@components/layout-util-components/column';
import WCTTextField from '@components/input/text-field';
import WCTSwitchField from '@components/input/switch-field';

export interface WAUpsertInstallFormProps
  extends SideDrawerFormProps<WAUpsertInstallResponse> {
  install?: WAInstallRow;
}

const Container = styled(DrawerContent)({});

function WAUpsertInstallForm({ install, onClose }: WAUpsertInstallFormProps) {
  const {
    isEdit,
    upsertInstall,
    installApiUrl,
    installAppUrl,
    name,
    isBusy,
    manualPayments,
    companyLimit,
    canSubmit,
  } = useUpsertInstallForm(install);

  const onSubmit = async () => {
    const success = await upsertInstall();
    if (success) {
      onClose();
    }
  };

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <DrawerHeader onClose={onClose}>
        {isEdit ? `Edit ${install!.name}` : 'Create Install'}
      </DrawerHeader>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <WCTTextField
              label="Name"
              name="name"
              onChange={name.set}
              value={name.value}
              error={name.error}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <WCTTextField
              label="Company Limit"
              name="companyLimit"
              onChange={companyLimit.set}
              value={companyLimit.value}
              error={companyLimit.error}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <WCTSwitchField
              label="Manual Payments"
              name="manualPayments"
              onChange={manualPayments.set}
              value={manualPayments.value}
              error={manualPayments.error}
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              label="API URL"
              name="apiUrl"
              onChange={installApiUrl.set}
              value={installApiUrl.value}
              error={installApiUrl.error}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              label="APP URL"
              name="appUrl"
              onChange={installAppUrl.set}
              value={installAppUrl.value}
              error={installAppUrl.error}
              required
            />
          </Grid>
        </Grid>
      </Container>
      <DrawerFooter>
        <OutlinedButton onClick={() => onClose()}>Cancel</OutlinedButton>
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          {isEdit ? 'Update' : 'Create'}
        </PrimaryButton>
      </DrawerFooter>
    </ColumnForm>
  );
}

export default withSideDrawer(WAUpsertInstallForm);
