import { CaseFileType } from '@api/types/case/case-file/case-file-type';
import { EvidenceSensitivity } from '@api/types/case/case-evidence/evidence-sensitivity';
import { Option } from '@api/types/option';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { EvidenceStrength } from '@api/types/case/case-evidence/evidence-strength';
import { CaseSolutionResource } from '@api/types/case/case-solution/case-solution.resource';

export class CaseFileSolutionItem {
  solutionId!: number;
  caseId!: number;
  name!: string;
  description!: string;
  cost!: number;
  rejected!: boolean;
  created!: string; // Datetime

  static toResource(item: CaseFileSolutionItem): CaseSolutionResource {
    return {
      solutionId: item.solutionId,
      caseId: item.caseId,
      name: item.name,
      description: item.description,
      cost: item.cost,
      rejected: item.rejected,
      createdByCompanyUserId: 0,
      createdByName: '',
      caseFiles: [],
      themes: [],
      created: item.created,
      chainItems: [],
    };
  }
}

export class CaseFileEvidenceItem {
  evidenceId!: number;
  chainItemIds!: Array<number>;
  name!: string;
  description?: string;
  sensitivity!: EvidenceSensitivity;
  quality!: EvidenceStrength;

  static toResource(item: CaseFileEvidenceItem): CaseEvidenceResource {
    return {
      evidenceId: item.evidenceId,
      name: item.name,
      description: item.description,
      sensitivity: item.sensitivity,
      quality: item.quality,
      caseFiles: [],
      chainItemIds: item.chainItemIds,
      chainItems: [],
      createdByCompanyUserId: 0,
      createdByName: '',
      created: '',
    };
  }
}

export class CaseFileResource {
  caseFileId!: number;
  caseFileType!: CaseFileType;
  name!: string;
  fileMetadataId!: number;
  filename!: string;
  fileLocation?: string;
  createdByCompanyUserId!: number;
  createdByName!: string;
  solutions!: Array<CaseFileSolutionItem>;
  evidence!: Array<CaseFileEvidenceItem>;
  chainItems!: Array<Option>;
  created!: string; // Datetime
  fileLength?: number;

  static toListItemResponse(item: CaseFileResource): CaseFileListItemResponse {
    return {
      caseFileId: item.caseFileId,
      caseFileType: item.caseFileType,
      name: item.name,
      fileMetadataId: item.fileMetadataId,
      filename: item.filename,
      fileLength: item.fileLength,
      fileLocation: item.fileLocation,
      createdByCompanyUserId: item.createdByCompanyUserId,
      createdByName: item.createdByName,
      linkedSolutions: item.solutions.length,
      linkedEvidence: item.evidence.length,
      chainItems: item.chainItems,
      created: item.created,
    };
  }
}
