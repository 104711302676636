import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useEffect, useMemo } from 'react';
import { LensesPanelState } from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import {
  useGetReportRankByOptionsQuery,
  useGetReportSubtypeOptionsQuery,
} from '@api/endpoints/report-type.api';
import { useGetCaseImpactTypesQuery } from '@api/endpoints/case-impact-type.api';
import useReportTypeOptions, {
  ReportTypeListItem,
} from '@pages/app/rca/tabs/lenses/report-type-options-hook';
import { ReportType } from '@api/types/report-type/report-type.option';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { useAppDispatch, useAppSelector } from '@store/store';
import { showReportByRunRequest } from '@store/reports/reports-actions';
import { RunReportRequest } from '@api/types/case/case-report/run-report.request';
import { selectRunReportModel } from '@store/reports/reports-selectors';
import {
  useGetImpactReportGroupOptionsQuery,
  useGetImpactReportTypeOptionsQuery,
} from '@api/endpoints/impact-report.api';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { isNullOrEmpty } from '@util/string-util';
import { ReportRankBy } from '@api/types/report-type/report-rank-by.option';
import { useGetCaseImpactsQuery } from '@api/endpoints/case.api';

export default function useReportForm({
  selectedReportType,
}: LensesPanelState) {
  const dispatch = useAppDispatch();
  const { showErrorMessage } = usePageAlertVariants();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const currentRunReport = useAppSelector(selectRunReportModel);

  const reportType = selectedReportType!;
  const shouldDisplayImpactOptions =
    reportType.reportType === ReportType.impact ||
    reportType.reportType === ReportType.dominant;

  const { data: trackedImpactOptions, isLoading: loadingTrackedImpactOptions } =
    useGetCaseImpactTypesQuery(caseId);

  const { data: caseImpacts, isLoading: loadingCaseIImpacts } =
    useGetCaseImpactsQuery({ caseId });

  const {
    data: impactReportTypeOptions,
    isLoading: loadingImpactReportTypeOptions,
  } = useGetImpactReportTypeOptionsQuery();

  const {
    data: impactReportGroupOptions,
    isLoading: loadingImpactReportGroupOptions,
  } = useGetImpactReportGroupOptionsQuery();

  const reportSubType = useField<number>([], currentRunReport?.reportSubTypeId);
  const filteredCaseImpacts = useField<number[]>(
    [],
    currentRunReport?.filterCaseImpacts
  );
  const rankBy = useField<number>([], currentRunReport?.reportRankById);
  const trackedImpact = useField<number>(
    [
      required({
        when: () =>
          reportType.reportType === ReportType.impact ||
          reportType.reportType === ReportType.dominant,
      }),
    ],
    currentRunReport?.caseImpactTypeId
  );

  const impactType = useField<string>(
    [
      required({
        when: () => false, //shouldDisplayImpactOptions,
      }),
    ],
    currentRunReport?.impactType
  );
  const impactGroup = useField<string>(
    [required({ when: () => reportType.reportType === ReportType.dominant })],
    currentRunReport?.impactGroup
  );

  const selectedIds = useField<Array<number>>(
    [],
    useMemo(() => currentRunReport?.ids ?? [], [currentRunReport?.ids])
  );

  const selectableOptions = useReportTypeOptions(
    reportType.reportTypeId,
    reportSubType.value
  );

  const {
    data: reportSubTypeOptions,
    isFetching: loadingReportSubTypes,
    isSuccess: hasLoadedReportSubTypes,
  } = useGetReportSubtypeOptionsQuery(reportType.reportTypeId);

  const setReportSubType = reportSubType.set;
  useEffect(() => {
    if (
      !loadingReportSubTypes &&
      hasLoadedReportSubTypes &&
      reportSubTypeOptions.length > 0
    ) {
      setReportSubType(reportSubTypeOptions[0].reportSubTypeId);
    }
  }, [
    reportSubTypeOptions,
    loadingReportSubTypes,
    hasLoadedReportSubTypes,
    setReportSubType,
  ]);

  const {
    data: rankByOptions,
    isFetching: loadingRankByOptions,
    isSuccess: hasLoadedRankByOptions,
  } = useGetReportRankByOptionsQuery(reportSubType.value ?? -1, {
    skip: reportSubType.value == null,
  });

  const { isValid, validateAll } = useFieldsWatcher(
    [reportSubType, rankBy, trackedImpact, impactType, impactGroup],
    false
  );

  const isLoading =
    loadingTrackedImpactOptions ||
    loadingImpactReportGroupOptions ||
    loadingImpactReportTypeOptions ||
    loadingCaseIImpacts;
  const canSubmit =
    isValid && !isLoading && !loadingReportSubTypes && !loadingRankByOptions;

  const isCoverageReport = reportType?.reportType === ReportType.coverage;
  const isDominantReport = reportType?.reportType === ReportType.dominant;

  const shouldDisplayFilters =
    !isCoverageReport &&
    !isDominantReport &&
    !isNullOrEmpty(selectableOptions.name) &&
    selectableOptions.options.length > 0;

  const resetRankBy = rankBy.reset;
  const resetImpactType = impactType.reset;
  const resetDisplayLevel = impactType.reset;
  const resetSelectedIds = selectedIds.reset;
  const resetFilteredCaseImpacts = filteredCaseImpacts.reset;
  const resetTrackedImpacts = trackedImpact.reset;
  useEffect(() => {
    if (reportSubType.value != null) {
      resetRankBy();
      resetImpactType();
      resetDisplayLevel();
      resetSelectedIds();
      resetFilteredCaseImpacts();
      resetTrackedImpacts();
    }
  }, [
    reportSubType.value,
    resetFilteredCaseImpacts,
    resetDisplayLevel,
    resetImpactType,
    resetRankBy,
    resetSelectedIds,
    resetTrackedImpacts,
  ]);

  useEffect(() => {
    resetFilteredCaseImpacts();
  }, [trackedImpact.value, resetFilteredCaseImpacts]);

  useEffect(() => {
    if (reportType.reportTypeId && selectableOptions) {
      selectedIds.set(selectableOptions.options.map((m) => m.id));
    }
  }, [reportType.reportTypeId, selectableOptions, selectedIds]);

  const setRankBy = rankBy.set;
  useEffect(() => {
    if (rankByOptions == null) {
      setRankBy(null);
      return;
    }

    if (rankByOptions.length === 1) {
      setRankBy(rankByOptions[0].reportRankById);
    } else if (rankByOptions.length > 0) {
      const bestDefaultOptions = rankByOptions.find(
        (x) =>
          x.reportRankBy === ReportRankBy.coverageAll ||
          x.reportRankBy === ReportRankBy.coverage
      );
      if (bestDefaultOptions != null) {
        setRankBy(bestDefaultOptions.reportRankById);
      } else {
        setRankBy(rankByOptions[0].reportRankById);
      }
    }
  }, [rankByOptions, setRankBy]);

  const toggleSelect = (option: ReportTypeListItem) => {
    if (selectedIds.value.includes(option.id)) {
      selectedIds.set(selectedIds.value.filter((id) => id !== option.id));
    } else {
      selectedIds.set([...selectedIds.value, option.id]);
    }
  };

  const clearSelected = () => {
    selectedIds.set([]);
  };

  const selectAll = () => {
    selectedIds.set(selectableOptions.options.map((m) => m.id));
  };

  const isSelected = (option: ReportTypeListItem) => {
    return selectedIds.value.includes(option.id);
  };

  const submit = async () => {
    if (
      !validateAll() ||
      (shouldDisplayFilters && selectedIds.value.length === 0)
    ) {
      if (shouldDisplayFilters && selectedIds.value.length === 0) {
        selectedIds.setError('Please select at least one filter');
      }
      return false;
    }

    const selectedSubType = reportSubTypeOptions!.find(
      (x) => x.reportSubTypeId === reportSubType.value
    )!;
    const selectedRankBy = rankByOptions!.find(
      (x) => x.reportRankById === rankBy.value
    )!;

    try {
      dispatch(
        showReportByRunRequest({
          reportType: reportType.reportType,
          reportTypeName: reportType.name,
          reportTypeId: reportType.reportTypeId,
          reportSubTypeId: selectedSubType.reportSubTypeId,
          reportSubTypeName: selectedSubType.name,
          reportSubType: selectedSubType.reportSubType,
          reportRankById: selectedRankBy.reportRankById,
          reportRankByName: selectedRankBy.name,
          reportRankBy: selectedRankBy.reportRankBy,
          caseImpactTypeId: trackedImpact.value,
          impactGroup: impactGroup.value,
          filterCaseImpacts: filteredCaseImpacts.value,
          ids: shouldDisplayFilters
            ? selectedIds.value
            : isCoverageReport
            ? selectableOptions?.options?.map((x) => x.id) ?? []
            : [],
          impactType: impactType.value,
          caseImpactTypeName: trackedImpactOptions?.find(
            (x) => x.caseImpactTypeId === trackedImpact.value
          )?.name,
          filterCaseImpactNames: caseImpacts
            ?.filter(
              (x) =>
                filteredCaseImpacts.value &&
                Array.isArray(filteredCaseImpacts.value) &&
                filteredCaseImpacts.value.some((y) => y === x.caseImpactId)
            )
            .map((x) => x.name),
        })
      );
      return true;
    } catch (e) {
      const { message, errors } = e as ApiError<RunReportRequest>;

      reportSubType.setError(errors?.reportSubTypeId);
      rankBy.setError(errors?.reportRankById);

      if (shouldDisplayImpactOptions) {
        showErrorMessage(errors?.reportTypeId ?? errors?.ids ?? message);
      } else {
        showErrorMessage(
          errors?.reportTypeId ??
            errors?.reportRankById ??
            errors?.ids ??
            message
        );
      }

      return false;
    }
  };

  return {
    reportType,
    submit,
    canSubmit,
    reportSubTypeOptions,
    reportSubType,
    loadingReportSubTypes,
    hasLoadedReportSubTypes,
    isLoading,
    rankBy,
    impactType,
    caseImpacts:
      caseImpacts?.filter(
        (o) =>
          !trackedImpact.value || trackedImpact.value === o.caseImpactTypeId
      ) ?? [],
    trackedImpactOptions:
      trackedImpactOptions?.filter(
        (o) =>
          caseImpacts &&
          caseImpacts.some((x) => x.caseImpactTypeId === o.caseImpactTypeId)
      ) ?? [],
    trackedImpact,
    impactGroup,
    impactReportTypeOptions,
    impactReportGroupOptions,
    selectableOptions,
    rankByOptions,
    loadingRankByOptions,
    hasLoadedRankByOptions,
    toggleSelect,
    isSelected,
    shouldDisplayImpactOptions,
    shouldDisplayFilters,
    clearSelected,
    selectAll,
    selectedIds,
    filteredCaseImpacts,
  };
}
