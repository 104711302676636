import { InlineBoxSkeleton } from '@components/skeletons';
import WCTSwitchField, {
  SwitchContainer,
} from '@components/input/switch-field';
import { Typography } from '@mui/material';
import { ConfirmationModal } from '@components/ui-popup/modals';
import { useState } from 'react';
import { Gap } from '@components/layout-util-components/gap';

export interface ReferenceDataDisableListOption {
  id: number;
  label: string;
  enabled: boolean;
  hasWarning: boolean;
  disabledDateTime?: Date;
}

interface Props {
  isLoading: boolean;
  options: ReferenceDataDisableListOption[];
  onEnable: (id: number) => void;
  onDisable: (id: number) => void;
  type: string;
}

export default function ReferenceDataDisableList({
  isLoading,
  options,
  onEnable,
  onDisable,
  type,
}: Props) {
  const [selected, setSelected] = useState<number>();

  return (
    <>
      {options.map((o) => (
        <>
          <SwitchContainer variant="light">
            {isLoading ? (
              <InlineBoxSkeleton />
            ) : (
              <>
                <Typography sx={{ flex: 1 }}>{o.label}</Typography>
                <WCTSwitchField
                  name={`theme-toggle-${o.id}`}
                  label={o.enabled ? 'Active' : 'Disabled'}
                  value={o.enabled}
                  onChange={(enabled: boolean) => {
                    if (!enabled) {
                      setSelected(o.id);
                    } else {
                      onEnable(o.id);
                    }
                  }}
                />
              </>
            )}
          </SwitchContainer>
          <Gap size={15} />
        </>
      ))}
      {selected && (
        <ConfirmationModal
          show={true}
          title={`Are you sure you want to disable this ${type}?`}
          message={`Disabling this ${type} will make it unavailable to select for new RCA. It will remain active on all existing RCA.`}
          yesButtonAsyncAction={async () => {
            await onDisable(selected);
            return true;
          }}
          onClose={() => setSelected(undefined)}
        />
      )}
    </>
  );
}
