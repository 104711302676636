import {
  WAInstallRow,
  WAUpsertInstallRequest,
} from '@api/types/wct-admin/wct-admin-install-types';
import useField from '@hooks/use-field-hook';
import { onlyNumbers, required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useUpsertWAInstallMutation } from '@api/endpoints/wct-admin/wct-admin-install.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { invalidation } from '@api/cache-util';
import { numberFromString } from '@util/string-util';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import WAInstallSecretModal from '@pages/wct-admin/installs/components/wa-install-secret-modal';

export default function useUpsertInstallForm(install?: WAInstallRow) {
  const isEdit = install != null;
  const { showPopup } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const [upsertInstallMutation] = useUpsertWAInstallMutation();

  const name = useField<string>([required()], install?.name);
  const companyLimit = useField<string>(
    [required(), onlyNumbers()],
    install?.companyCount?.toString() ?? '1'
  );
  // TODO(Nick): Get value from model when it's added
  const manualPayments = useField<boolean>([required()], false);
  const installApiUrl = useField<string>([required()], install?.installAPIUrl);
  const installAppUrl = useField<string>([required()], install?.installAPPUrl);

  const { validateAll, isValid } = useFieldsWatcher([
    name,
    companyLimit,
    manualPayments,
    installApiUrl,
    installAppUrl,
  ]);

  const [upsertInstall, isBusy] = useBusyAction(async () => {
    try {
      if (!validateAll()) {
        return false;
      }

      const result = await upsertInstallMutation({
        installId: install?.installId,
        name: name.value,
        companyLimit: numberFromString(companyLimit.value)!,
        manualPayments: manualPayments.value ? 1 : 0,
        installAPIUrl: installApiUrl.value,
        installAPPUrl: installAppUrl.value,
      }).unwrap();
      await invalidation('WAInstall');

      const secret = result.installSecret;
      if (secret != null && secret.length > 0) {
        await showPopup(WAInstallSecretModal, { secret });
      }

      showSuccessMessage('Install saved successfully');
      return true;
    } catch (error) {
      if (isApiError<WAUpsertInstallRequest>(error)) {
        const { message, errors } = error;
        showErrorMessage(errors?.installId ?? message);

        name.setError(errors?.name);
        companyLimit.setError(errors?.companyLimit);
        manualPayments.setError(errors?.manualPayments);
        installApiUrl.setError(errors?.installAPIUrl);
        installAppUrl.setError(errors?.installAPPUrl);
      }

      return false;
    }
  });

  const canSubmit = !isBusy && isValid;

  return {
    isEdit,
    name,
    companyLimit,
    manualPayments,
    installApiUrl,
    installAppUrl,
    upsertInstall,
    isBusy,
    canSubmit,
  };
}
