import { styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownLeftAndUpRightToCenter,
  faPencil,
  faRefresh,
  faTimes,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/pro-light-svg-icons';
import {
  RunReportModel,
  setOpenLensesSlideOut,
  toggleShrinkView,
} from '@store/reports/reports-slice';
import { useAppDispatch, useAppSelector } from '@store/store';
import { shouldShrinkReportView } from '@store/reports/reports-selectors';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import { ReportPanelState } from '../report-panel-hook';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';

interface Props {
  runModel: RunReportModel;
  onClose: () => void;
  state: ReportPanelState;
}

const Container = styled('div')({
  height: 120,
  display: 'flex',
  padding: 20,
});

export default function ReportTitleBar({ runModel, onClose, state }: Props) {
  const dispatch = useAppDispatch();

  const isShrunk = useAppSelector(shouldShrinkReportView);
  const canEdit = runModel.reportTypeId !== -1;
  const { name, isReRunning, reRun } = state;

  return (
    <Container>
      <Column>
        <Typography variant="body1">{runModel.reportTypeName}</Typography>
        <WCTTextField
          name="reportName"
          value={name.value}
          error={name.error}
          onChange={name.set}
          required
        />
      </Column>
      <Spacer />
      <Gap size={15} />
      <FlatButton onClick={() => dispatch(toggleShrinkView())}>
        <FontAwesomeIcon
          icon={
            isShrunk
              ? faUpRightAndDownLeftFromCenter
              : faDownLeftAndUpRightToCenter
          }
        />
      </FlatButton>
      <Gap size={24} />
      {canEdit ? (
        <FlatButton onClick={() => dispatch(setOpenLensesSlideOut(true))}>
          <FontAwesomeIcon icon={faPencil} />
        </FlatButton>
      ) : null}
      <Gap size={12} />
      <FlatButton disabled={isReRunning} onClick={() => reRun()}>
        {isReRunning ? (
          <FetchingIndicator show />
        ) : (
          <FontAwesomeIcon icon={faRefresh} />
        )}
      </FlatButton>
      <Gap size={24} />
      <FlatButton shape="circle" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </FlatButton>
    </Container>
  );
}
