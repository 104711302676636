import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyThemes from './company-themes-hook';
import ReferenceDataDisableList from './components/reference-data-disable-list';
import SubpageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';

function CompanyThemesPage() {
  const { options, loadingThemes, enableTheme, disableTheme } =
    useCompanyThemes();

  return (
    <>
      <SubpageTitle breadCrumb="RCA Settings" title="Theme Configuration" />
      <Gap size={32} />
      <ReferenceDataDisableList
        options={options}
        isLoading={loadingThemes}
        onEnable={enableTheme}
        onDisable={disableTheme}
        type="theme"
      />
    </>
  );
}

export default requireAuth(CompanyThemesPage);
