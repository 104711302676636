import { CaseFileManagerState } from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal-hook';
import { Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { ColumnForm } from '@components/layout-util-components/column';
import useCaseFileManagerLink from '@pages/app/rca/tabs/components/modals/case-file-manager/views/case-file-manager-link-hook';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { CaseFileResource } from '@api/types/case/case-file/case-file.resource';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: CaseFileManagerState;
  onClose: (caseFiles?: Array<CaseFileListItemResponse>) => void;
}

export default function CaseFileManagerLinkView({ state, onClose }: Props) {
  const { preselectedFileIds, caseFiles } = state;
  const { submit, linkUrl, linkName, canSubmitLink, isBusy } =
    useCaseFileManagerLink(state);

  const onSubmit = async () => {
    const result = await submit();
    if (result === false) {
      return;
    }

    const newFiles = result.map(CaseFileResource.toListItemResponse);
    const oldFiles =
      caseFiles?.model.filter(
        (x) => preselectedFileIds?.includes(x.caseFileId) ?? false
      ) ?? [];
    const allFiles = [...oldFiles, ...newFiles];

    onClose(allFiles);
  };

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Typography variant="body1">Enter file location </Typography>
      <Gap size={4} />
      <WCTTextField
        name="location"
        label="File location"
        value={linkUrl.value}
        error={linkUrl.error}
        onChange={linkUrl.set}
        required
      />
      <Gap size={15} />
      <Typography variant="body1">Enter file name</Typography>
      <Gap size={4} />
      <WCTTextField
        name="name"
        label="File name"
        value={linkName.value}
        error={linkName.error}
        onChange={linkName.set}
        required
      />
      <Gap size={24} />
      <ActionsRow>
        <OutlinedButton onClick={() => onClose(undefined)}>
          Cancel
        </OutlinedButton>
        <PrimaryButton type="submit" disabled={!canSubmitLink} isBusy={isBusy}>
          Add file location
        </PrimaryButton>
      </ActionsRow>
    </ColumnForm>
  );
}
