import { WAInstallRow } from '@api/types/wct-admin/wct-admin-install-types';
import { Button, styled, TableCell, TableRow } from '@mui/material';
import Row from '@components/layout-util-components/row';
import { truncateString } from '@util/string-util';
import { Gap } from '@components/layout-util-components/gap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { usePageAlertVariants } from '@components/alerts';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { ElipsisButton } from '@components/buttons';
import { useMemo } from 'react';
import { useDeleteWAInstallMutation } from '@api/endpoints/wct-admin/wct-admin-install.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { invalidation } from '@api/cache-util';
import { isApiError } from '@api/types/api-error';
import WASeedInstallForm from '@pages/wct-admin/companies/components/forms/wa-seed-install-form';

interface Props {
  install: WAInstallRow;
  onEdit: () => void;
}

const Container = styled(TableRow)({});

export default function WAInstallTableRow({ install, onEdit }: Props) {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { showDeleteConfirmationModal, showPopup } = useUiPopup();
  const [deleteInstallMutation] = useDeleteWAInstallMutation();

  const {
    name,
    installKey,
    installAPIUrl,
    installAPPUrl,
    companyCount,
    created,
  } = install;

  const [deleteInstall] = useBusyAction(async () => {
    await showDeleteConfirmationModal({
      itemName: `Install '${name}'`,
      onDelete: async () => {
        try {
          await deleteInstallMutation(install.installId).unwrap();
          await invalidation('WAInstall');
          showSuccessMessage('Install deleted successfully');
          return true;
        } catch (error) {
          if (isApiError(error)) {
            showErrorMessage(error.message);
          }

          return false;
        }
      },
    });
  });

  const onSeedInstall = () => {
    showPopup(WASeedInstallForm, {
      installId: install.installId,
    });
  };

  // Show first 4 and last 2 digits of the key and only 2 asterisks in between
  const maskedKey = useMemo(() => {
    const clonedStr = installKey?.trim();

    return installKey != null && installKey.length > 8 && clonedStr != null
      ? `${installKey.slice(0, 4).trim()}**${clonedStr.slice(-2).trim()}`
      : null;
  }, [installKey]);

  const appUrlDomain = useMemo(() => {
    return installAPPUrl?.split('/')?.[2];
  }, [installAPPUrl]);
  const apiUrlDomain = useMemo(() => {
    return installAPIUrl?.split('/')?.[2];
  }, [installAPIUrl]);

  return (
    <Container onClick={() => onEdit()}>
      <TableCell>{name}</TableCell>
      <TableCell>
        {maskedKey != null ? (
          <Row>
            <span>{maskedKey}</span>
            <Gap size={4} />
            <Button
              variant="text"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(installKey!);
                showSuccessMessage('Key copied to clipboard');
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </Row>
        ) : (
          'No Key'
        )}
      </TableCell>
      <TableCell>{companyCount}</TableCell>
      <TableCell>
        {appUrlDomain != null ? (
          <Row>
            <span>{truncateString(appUrlDomain, 12)}</span>
            <Gap size={12} />
            <Button
              variant="text"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(installAPPUrl!);
                showSuccessMessage('APP URL copied to clipboard');
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </Row>
        ) : (
          'Not set'
        )}
      </TableCell>
      <TableCell>
        {apiUrlDomain != null ? (
          <Row>
            <span>{truncateString(apiUrlDomain, 12)}</span>
            <Gap size={12} />
            <Button
              variant="text"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(installAPIUrl!);
                showSuccessMessage('API URL copied to clipboard');
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </Row>
        ) : (
          'Not set'
        )}
      </TableCell>
      <TableCell>{dateOnlyShortFormatter(created)}</TableCell>
      <TableCell>
        <ElipsisButton
          actions={[
            { label: 'Edit', onClick: onEdit },
            { label: 'Seed Install', onClick: onSeedInstall },
            { label: 'Delete', onClick: deleteInstall },
          ]}
        />
      </TableCell>
    </Container>
  );
}
