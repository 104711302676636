import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import useCreateSolutionForm from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/create-solution-form-view-hook';
import { ColumnForm } from '@components/layout-util-components/column';
import WCTTextField from '@components/input/text-field';
import { InputSkeleton } from '@components/skeletons';
import { Divider } from '@components/layout-util-components/divider';
import CaseFileManagerInput from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-input';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import Spacer from '@components/layout-util-components/spacer';
import WCTSelectField from '@components/input/select-field';
import WCTCurrencyField from '@components/input/currency-field';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: SolutionsPanelState;
}

export default function CreateSolutionFormView({ state }: Props) {
  const {
    cancelCreateNewSolution,
    gotoViewList,
    chainItemId,
    createNewFromCaseSolution,
  } = state;
  const {
    name,
    isLoading,
    cost,
    themes,
    themeOptions,
    files,
    submit,
    description,
    canSubmit,
    isBusy,
    currency,
  } = useCreateSolutionForm(state);

  const onSubmit = async () => {
    const result = await submit();
    if (result === false) {
      return;
    }
    if (chainItemId != null) {
      createNewFromCaseSolution(result);
    } else {
      gotoViewList();
    }
  };
  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTTextField
          name="name"
          label="Solution Name"
          value={name.value}
          onChange={name.set}
          error={name.error}
          required
        />
      )}
      <Gap size={20} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTTextField
          name="description"
          label="Solution Description"
          value={description.value}
          onChange={description.set}
          error={description.error}
          multiline
        />
      )}
      <Divider compact addMargin negativeMargin={-24} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTSelectField
          id="theme"
          name="theme"
          label="Theme"
          options={themeOptions!}
          onChange={themes.set}
          value={themes.value}
          error={themes.error}
          multiple
          required
        />
      )}
      <Divider compact addMargin negativeMargin={-24} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTCurrencyField
          name="cost"
          label="Cost"
          value={cost.value}
          onChange={cost.set}
          error={cost.error}
          required
          currency={currency}
        />
      )}
      <Divider compact addMargin negativeMargin={-24} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <CaseFileManagerInput
          label="Documents"
          addButtonlabel="Add Document"
          value={files.value}
          onChange={files.set}
          error={files.error}
        />
      )}
      <Spacer />
      <Divider compact addMargin negativeMargin={-24} />
      <ActionsRow>
        <OutlinedButton onClick={cancelCreateNewSolution}>
          Cancel
        </OutlinedButton>
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Save
        </PrimaryButton>
      </ActionsRow>
    </ColumnForm>
  );
}
