import {
  useCompanyDisableThemeMutation,
  useCompanyEnableThemeMutation,
  useGetThemesQuery,
} from '@api/endpoints/company/company-theme.api';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useEffect, useState } from 'react';
import { ReferenceDataDisableListOption } from './components/reference-data-disable-list';

export default function useCompanyThemes() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { data: themes, isLoading: loadingThemes } = useGetThemesQuery();
  const [options, setOptions] = useState<ReferenceDataDisableListOption[]>([]);
  const [enable] = useCompanyEnableThemeMutation();
  const [disable] = useCompanyDisableThemeMutation();

  const enableTheme = (themeId: number) => {
    enable(themeId)
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully enabled theme');
        setOptions((prevOptions) =>
          prevOptions.map((option) =>
            option.id === themeId
              ? { ...option, enabled: true, disabledDateTime: undefined }
              : option
          )
        );
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const disableTheme = (themeId: number) => {
    disable(themeId)
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully disabled theme');
        setOptions((prevOptions) =>
          prevOptions.map((option) =>
            option.id === themeId
              ? { ...option, enabled: false, disabledDateTime: new Date() }
              : option
          )
        );
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  useEffect(() => {
    setOptions(
      themes?.map((o) => {
        return {
          id: o.themeId,
          label: o.name,
          enabled: !o.disabled,
          hasWarning: o.hasRca,
          disabledDateTime: o.disabled ? new Date(o.disabled) : undefined,
        };
      }) ?? []
    );
  }, [themes]);

  return {
    options,
    loadingThemes,
    enableTheme,
    disableTheme,
  };
}
