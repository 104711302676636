import { StepLayout } from '@pages/app/rca/create/components/step-layout';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import Spacer from '@components/layout-util-components/spacer';
import useImpactStep from '@pages/app/rca/create/steps/impact-step-hook';
import { RcaStepProps } from '@pages/app/rca/create/rca-creation-steps-page';
import ImpactsPage from '../components/impacts-page';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { Box, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';

export default function ImpactStep({
  onComplete,
  onGoBack,
  caseId,
}: RcaStepProps) {
  const state = useImpactStep(caseId!);
  const {
    canSubmit,
    isEdit,
    isLoading,
    isFetching,
    isEmpty,
    beginCreate,
    canContribute,
  } = state;

  return (
    <StepLayout
      marginTop={!isEdit}
      actionsRow={
        <>
          <ActionsRow>
            <OutlinedButton onClick={onGoBack}>Back</OutlinedButton>
            <Spacer />
            {!isEdit && canContribute && (
              <>
                <OutlinedButton onClick={onComplete} disabled={!canSubmit}>
                  Skip
                </OutlinedButton>

                <PrimaryButton
                  disabled={!canSubmit}
                  type="submit"
                  onClick={onComplete}
                >
                  {isEdit ? 'Update' : 'Continue'}
                </PrimaryButton>
              </>
            )}
          </ActionsRow>
        </>
      }
    >
      <Column>
        <Row>
          <Column sx={{ maxWidth: '80%' }}>
            <Typography variant="h1">Problem Analysis - Impacts</Typography>
            <Gap size={10} />
            <Typography variant="body1" display="inline">
              Which company goals were impacted by your issue? Recording and
              tracking impacts will greatly improve your analysis and guide you
              to better solutions.
            </Typography>
            <Gap size={50} />
          </Column>
          <Spacer />
          <FetchingIndicator show={!isLoading && isFetching} />
          {!isEmpty && canContribute ? (
            <Box alignSelf="start">
              <Gap size={8} />
              <OutlinedButton onClick={beginCreate}>Add</OutlinedButton>
            </Box>
          ) : null}
        </Row>
        <ImpactsPage state={state} />
      </Column>
    </StepLayout>
  );
}
