export const STATIC_BRAND_COLOR_ID = 1;
export const DYNAMIC_BRAND_COLOR_ID = 0;

export type ColorId = 'solution' | 'theme' | 'evidence' | 'health' | number;

const baseGrey = '949FA2';
const baseGreyLight = '#ECEDF0';

const turquoise = '#26AEB6';
const pink = '#FF4DB8';
const blue = '#0097E1';
const darkBlue = '#0D5080';
const green = '#4CB648';
const darkGreen = '#197D5A';
const red = '#FF3D3D';
const darkRed = '#9B1D34';
const purple = '#C640A9';
const yellow = '#F7B637';
const orange = '#FF6C00';

const turquoiseLight = '#c2f6f6';
const pinkLight = '#fdceeb';
const blueLight = '#c3dff1';
const darkBlueLight = '#8fa6b1';
const greenLight = '#bbe8b8';
const darkGreenLight = '#cff1d6';
const redLight = '#f8d8d8';
const darkRedLight = '#f4ccd1';
const purpleLight = '#f8ceec';
const yellowLight = '#fff7e0';
const orangeLight = '#ffdac1';

const base: string[] = [
  orange,
  darkBlue,
  blue,
  turquoise,
  orange,
  red,
  yellow,
  pink,
  purple,
  darkRed,
  green,
  purple,
  darkGreen,
  darkBlue,
  blue,
  green,
  turquoise,
  orange,
  yellow,
  pink,
  purple,
  darkRed,
  red,
  orange,
  green,
  darkGreen,
  darkBlue,
  blue,
];

const lightened: string[] = [
  orangeLight,
  darkBlueLight,
  blueLight,
  turquoiseLight,
  orangeLight,
  redLight,
  yellowLight,
  pinkLight,
  purpleLight,
  darkRedLight,
  greenLight,
  purpleLight,
  darkGreenLight,
  darkBlueLight,
  blueLight,
  greenLight,
  turquoiseLight,
  orangeLight,
  yellowLight,
  pinkLight,
  purpleLight,
  darkRedLight,
  redLight,
  orangeLight,
  greenLight,
  darkGreenLight,
  darkBlueLight,
  blueLight,
];

const brandColours: string[] = ['#CDF1F3', '#00223A', '#FF6C00'];
const onBrandColours: string[] = ['#00223A', '#ffffff', '#000000'];

export const getColorBrandId = (id: number) => {
  if (id < 0) {
    return baseGreyLight;
  }
  return brandColours[id];
};

export const getOnColorBrandId = (id: number) => {
  if (id < 0) {
    return baseGreyLight;
  }
  return onBrandColours[id];
};

export const getColorForId = (id: ColorId) => {
  if (typeof id === 'number') {
    if (id < 0) {
      return baseGreyLight;
    }
    return base[id % base.length];
  }

  switch (id) {
    case 'solution':
      return '#3CA680';
    case 'evidence':
      return '#00223A';
    case 'theme':
      return '#Cdf1f3';
    case 'health':
      return '#FF6C00';
  }
};

export const getLightenedColorForId = (id: ColorId) => {
  if (typeof id === 'number') {
    if (id < 0) {
      return baseGrey;
    }
    return lightened[id % lightened.length];
  }

  switch (id) {
    case 'solution':
      return '#3CA680';
    case 'theme':
      return '#Cdf1f3';
    case 'evidence':
      return '#00223A';
    case 'health':
      return '#ff6c001f';
  }
};

export const getStrengthColor = (strength: number) => {
  switch (strength) {
    case 1:
      return '#FF3D3D';
    case 2:
      return '#FF9B51';
    case 3:
      return '#FF6C00';
    case 4:
      return '#3CA680';
    case 5:
      return '#178F64';
    default:
      return '#178F64';
  }
};
