import { Box, Link, styled, Typography } from '@mui/material';
import App from 'src/app';
import AppLogo from '@assets/svgs/app-logo.svg';
import { PrimaryButton } from '@components/buttons';
import { useLocation } from 'react-router-dom';
import useUser from '@store/user/user-hook';

interface Props {
  goTo?: string;
  message?: string;
}

const PageWrapper = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  minWidth: '100vw',
  minHeight: '100vh',
  boxSizing: 'border-box',
  padding: 30,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 50,
  background: 'url(/svgs/error-bg.svg) no-repeat center center #00223A',
  backgroundSize: 'cover',
}));

const Logo = styled('div')(({ theme: { palette } }) => ({
  width: 80,
  height: 80,
}));

const ErrorPanel = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 540,
  minEidth: 315,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: palette.common.white,
  borderRadius: 12,
  padding: 40,
  '@media (min-height:600px)': {
    marginBottom: 130,
  },
  p: {
    margin: '5px 0 30px',
    textAlign: 'center',
  },
}));

export const ErrorPage = ({ goTo, message }: Props) => {
  const { isLoggedIn } = useUser();
  const location = useLocation();

  const buttonMessage =
    goTo != null
      ? 'Go back'
      : isLoggedIn
      ? 'Back to dashboard'
      : 'Back to login';
  const goToLink = goTo ?? (isLoggedIn ? '/' : '/auth/login');

  return (
    <PageWrapper>
      <Logo>
        <Link href="/">
          <img className="nav-logo" src={AppLogo} alt="logo" />
        </Link>
      </Logo>
      <ErrorPanel>
        <Typography variant="h1">
          {message ? 'Alert!' : 'An error has occurred'}
        </Typography>
        <Typography variant="body1">
          {message
            ? message
            : 'Sorry, an error has occurred, if the problem persists please '}
          <Link href="mailto:support@whatcausedthis.com">contact support</Link>
        </Typography>
        <Box display="flex" justifyContent="center">
          <PrimaryButton
            href={goToLink}
            onClick={() => {
              if (goToLink === location.pathname) {
                window.location.reload();
              }
            }}
          >
            {buttonMessage}
          </PrimaryButton>
        </Box>
      </ErrorPanel>
    </PageWrapper>
  );
};

export default function ErrorRouteCatcher(props: Props) {
  return (
    <App>
      <ErrorPage {...props} />
    </App>
  );
}
