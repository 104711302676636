import { Button, styled } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';
import { ButtonProps } from './button-props';
import { LoadingIndicator } from '@components/loading-indicator';

const StyledButton = styled(Button, { target: 'wct-primary-button' })<{
  buttonVariant?: Props['buttonVariant'];
  styleOptions?: CSSProperties;
}>(({ theme: { palette }, buttonVariant, styleOptions }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    color: palette.primary.contrastText,
    opacity: 0.5,
  },
  '&.MuiButton-sizeLarge': {
    height: 56,
    fontSize: '15px',
    fontWeight: 500,
    borderRadius: 4,
  },
  '&.MuiButton-sizeMedium': {
    height: 40,
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: 4,
  },
  backgroundImage: `linear-gradient(90deg, ${palette.primary.main} 0%, ${palette.primary.main} 100%)`,
  ...styleOptions,
  ...((!buttonVariant || buttonVariant === 'one') && {
    color: palette.primary.contrastText,
  }),
  ...(buttonVariant === 'two' && {
    display: 'flex',
    padding: '8px 22px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    color: palette.common.white,
  }),
}));

type Props = ButtonProps & {
  isBusy?: boolean;
  color?: 'primary' | 'info';
  size?: 'large' | 'medium' | 'small';
  buttonVariant?: 'one' | 'two';
  styleOptions?: CSSProperties;
  form?: string;
};

const PrimaryButton = ({
  id,
  children,
  onClick,
  disabled,
  color = 'primary',
  isBusy,
  type,
  buttonVariant,
  size,
  styleOptions,
  form,
  href,
}: PropsWithChildren<Props>) => {
  return (
    <StyledButton
      id={id}
      variant="contained"
      buttonVariant={buttonVariant}
      type={type ?? 'button'}
      color={color}
      onClick={onClick}
      disabled={disabled || isBusy}
      size={size}
      styleOptions={styleOptions}
      form={form}
      href={href}
    >
      <span
        className="button-content"
        style={{ visibility: isBusy ? 'hidden' : 'visible' }}
      >
        {children}
      </span>
      <LoadingIndicator show={isBusy ?? false} isWhite />
    </StyledButton>
  );
};

export default PrimaryButton;
