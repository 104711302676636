import { api } from '@api/api';
import {
  WADashboardAdminStats,
  WADashboardCaseStatusStats,
  WADashboardCaseTypeStats,
  WADashboardCompanySeatsFilters,
  WADashboardCompanySeatsStats,
  WADashboardFilters,
  WADashboardHealthScoreStats,
  WADashboardImpactCategoryStats,
  WADashboardRegionsStats,
  WADashboardSectorsStats,
  WADashboardSolutionEfficacyStats,
  WADashboardSolutionTermStats,
  WADashboardSortableFilters,
  WADashboardThemeStats,
} from '@api/types/wct-admin/wa-dashboard-types';

const WADashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWADashboardAdminStats: build.query<
      WADashboardAdminStats,
      WADashboardFilters
    >({
      query: (params) => ({
        url: 'dashboard/adminStats',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardThemes: build.query<
      WADashboardThemeStats,
      WADashboardFilters
    >({
      query: (params) => ({
        url: 'dashboard/themes',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardImpactCategories: build.query<
      WADashboardImpactCategoryStats,
      WADashboardFilters
    >({
      query: (params) => ({
        url: 'dashboard/impactCategory',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardSolutionTerm: build.query<
      WADashboardSolutionTermStats,
      WADashboardFilters
    >({
      query: (params) => ({
        url: 'dashboard/solutionTerm',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardSolutionEfficacy: build.query<
      WADashboardSolutionEfficacyStats,
      WADashboardFilters
    >({
      query: (params) => ({
        url: 'dashboard/solutionEfficacy',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardCaseTypes: build.query<
      WADashboardCaseTypeStats,
      WADashboardFilters
    >({
      query: (params) => ({
        url: 'dashboard/caseType',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardCaseStatuses: build.query<
      WADashboardCaseStatusStats,
      WADashboardFilters
    >({
      query: (params) => ({
        url: 'dashboard/caseStatus',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardCompanySeats: build.query<
      WADashboardCompanySeatsStats,
      WADashboardCompanySeatsFilters
    >({
      query: (params) => ({
        url: 'dashboard/companySeats',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardRegions: build.query<
      WADashboardRegionsStats,
      WADashboardSortableFilters
    >({
      query: (params) => ({
        url: 'dashboard/regions',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardSectors: build.query<
      WADashboardSectorsStats,
      WADashboardFilters
    >({
      query: (params) => ({
        url: 'dashboard/sectors',
        method: 'GET',
        params,
      }),
    }),
    getWADashboardHealthScore: build.query<
      WADashboardHealthScoreStats,
      WADashboardFilters
    >({
      query: (params) => ({
        url: 'dashboard/healthScore',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetWADashboardAdminStatsQuery,
  useGetWADashboardCaseStatusesQuery,
  useGetWADashboardCaseTypesQuery,
  useGetWADashboardCompanySeatsQuery,
  useGetWADashboardHealthScoreQuery,
  useGetWADashboardImpactCategoriesQuery,
  useGetWADashboardRegionsQuery,
  useGetWADashboardSectorsQuery,
  useGetWADashboardSolutionEfficacyQuery,
  useGetWADashboardSolutionTermQuery,
  useGetWADashboardThemesQuery,
} = WADashboardApi;
