import { Box, Chip, Link, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoReportInfo from '@pages/app/dashboard/components/no-report-info';
import { getColorBrandId, getColorForId } from '@util/colour-identifiers';
import { RadialOverviewStat } from '@components/outcome-report/components/radial-chart';
import Row from '@components/layout-util-components/row';
import { Gap } from '@components/layout-util-components/gap';
import Spacer from '@components/layout-util-components/spacer';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

const StyledHeader = styled(Typography)({
  fontSize: 18,
  lineHeight: '133.4%' /* 24.012px */,
  fontWeight: 400,
});

const StyledSubHeader = styled(Typography)({
  color: '#949FA2',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '150%' /* 18px */,
  letterSpacing: '0.15px',
});

const StyledChip = styled(Chip)(() => ({
  borderRadius: 76,
  background: '#F8F8FA',
  marginLeft: 'auto',
  width: 69,
  padding: '8px 10px 8px 10px',
  '> span ': {
    padding: 0,
    width: '100%',
  },
  '> span > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    p: {
      fontSize: 12,
      color: '#525B5E',
      margin: 0,
      marginLeft: 'auto',
    },
  },
}));

const StyledLeagueEntry = styled(Box)({
  display: 'flex',
  padding: '8px 10px 8px 16px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: 3,
  border: '1px solid #E0E0E0',
  background: 'white',
  p: {
    fontSize: 14,
    marginRight: 15,
  },
});

export const DashboardStatContainer = styled(Box)<{ isTotals?: boolean }>(
  ({ isTotals }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: 20,
    flex: '1 0 0',
    alignSelf: 'stretch',
    hr: {
      height: 1,
      background: 'rgba(0, 0, 0, 0.15)',
      margin: '20px 0 10px 0',
    },

    ...(isTotals
      ? {
          '> .MuiBox-root': {
            gap: 16,
            alignItems: 'center',
            '> .MuiBox-root:first-of-type': {
              display: 'flex',
              alignItems: 'center',
              width: '40px',
              height: '40px',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: 300,
              borderRadius: 4,
              flexShrink: 0,
              svg: {
                height: '1.25em',
                path: {
                  fill: 'inherit',
                  stroke: 'inherit',
                },
              },
            },
            '> .MuiBox-root:last-of-type': {
              display: 'flex',
              flexDirection: 'column',
            },
            '.stat_header': {
              lineHeight: 1,
              marginBottom: '4px',
            },
            '.circle-container': {
              width: 24,
              height: 24,
              borderRadius: 12,
              fontSize: 8,
              flex: '0 0 24px',
            },
            '.flat-badge': {
              marginTop: '4px',
            },
            '.plus': {
              padding: '4px 6px',
              borderRadius: '4px',
              background: '#D8DBE3',
              color: '#000000',
              fontSize: '10px',
              fontWeight: '400',
              lineHeight: '12px',
              letterSpacing: '0em',
              textAlign: 'center',
              flex: '0 0 auto',
              marginTop: '4px',
            },
          },
        }
      : {
          margin: '30px 0',
          '> .MuiBox-root': {
            flexDirection: 'column',
            '> .MuiBox-root.coverage': {
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
            },
            '> .MuiBox-root.title': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 11,
            },
            '> .MuiBox-root.solution': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          },
        }),
  })
);

type StatBoxProps = {
  bgColor: string;
  fontColour: string;
  icon: ReactNode;
  value: ReactNode;
  label: ReactNode;
};

export const StyledStatBox = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: 4,
  display: 'flex',
  background: palette.common.white,
  padding: '20px',
  flex: '1 0 0',
  alignSelf: 'stretch',
}));

export function DashboardStatBox({
  bgColor,
  fontColour,
  icon,
  value,
  label,
}: StatBoxProps) {
  return (
    <StyledStatBox>
      <Box sx={{ background: bgColor, color: fontColour }}>{icon}</Box>
      <Box>
        <StyledSubHeader className="stat_header" variant="body2">
          {label}
        </StyledSubHeader>
        <StyledHeader className="stat_subheader" variant="body1">
          {value}
        </StyledHeader>
      </Box>
    </StyledStatBox>
  );
}

export interface DashboardLinkData {
  text: string;
  href?: string;
  onClick?: () => void;
}

export type LeagueTableStat = {
  id: number;
  name: string;
  colour: string;
  percentage: number;
};

type StyledLeagueTableProps = {
  title: string;
  tableTitle: string;
  afterTitle?: ReactNode;
  emptyMessage: string;
  entries: LeagueTableStat[];
  footerLink?: DashboardLinkData;
  hideCoverageLabel?: boolean;
  noPercentage?: boolean;
};

export function DashboardLeagueTable({
  title,
  tableTitle,
  afterTitle,
  entries,
  emptyMessage,
  footerLink,
  hideCoverageLabel,
  noPercentage,
}: StyledLeagueTableProps) {
  const total = entries.length;

  const nonNegativeIdRows = entries.filter(({ id }) => id >= 0);
  const negativeIdRows = entries.filter(({ id }) => id < 0);
  negativeIdRows.sort((a, b) => {
    const aLower = a.name.toLowerCase();
    const bLower = b.name.toLowerCase();

    if (aLower.endsWith('required')) {
      return -1;
    } else if (bLower.endsWith('required')) {
      return 1;
    }

    return 0;
  });
  return (
    <StyledStatBox>
      {total > 0 ? (
        <>
          <Row>
            <StyledHeader variant="body1">{title}</StyledHeader>
            {afterTitle && (
              <>
                <Spacer />
                <Gap size={20} />
                {afterTitle}
              </>
            )}
          </Row>
          <Box className="coverage">
            <Row>
              <Gap size={15} />
              <Typography variant="caption">{tableTitle}</Typography>
              <Spacer />
              {!hideCoverageLabel && (
                <Typography variant="caption">Coverage (%)</Typography>
              )}
            </Row>
            {nonNegativeIdRows.slice(0, 5).map((data) => (
              <StyledLeagueEntry key={data.id}>
                <StyledHeader>{data.name}</StyledHeader>
                <StyledChip
                  variant="filled"
                  label={
                    <Box>
                      <Box
                        sx={{
                          flex: '0 0 auto',
                          width: '10px',
                          height: '10px',
                          borderRadius: 10,
                          background:
                            data.colour ?? getColorForId(data.id ?? -1),
                        }}
                      />
                      {noPercentage ? (
                        <Typography>{Math.round(data.percentage)}</Typography>
                      ) : (
                        <Typography>{Math.round(data.percentage)}%</Typography>
                      )}
                    </Box>
                  }
                />
              </StyledLeagueEntry>
            ))}
            {nonNegativeIdRows.length > 0 && <Gap size={15} />}
            <Spacer />
            {negativeIdRows.map((data) => (
              <StyledLeagueEntry
                sx={{
                  background: '#ECEDF0',
                }}
                key={data.id}
              >
                <StyledHeader>{data.name}</StyledHeader>
                <StyledChip
                  variant="filled"
                  label={
                    <Box>
                      <Box
                        sx={{
                          flex: '0 0 auto',
                          width: '10px',
                          height: '10px',
                          borderRadius: 10,
                          background:
                            data.colour ?? getColorForId(data.id ?? -1),
                        }}
                      />

                      {noPercentage ? (
                        <Typography>{Math.round(data.percentage)}</Typography>
                      ) : (
                        <Typography>{Math.round(data.percentage)}%</Typography>
                      )}
                    </Box>
                  }
                />
              </StyledLeagueEntry>
            ))}
          </Box>
          {!!footerLink && (
            <>
              <Gap size={12} />
              <Spacer />
              <Row>
                <Spacer />
                <Link
                  href={footerLink.href ?? '#'}
                  className="link"
                  onClick={(e) => {
                    if (footerLink?.onClick != null) {
                      e.preventDefault();
                      footerLink.onClick();
                    }
                  }}
                >
                  {footerLink.text}
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          {afterTitle ? (
            <Row>
              <StyledHeader variant="body1">{title}</StyledHeader>
              <Spacer />
              <Gap size={20} />
              {afterTitle}
            </Row>
          ) : null}
          <NoReportInfo message={emptyMessage} />
        </>
      )}
    </StyledStatBox>
  );
}

const StyledSplitCoverageBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '.MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '> .MuiBox-root.solution': {
    flexDirection: 'column',
    borderRadius: 5,
    flexGrow: 1,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '7px 13px 5px 13px',
    '> .MuiBox-root': {
      gap: 8,
    },
    '> p': {
      textTransform: 'uppercase',
    },
  },
});

type StyledSplitCoverage = {
  title: string;
  subtitle: string;
  emptyMessage: string;
  topEntries: RadialOverviewStat[];
  topTitle: string;
  topSubtitle: string;
  bottomEntries: RadialOverviewStat[];
  bottomTitle: string;
  bottomSubtitle: string;
};

export function DashboardSplitCoverage({
  title,
  subtitle,
  topEntries,
  topTitle,
  topSubtitle,
  bottomEntries,
  bottomTitle,
  bottomSubtitle,
  emptyMessage,
}: StyledSplitCoverage) {
  const statsToGradiant = (stats: RadialOverviewStat[]) => {
    const totals = stats.reduce((acc, { percentage }) => acc + percentage!, 0);
    const percentages = stats.map(({ percentage }) => percentage! / totals);

    // Initialise with fallback colour
    let gradientString = 'linear-gradient(90deg';

    let previousStopPoint = 0;

    stats.forEach(({ id }, i) => {
      const percentage = percentages[i] * 100.0;
      const startPoint = previousStopPoint;
      const stopPoint = startPoint + percentage;
      previousStopPoint = stopPoint;

      const color = getColorBrandId(id ?? -1);

      gradientString += `, ${color} ${startPoint}% ${stopPoint}%`;
    });

    return (gradientString += ')');
  };

  return (
    <StyledStatBox>
      {topEntries.length + bottomEntries.length > 0 ? (
        <>
          <StyledHeader variant="body1">{title}</StyledHeader>
          <StyledSubHeader variant="body1">{subtitle}</StyledSubHeader>
          {topEntries.length > 0 && (
            <>
              <Box className="title">
                <StyledHeader>{topTitle}</StyledHeader>
                <StyledSubHeader>{topSubtitle}</StyledSubHeader>
              </Box>
              <Box
                sx={{
                  height: 25,
                  marginY: 1.5,
                  borderRadius: 2,
                  background: statsToGradiant(topEntries),
                }}
              />
              <StyledSplitCoverageBox>
                {topEntries.map((data) => (
                  <Box className="solution" key={data.id}>
                    <Box>
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: 8,
                          background: getColorBrandId(data.id ?? -1),
                        }}
                      />
                      <StyledHeader>
                        {`${Math.round(data.percentage!)}%` || '0.00%'}
                      </StyledHeader>
                    </Box>
                    <StyledSubHeader>{data.name}</StyledSubHeader>
                  </Box>
                ))}
              </StyledSplitCoverageBox>
              <hr />
            </>
          )}
          {bottomEntries.length > 0 && (
            <>
              <Box className="title">
                <StyledHeader>{bottomTitle}</StyledHeader>
                <StyledSubHeader>{bottomSubtitle}</StyledSubHeader>
              </Box>
              <Box
                sx={{
                  height: 25,
                  marginY: 1.5,
                  borderRadius: 2,
                  background: statsToGradiant(bottomEntries),
                }}
              />
              <StyledSplitCoverageBox>
                {bottomEntries.map((data) => (
                  <Box className="solution" key={data.id}>
                    <Box>
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: 8,
                          background: getColorBrandId(data.id ?? -1),
                        }}
                      />
                      <StyledHeader>
                        {`${Math.round(data.percentage!)}%` || '0.00%'}
                      </StyledHeader>
                    </Box>
                    <StyledSubHeader>{data.name}</StyledSubHeader>
                  </Box>
                ))}
              </StyledSplitCoverageBox>
            </>
          )}
        </>
      ) : (
        <NoReportInfo message={emptyMessage} />
      )}
    </StyledStatBox>
  );
}
