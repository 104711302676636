import { v4 as uuid } from 'uuid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAP_KEY } from '@util/env';
import ReportingLocationMarker from '@assets/svgs/reporting-location.svg';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import CasePinCard from '@components/cards/case-pin-card';

type Map = google.maps.Map;

export interface PinDisplayInfo {
  info: PinInfo;
  positionX: number;
  positionY: number;
}

export interface PinInfo {
  lat: number;
  lng: number;
  isReportingLocation: boolean;
  cases: Array<CaseItemRowResponse>;
}
interface Props {
  pins: Array<PinInfo>;
  onFilterByLocationId: (locationId: number) => void;
}

export default function PinDisplayMap({
  pins = [],
  onFilterByLocationId,
}: Props) {
  const [pinDisplay, setPinDisplay] = useState<PinDisplayInfo>();

  const [mapId] = useState(uuid());
  const [defaultLatLng] = useState(() => ({
    lat: 51.5072,
    lng: 0.1276,
  }));

  const loadedRef = useRef<boolean>(false);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map>();

  const loadMap = useCallback(async () => {
    if (loadedRef.current) {
      return;
    }

    const loader = new Loader({
      apiKey: GOOGLE_MAP_KEY,
      version: 'weekly',
    });

    try {
      await Promise.all([
        loader.importLibrary('maps'),
        loader.importLibrary('marker'),
      ]);

      const map = (mapRef.current = new google.maps.Map(
        mapContainerRef.current as HTMLElement,
        {
          center: defaultLatLng,
          zoom: 8,
        }
      ));

      map.addListener('click', () => {
        setPinDisplay(undefined);
      });

      pins.forEach((pin) => {
        const marker = new google.maps.Marker({
          map: map,
          position: pin as any,
          icon: pin.isReportingLocation ? ReportingLocationMarker : undefined,
          label: pin.cases.length.toString(),
          clickable: true,
        });

        marker.addListener('click', (e) => {
          const mouseEvent = e.domEvent as MouseEvent;
          const offsetX = mapContainerRef.current!.offsetLeft;
          const offsetY = mapContainerRef.current!.offsetTop;

          setPinDisplay({
            info: pin,
            positionX: mouseEvent.clientX - offsetX,
            positionY: mouseEvent.clientY - offsetY,
          });
        });
      });

      loadedRef.current = true;
    } catch (error) {
      console.error(error);
    }
  }, [defaultLatLng, pins]);

  useEffect(() => {
    loadMap();
  }, [loadMap]);

  return (
    <div
      style={{ width: '100%', height: '100%' }}
      ref={mapContainerRef}
      id={mapId}
    >
      {pinDisplay ? (
        <div
          style={{
            zIndex: 2,
            position: 'absolute',
            top: pinDisplay.positionY,
            left: pinDisplay.positionX,
          }}
        >
          {
            <CasePinCard
              info={pinDisplay.info}
              onFilterByLocationId={onFilterByLocationId}
            />
          }
        </div>
      ) : null}
    </div>
  );
}
