import { OutcomeReportState } from '@pages/app/outcome/outcome-report-hook';
import { styled } from '@mui/material';
import OutcomeReportScaffold from '@components/outcome-report/outcome-report-scaffold';
import ShareButton from '@pages/app/rca/outcome/components/share-button';

interface Props {
  state: OutcomeReportState;
}

const PageContainer = styled('div')({
  width: '100vw',
  minHeight: '100vh',
  display: 'flex',

  '.share-button-container': {
    position: 'fixed',
    right: 20,
    top: 20,
    zIndex: 1,
  },
});

export default function ReportView({ state }: Props) {
  const { caseId, outcomeId, canShare } = state;

  return (
    <PageContainer>
      {canShare && (
        <div className="share-button-container">
          <ShareButton caseId={caseId} outcomeId={outcomeId} filled />
        </div>
      )}
      <OutcomeReportScaffold caseId={caseId} outcomeId={outcomeId} />
    </PageContainer>
  );
}
