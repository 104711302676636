import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectBestReportView,
  selectIsReportRunning,
  selectIsReportsOpen,
  selectRunReportData,
  selectRunReportId,
  selectRunReportModel,
  shouldShrinkReportView,
} from '@store/reports/reports-selectors';
import { closeReport, setShowReportId } from '@store/reports/reports-slice';
import {
  useCreateCaseReportMutation,
  useUpdateCaseReportMutation,
} from '@api/endpoints/case-report.api';
import { selectCurrentRcaCaseIdNullable } from '@store/rca-editor/selectors';
import { ApiError } from '@api/types/api-error';
import { CreateReportRequest } from '@api/types/case/case-report/create-report.request';
import { usePageAlertVariants } from '@components/alerts';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import useReportDataSplit from '@hooks/report/use-report-data-split-hook';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useBusyAction from '@hooks/use-busy-action-hook';
import { showReportByRunRequest } from '@store/reports/reports-actions';

export default function useReportPanel() {
  const dispatch = useAppDispatch();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const [createReport, { isLoading: isBusyCreating }] =
    useCreateCaseReportMutation();
  const [updateReport, { isLoading: isBusyUpdating }] =
    useUpdateCaseReportMutation();

  const reportId = useAppSelector(selectRunReportId);
  const caseId = useAppSelector(selectCurrentRcaCaseIdNullable);
  const isOpen = useAppSelector(selectIsReportsOpen);
  const isLoading = useAppSelector(selectIsReportRunning);
  const runModel = useAppSelector(selectRunReportModel);
  const runData = useAppSelector(selectRunReportData);
  const shouldShrink = useAppSelector(shouldShrinkReportView);
  const reportView = useAppSelector(selectBestReportView);

  const isBusy = isBusyCreating || isBusyUpdating;
  const canSubmit = !isBusy;
  const isEmpty = !isLoading && runData?.data.length === 0;

  const splitData = useReportDataSplit(runData);

  const close = () => {
    dispatch(closeReport());
  };

  var reportName = '';

  if (runModel && !runModel.name) {
    var subTitle =
      runModel.filterCaseImpactNames &&
      runModel.filterCaseImpactNames.length > 0
        ? runModel.filterCaseImpactNames.join(', ')
        : runModel.caseImpactTypeName ?? '';

    var subTitlePrefix = runModel.reportSubTypeName;

    if (subTitlePrefix.toLowerCase() === 'dominant') {
      subTitlePrefix = 'Dominant Causes Against';
    }

    reportName = `${subTitlePrefix} ${
      subTitle ? ` - ${subTitle}` : ''
    }`.trimEnd();
  } else if (runModel) {
    reportName = runModel?.name ?? '';
  }
  const name = useField<string>([required()], reportName);

  const save = async () => {
    const model = runModel!;
    try {
      const action =
        reportId != null
          ? updateReport({
              caseId: caseId!,
              reportId,
              reportTypeId: model.reportTypeId,
              reportSubTypeId: model.reportSubTypeId,
              reportRankById: model.reportRankById,
              ids: model.ids ?? [],
              caseImpactTypeId: model.caseImpactTypeId,
              filterCaseImpactIds: model.filterCaseImpacts,
              name: name.value,
            })
          : createReport({
              caseId: caseId!,
              reportTypeId: model.reportTypeId,
              reportSubTypeId: model.reportSubTypeId,
              reportRankById: model.reportRankById,
              ids: model.ids ?? [],
              caseImpactTypeId: model.caseImpactTypeId,
              filterCaseImpactIds: model.filterCaseImpacts,
              name: name.value,
            });

      const report = await action.unwrap();
      if (report != null) {
        dispatch(setShowReportId({ id: report.reportId, reset: false }));
      }

      showSuccessMessage(
        `${model.reportSubTypeName} ${model.reportTypeName} report has been saved.`
      );

      return true;
    } catch (e) {
      const { errors, message } = e as ApiError<CreateReportRequest>;
      showErrorMessage(
        errors?.ids ??
          errors?.reportTypeId ??
          errors?.reportSubTypeId ??
          errors?.reportRankById ??
          errors?.impactType ??
          errors?.caseImpactTypeId ??
          errors?.impactGroup ??
          message
      );

      return false;
    }
  };

  const exportData = () => {
    const { columns, data: rows } = runData!;

    const config = mkConfig({
      useKeysAsHeaders: false,
      columnHeaders: columns.map((header, index) => ({
        key: `${index}`,
        displayLabel: header,
      })),
    });

    const exportData = rows.map(({ cells }) => ({
      ...cells.map((cell) => cell.value),
    }));

    const csv = generateCsv(config)(exportData);
    download(config)(csv);
  };

  const [reRun, isReRunning] = useBusyAction(() => {
    return dispatch(showReportByRunRequest(runModel!, false));
  });

  return {
    isOpen,
    isLoading,
    close,
    runModel,
    runData,
    shouldShrink,
    reportView,
    save,
    name,
    isBusy,
    canSubmit,
    exportData,
    isEmpty,
    splitData,
    reRun,
    isReRunning,
  };
}

export type ReportPanelState = ReturnType<typeof useReportPanel>;
