/* eslint-disable react-hooks/exhaustive-deps */
import { Field } from './use-field-hook';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch } from '@store/store';
import { setCurrentFormDirty } from '@store/ui/ui-slice';

type FieldsWatcherArgs = Array<Field<any>>;
export default function useFieldsWatcher<T extends FieldsWatcherArgs>(
  fields: T,
  dirtyCheck = true
) {
  const dispatch = useAppDispatch();
  const shouldAutoValidate = useRef(false);

  const isValid = useMemo(
    () => {
      if (!shouldAutoValidate.current) {
        return true;
      }

      for (const field of fields) {
        if (!field.validate()) {
          return false;
        }
      }
      return true;
    },
    fields.map((field) => field.validate)
  );

  const { dirtyCount, isDirty } = useMemo(
    () => {
      let dirtyCount = 0;
      for (const field of fields) {
        if (field.isDirty) {
          dirtyCount += 1;
        }
      }
      return { dirtyCount, isDirty: dirtyCount > 0 };
    },
    fields.map((field) => field.isDirty)
  );

  const resetAll = useCallback(() => {
    shouldAutoValidate.current = false;
    for (const field of fields) {
      field.reset();
    }
  }, []);

  const validateAll = useCallback(
    () => {
      shouldAutoValidate.current = true;
      let isValid = true;
      for (const field of fields) {
        if (!field.validate()) {
          isValid = false;
        }
      }
      return isValid;
    },
    fields.map((field) => field.validate)
  );

  useEffect(() => {
    if (dirtyCheck) {
      dispatch(setCurrentFormDirty(isDirty));
      return () => {
        dispatch(setCurrentFormDirty(false));
      };
    }
  }, [dirtyCheck, isDirty]);

  const scrollToTopMostError = useCallback(() => {
    setTimeout(() => {
      const elements = document.querySelectorAll(
        '.MuiFormHelperText-root.Mui-error'
      );

      // Find the element highest in the page and scroll to it
      let top = Number.MAX_VALUE;
      let topElement: HTMLElement | null = null;
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i] as HTMLElement;
        const rect = element.getBoundingClientRect();
        if (rect.top < top) {
          top = rect.top;
          topElement = element;
        }
      }
      if (topElement) {
        topElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 1);
  }, []);

  return useMemo(
    () => ({
      isValid,
      isDirty,
      dirtyCount,
      resetAll,
      validateAll,
      scrollToTopMostError,
    }),
    [isDirty, isValid, dirtyCount, validateAll, scrollToTopMostError]
  );
}
